import React from 'react';

import { TableOfContentsEntry } from '@/domains';

import { TableOfContents } from '@/components/TableOfContents';
import { Reader } from '@/components/Reader/containers/ReaderContainer';

export const TableOfContentsTab: React.FC = () => {
  const { tableOfContents, readerService, sidebar } = Reader.useContainer();

  const onClick = React.useCallback(
    async ({ epubLocation }: TableOfContentsEntry) => {
      sidebar.close();
      await readerService.goToSelector(epubLocation);
    },
    [sidebar, readerService]
  );

  return <TableOfContents {...{ onClick }} items={tableOfContents.value ?? []} />;
};
