import React from 'react';
import { Outlet } from 'react-router-dom';
import useSWR from 'swr';

import { getVLDHandbook } from '@/services/api';

import { TenantContainer } from '@/containers/TenantContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { Loading, LoadingCenter, LoadingIndicator } from '@/components/Loading';

export const VLDHandbookLoader: React.FC = () => {
  const { tenant } = TenantContainer.useContainer();
  const { language } = LanguageContainer.useContainer();

  const { data, isValidating } = useSWR(
    { key: `getVLDHandbook`, tenant, language },
    ({ key, ...o }) => getVLDHandbook(o),
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  if (!data && isValidating)
    return (
      <Loading visible>
        <LoadingCenter>
          <LoadingIndicator />
        </LoadingCenter>
      </Loading>
    );

  return <Outlet context={data} />;
};
