import React from 'react';
import { useIntl } from 'react-intl';

import { Lesson, LessonAccessState, LessonProgressStatus, LessonListItem } from '@/domains';
import { translations } from '@/locales';

import { SessionContainer } from '@/containers/SessionContainer';

export interface Props {
  lesson: Lesson | LessonListItem;
}

export const FormattedLessonPages = ({ lesson }: React.PropsWithChildren<Props>) => {
  const intl = useIntl();
  const { authenticated } = SessionContainer.useContainer();

  if (!authenticated)
    return <>{intl.formatMessage({ id: translations.misc.pages }, { number: lesson.totalNumberOfPages })}</>;

  if (lesson.access === LessonAccessState.Accessible)
    return (
      <>
        {intl.formatMessage(
          {
            id:
              lesson.status === LessonProgressStatus.InProgress
                ? translations.misc.completionPercentageWithStatus
                : translations.misc.pagesWithStatus
          },
          {
            number:
              lesson.status === LessonProgressStatus.InProgress
                ? lesson.completionPercentage
                : lesson.totalNumberOfPages,
            status: intl.formatMessage({ id: translations.domains.lessonProgressStatus[lesson.status] })
          }
        )}
      </>
    );

  if (lesson.accessibleAt)
    return (
      <>
        {intl.formatMessage(
          { id: translations.misc.availableOn },
          { date: intl.formatDate(lesson.accessibleAt, { dateStyle: 'long' }) }
        )}
      </>
    );

  return <>{intl.formatMessage({ id: translations.misc.unavailable })}</>;
};
