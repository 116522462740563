import React from 'react';
import classnames from 'classnames';
import { millisecondsInHour, millisecondsInMinute, millisecondsInSecond } from 'date-fns';

import { Icon } from '@/components/Icon';
import { ReaderProgress } from '../services';
import { Reader } from '../containers/ReaderContainer';

interface Props {
  percent: number;
  className?: string;
}

const formatTimePart = (value: number) => value.toString().padStart(2, '0');

const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / millisecondsInHour);
  const minutes = Math.floor((duration % millisecondsInHour) / millisecondsInMinute);
  const seconds = Math.floor((duration % millisecondsInMinute) / millisecondsInSecond);

  return [...(hours > 0 ? [formatTimePart(hours)] : []), formatTimePart(minutes), formatTimePart(seconds)].join(':');
};

export const ReaderAudioControls = React.forwardRef<HTMLDivElement, Props>(({ percent, className }, ref) => {
  const { loading, readerService } = Reader.useContainer();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [progress, setProgress] = React.useState<ReaderProgress>({ current: 0, total: 1 });

  React.useEffect(() => {
    return () => readerService.mediaService?.pause();
  }, [readerService.mediaService]);

  React.useEffect(() => {
    return readerService.mediaService?.subscribeToStateChange(setIsPlaying);
  }, [readerService, readerService.mediaService]);

  React.useEffect(() => {
    return readerService.mediaService?.subscribeToProgressChange(setProgress);
  }, [readerService, readerService.mediaService]);

  const togglePlay = React.useCallback(() => {
    if (isPlaying) return readerService.mediaService?.pause();
    return readerService.mediaService?.play();
  }, [isPlaying, readerService]);

  const seekBackward = React.useCallback(() => readerService.mediaService?.seekPreviousFragment(), [readerService]);
  const seekForward = React.useCallback(() => readerService.mediaService?.seekNextFragment(), [readerService]);

  return (
    <div {...{ ref }} className={classnames('flex justify-between items-stretch', className)}>
      <span className="w-1/3 text-[0.875rem] text-gray-800">{formatDuration(progress.current)}</span>

      <div>
        <button className={classnames({ 'cursor-default': loading })} disabled={loading} onClick={seekBackward}>
          <Icon
            type="backward"
            className={classnames('text-[2rem] transition', {
              'text-scarlet hover:text-scarlet-700': !loading,
              'text-gray-200': loading
            })}
          />
        </button>

        <button
          className={classnames('rounded-full w-12 h-12 min-w-[3rem] mx-10 transition', {
            'bg-scarlet hover:bg-scarlet-700': !loading,
            'bg-gray-200 cursor-default': loading
          })}
          disabled={loading}
          onClick={togglePlay}
        >
          <Icon type={isPlaying ? 'pause' : 'play'} className="text-white text-[1.5rem]" />
        </button>

        <button className={classnames({ 'cursor-default': loading })} disabled={loading} onClick={seekForward}>
          <Icon
            type="forward"
            className={classnames('text-[2rem] transition', {
              'text-scarlet hover:text-scarlet-700': !loading,
              'text-gray-200': loading
            })}
          />
        </button>
      </div>

      <div className="w-1/3 flex flex-col text-right">
        <span className="flex-1 text-[0.875rem] text-gray-800">{formatDuration(progress.total)}</span>

        <span className="text-[0.875rem] text-gray-600">{percent}%</span>
      </div>
    </div>
  );
});
