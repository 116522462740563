import React from 'react';
import * as yup from 'yup';
import {
  Form as ReactFinalForm,
  FormProps as ReactFinalFormProps,
  FormRenderProps as ReactFinalFormRenderProps
} from 'react-final-form';
import set from 'lodash/set';
import { FormApi, FORM_ERROR } from 'final-form';

import { ErrorBoundary } from '@/components/ErrorBoundary';
import { ValidationError } from '@/components/ValidationMessage';

type AnyObject = Record<string, any>;
export type RenderProps<T> = ReactFinalFormRenderProps<T> & Pick<ReactFinalFormProps<T>, 'id'>;
export type FormProps<S extends yup.ObjectSchema<any>> = Omit<ReactFinalFormProps<yup.InferType<S>>, 'onSubmit'> &
  Pick<
    React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    'autoComplete' | 'className' | 'id' | 'spellCheck'
  > & {
    schema: S;
    onSubmit: (values: yup.InferType<S>, form: FormApi<yup.InferType<S>>) => void | Promise<AnyObject> | AnyObject;
    children: (props: RenderProps<yup.InferType<S>>) => React.ReactNode;
  };

export const Form = <S extends yup.ObjectSchema<any>>(props: React.PropsWithChildren<FormProps<S>>) => {
  const { id, onSubmit, schema, children, className, autoComplete = 'off', spellCheck = false, ...rest } = props;

  const getValue = (values: yup.InferType<S>): yup.InferType<S> => {
    return schema ? (schema.cast(values) as yup.InferType<S>) : values;
  };

  const validate = (values: yup.InferType<S>) => {
    if (!schema) return;

    return schema
      .validate(values, { abortEarly: false })
      .then(() => null)
      .catch((error: yup.ValidationError) => {
        return error.inner.reduce<{ [x: string]: ValidationError }>((fields, error) => {
          if (!error.path) return fields;
          set(fields, error.path, { id: error.message, values: error.params });
          return fields;
        }, {});
      });
  };

  const submit = (values: yup.InferType<S>, form: FormApi<yup.InferType<S>>) => {
    return Promise.resolve()
      .then(() => onSubmit(getValue(values), form))
      .catch((error) => ({ [FORM_ERROR]: error }));
  };

  return (
    <ErrorBoundary>
      <ReactFinalForm
        {...rest}
        validate={validate}
        onSubmit={submit}
        render={(renderProps) => (
          <form {...{ id, className, autoComplete, spellCheck }} onSubmit={renderProps.handleSubmit} noValidate>
            {children({ ...renderProps, id })}
          </form>
        )}
      />
    </ErrorBoundary>
  );
};
