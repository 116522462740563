import React from 'react';
import classnames from 'classnames';

import { FlashcardColor, FLASHCARD_COLORS } from '@/domains';

import { ColoredDot } from '@/components/ColoredDot';
import { flashcardColorToGradient } from '@/components/Flashcard';

export interface Props {
  onChange: (value?: FlashcardColor) => void;
  value?: FlashcardColor;
  className?: string;
}

export const FlashcardColorSelect: React.FC<Props> = ({ value, onChange, className }) => (
  <div className={classnames('flex', className)}>
    {FLASHCARD_COLORS.map((color) => (
      <ColoredDot
        key={color}
        className="h-12 w-12 m-1"
        selected={color === value}
        onClick={() => onChange(color)}
        gradient={classnames(`bg-gradient-to-br`, flashcardColorToGradient[color])}
      />
    ))}
  </div>
);
