import {
  AnyAuxiliaryMaterialItem,
  AuxiliaryAudioMaterial,
  AuxiliaryEpubMaterial,
  AuxiliaryMaterialType,
  AuxiliaryVideoMaterial,
  AuxiliaryWeblinkMaterial
} from '@/domains';

export const isVideoAuxiliaryMaterial = (
  auxiliaryMaterial: AnyAuxiliaryMaterialItem
): auxiliaryMaterial is AuxiliaryVideoMaterial => auxiliaryMaterial.type === AuxiliaryMaterialType.Video;

export const isAudioAuxiliaryMaterial = (
  auxiliaryMaterial: AnyAuxiliaryMaterialItem
): auxiliaryMaterial is AuxiliaryAudioMaterial => auxiliaryMaterial.type === AuxiliaryMaterialType.Audio;

export const isWeblinkAuxiliaryMaterial = (
  auxiliaryMaterial: AnyAuxiliaryMaterialItem
): auxiliaryMaterial is AuxiliaryWeblinkMaterial => auxiliaryMaterial.type === AuxiliaryMaterialType.Weblink;

export const isEpubAuxiliaryMaterial = (
  auxiliaryMaterial: AnyAuxiliaryMaterialItem
): auxiliaryMaterial is AuxiliaryEpubMaterial => auxiliaryMaterial.type === AuxiliaryMaterialType.Epub;
