import React from 'react';
import classnames from 'classnames';

import { TableOfContentsEntry } from '@/domains';

interface Props {
  items: TableOfContentsEntry[];
  className?: string;
  disabled?: boolean;
  parent?: string;
  onClick(item: TableOfContentsEntry): void;
}

const getEntryNumber = (index: number, parent?: string) => (!parent ? `${index}` : ``);

export const TableOfContents = React.forwardRef<HTMLUListElement, Props>(
  ({ items, disabled, parent, className, onClick }, ref) => (
    <ul {...{ className, ref }}>
      {items.map((item, index) => (
        <li
          key={index}
          className={classnames({
            'cursor-pointer': !disabled,
            'py-5': !parent,
            'py-2': parent,
            'border-b border-solid border-gray-200': !parent && index < items.length - 1
          })}
          onClick={() => !disabled && onClick(item)}
        >
          <div className="flex justify-between">
            {!parent && (
              <div className={classnames('w-[2em]', { 'text-gray-800': disabled, 'text-scarlet': !disabled })}>
                {getEntryNumber(index + 1, parent)}
              </div>
            )}
            <div className="flex-1">
              <div
                className={classnames({
                  'text-gray': disabled,
                  'text-gray-800': !disabled,
                  'font-semibold': !parent
                })}
              >
                {item.name}
              </div>

              {item.subEntries && item.subEntries.length > 0 && (
                <TableOfContents
                  {...{ disabled, onClick }}
                  items={item.subEntries}
                  parent={getEntryNumber(index + 1, parent)}
                  className="pt-2 -mb-2"
                />
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
);
