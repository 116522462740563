import type { FirebaseApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';

import { Analytics } from '../types';
import { EventProperties, EventType } from '../event-properties';

interface FirebaseAnalyticsOptions {
  app: FirebaseApp;
}

export class FirebaseAnalytics implements Analytics {
  private analytics: ReturnType<typeof getAnalytics>;

  constructor({ app }: FirebaseAnalyticsOptions) {
    this.analytics = getAnalytics(app);
  }

  getCrossDomainParameters(): Record<string, string | undefined> {
    return {};
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E]): void {
    return logEvent(this.analytics, type.replace(/\s/g, '_'), event);
  }

  setCurrentUser(userId?: string): void {
    return setUserId(this.analytics, userId as string);
  }

  trackUser() {}
}
