import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { storeLinks } from '@/constants';

import { TenantContainer } from '@/containers/TenantContainer';
import { Logo } from '@/components/Logo';

import appStore from '@/assets/app-store-button.svg';
import playStore from '@/assets/play-store-button.svg';

export interface Props {
  className?: string;
}

export const StoresRedirect: React.FC<Props> = ({ className }) => {
  const { tenant } = TenantContainer.useContainer();

  return (
    <div
      className={classnames('flex flex-col items-center text-center bg-white text-gray-800 h-full relative', className)}
    >
      <div className="flex items-center shadow-lg px-6 bg-blue-900 h-16 w-full">
        <Logo tenant={tenant} type="white" />

        <span className="text-white">
          <FormattedMessage id={translations.application.storesRedirect.header} values={{ tenant: tenant }} />
        </span>
      </div>

      <div className="flex flex-col items-center my-auto">
        <div className="sm:text-headline-2 mb-6 mt-10 font-semibold px-6">
          <FormattedMessage id={translations.application.storesRedirect.title} />
        </div>

        <div className="sm:text-title text-note mb-10 px-6">
          <FormattedMessage id={translations.application.storesRedirect.subtitle} />
        </div>

        <div className="flex flex-col sm:flex-row">
          <a target="_blank" rel="noopener noreferrer" href={storeLinks.playStore}>
            <img src={playStore} />
          </a>

          <a target="_blank" rel="noopener noreferrer" href={storeLinks.appStore} className="mt-4 sm:mt-0 sm:ml-6">
            <img src={appStore} />
          </a>
        </div>
      </div>
    </div>
  );
};
