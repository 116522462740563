import React from 'react';

import { Highlight } from '@/domains';

export interface State {
  value?: Highlight[];
  loading: boolean;
  error?: Error;
}

export type Actions =
  | { type: 'success'; value: Highlight[] }
  | { type: 'error'; error: Error }
  | { type: 'upsert-ordered-by-location'; highlight: Highlight }
  | { type: 'delete-by-ids'; ids: string[] };

export const reducer: React.Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case 'success':
      return { value: action.value, loading: false };
    case 'error':
      return { loading: false, error: action.error };
    case 'upsert-ordered-by-location': {
      if (!state.value) return { ...state, value: [action.highlight] };

      return {
        ...state,
        value: [...state.value.filter((item) => item.id !== action.highlight.id), action.highlight].sort((one, other) =>
          one.sortingLocation.localeCompare(other.sortingLocation)
        )
      };
    }
    case 'delete-by-ids': {
      if (!state.value) return state;

      return {
        ...state,
        value: state.value.filter((bookmark) => !action.ids.includes(bookmark.id))
      };
    }
  }
};
