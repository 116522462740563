import React from 'react';
import { useMap } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { createContainer } from 'unstated-next';
import { IReaderViewAnnotation } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

import { Bookmark } from '@/domains';
import { Reader } from '@/components/Reader/containers/ReaderContainer';

import {
  BasicBookmarkAnnotationData,
  BookmarkAnnotationLayer,
  IBookmarkAnnotationLayer
} from './bookmark-annotation-layer';
import { reducer } from './reducer';

type BookmarksMap = Record<string, IReaderViewAnnotation<BasicBookmarkAnnotationData>>;

export interface OnBookmarkCreate extends BasicBookmarkAnnotationData {}

export interface RemoveBookmarkOptions {
  id: string;
  location: string;
}

export interface Options {
  load(): Promise<Bookmark[]>;
  onBookmarkCreate(data: OnBookmarkCreate): Promise<Bookmark>;
  onBookmarkDelete(id: string): Promise<{ id: string }>;
}

export const useBookmarkAnnotations = ({ load, onBookmarkCreate, onBookmarkDelete }: Options = {} as Options) => {
  const { readerService } = Reader.useContainer();
  const [visibleBookmarksMap, visibleBookmarks] = useMap<BookmarksMap>();
  const [state, dispatch] = React.useReducer(reducer, { loading: true });
  const bookmarksAnnotationLayer: IBookmarkAnnotationLayer<BasicBookmarkAnnotationData> = React.useMemo<
    IBookmarkAnnotationLayer<BasicBookmarkAnnotationData>
  >(
    () => new BookmarkAnnotationLayer<BasicBookmarkAnnotationData>('bookmarks-annotation-layer', readerService.view),
    [readerService.view]
  );

  React.useEffect(() => {
    load()
      .then((value) => {
        value.forEach((bookmark) => {
          bookmarksAnnotationLayer.addBookmark(bookmark);
        });
        dispatch({ type: 'success', value });
      })
      .catch((error) => {
        dispatch({ type: 'error', error });
      });

    return () => bookmarksAnnotationLayer.unloadAll();
  }, []);

  React.useEffect(
    () =>
      bookmarksAnnotationLayer.subscribeToEvent('annotationIntersectingVisibleRange', (event) => {
        const data = event.annotation.getCustomData() as BasicBookmarkAnnotationData;

        visibleBookmarks.set(data.location, event.annotation);
      }),
    [visibleBookmarks.set]
  );

  React.useEffect(
    () =>
      bookmarksAnnotationLayer.subscribeToEvent('annotationOutsideVisibleRange', (event) => {
        const data = event.annotation.getCustomData() as BasicBookmarkAnnotationData;

        visibleBookmarks.remove(data.location);
      }),
    [visibleBookmarks.remove]
  );

  const toggle = async () => {
    if (!Object.values(visibleBookmarksMap).length) {
      const annotation = await bookmarksAnnotationLayer.addBookmark({ id: uuidv4() });

      if (!annotation) return;

      const data = annotation.getCustomData();

      if (!data) return;

      await onBookmarkCreate(data).then((bookmark) => {
        if (!bookmark) return;

        dispatch({ type: 'insert-ordered-by-location', bookmark });
      });
    } else {
      await Promise.all(
        Object.keys(visibleBookmarksMap).map((location) => {
          const annotation = bookmarksAnnotationLayer.deleteBookmark({ location });

          const id = annotation?.getCustomData()?.id;

          if (!id) return;

          return onBookmarkDelete(id);
        })
      ).then((results) => {
        const defined = (item?: { id: string }): item is { id: string } => !!item;
        const ids = results.filter(defined).map(({ id }) => id);

        dispatch({ type: 'delete-by-ids', ids });
      });
    }
  };

  const remove = React.useCallback(async ({ id, location }: RemoveBookmarkOptions) => {
    bookmarksAnnotationLayer.deleteBookmark({ location });

    await onBookmarkDelete(id).then(() => dispatch({ type: 'delete-by-ids', ids: [id] }));
  }, []);

  return React.useMemo(
    () => ({
      list: state.value,
      loading: state.loading,
      active: Object.values(visibleBookmarksMap).length > 0,
      toggle,
      remove
    }),
    [state.value, state.loading, visibleBookmarksMap, toggle, remove]
  );
};

export const BookmarkAnnotations = createContainer(useBookmarkAnnotations);
