import { Language } from '@/constants';
import { Tenant } from '@/domains';
import { SearchType, SearchResult } from '@/domains/search';
import { authorizedApi } from '../network/authorized-api';

export const search = (
  query: string,
  tenant: Tenant,
  language: Language,
  types: {
    type: SearchType;
    language: Language;
  }[],
  lessonId?: string
) => authorizedApi.post<SearchResult>('/v1/search', { query, tenant, language, types, lessonId });
