import useSWR from 'swr';

import { getModuleLessons } from '@/services/api';

import { TenantContainer } from '@/containers/TenantContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { SessionContainer } from '@/containers/SessionContainer';

export const useLoadSupplementModuleLessons = (moduleId: string) => {
  const { tenant } = TenantContainer.useContainer();
  const { language } = LanguageContainer.useContainer();
  const { preferences } = SessionContainer.useContainer();

  return useSWR(
    { key: `getModuleLessons`, moduleId, language, tenant, contentLanguage: preferences.contentLanguage },
    ({ key, contentLanguage, ...o }) => getModuleLessons(o),
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  );
};
