import { initializeApp } from 'firebase/app';

import { FIREBASE_API_KEY, FIREBASE_APP_ID, FIREBASE_MEASUREMENT_ID } from '@/config';

export const firebaseApp = initializeApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: 'srf-lessons.firebaseapp.com',
  databaseURL: 'https://srf-lessons.firebaseio.com',
  projectId: 'srf-lessons',
  storageBucket: 'srf-lessons.appspot.com',
  messagingSenderId: '544970633035',
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
});
