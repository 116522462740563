import React from 'react';
import { useNavigate, useOutletContext } from 'react-router';

import { urls } from '@/constants';
import { Lesson } from '@/domains';

import { TableOfContents } from '@/components/TableOfContents';

import { useLessonDisabled } from '../hooks/useLessonDisabled';

export const LessonTOCPage: React.FC = () => {
  const navigate = useNavigate();
  const lesson = useOutletContext<Lesson>();
  const disabled = useLessonDisabled(lesson);

  return (
    <TableOfContents
      {...{ disabled }}
      items={lesson.tableOfContentsEntries}
      onClick={(item) => navigate('./reader', urls.getReaderParams({ selector: item.epubLocation }))}
    />
  );
};
