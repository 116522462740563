import { generatePath } from 'react-router';

import { LessonType } from '@/domains';
import { lessonTypeToUrlParam } from '@/utils/lesson-view-to-type';

export const reader = `/reader`;

export const catchAll = (route: string) => `${route}/*`;
export const relative = (route: string) => route.replace(/^\//, '');

export const annotations = {
  root: `/annotations`,
  bookmarks: `/bookmarks`,
  highlights: `/highlights`
};

export const flashcards = `/flashcards`;

export const otherContent = {
  root: '/others',
  vld: {
    root: `/vld-handbook`,
    reader: `/reader`
  }
};

/**
 * /content/{basic,kriya,supplement}/lessons
 * /content/{basic,kriya,supplement}/lessons/:lessonId
 * /content/{basic,kriya,supplement}/lessons/:lessonId/reader
 * /content/{basic,kriya,supplement}/lessons/:lessonId/materials
 * /content/{basic,kriya,supplement}/lessons/:lessonId/materials/:materialId
 * /content/{basic,kriya,supplement}/lessons/:lessonId/materials/:materialId/reader
 *
 * /content/supplement/modules
 * /content/supplement/modules/:moduleId/lessons
 * /content/supplement/modules/:moduleId/lessons/:lessonId
 * /content/supplement/modules/:moduleId/lessons/:lessonId/reader
 * /content/supplement/modules/:moduleId/lessons/:lessonId/materials
 * /content/supplement/modules/:moduleId/lessons/:lessonId/materials/:materialId
 * /content/supplement/modules/:moduleId/lessons/:lessonId/materials/:materialId/reader
 */

export const getModuleListUrl = () => {
  return generatePath(`/content/:lessonType/modules`, {
    lessonType: lessonTypeToUrlParam[LessonType.Supplement]
  });
};

interface GetModuleUrlOptions {
  moduleId: string;
}

export const getModuleUrl = (o: GetModuleUrlOptions) => {
  return `${getModuleListUrl()}${generatePath(`/:moduleId`, o as {})}/lessons`;
};

interface GetLessonListUrlOptions {
  lessonType: LessonType | string;
  moduleId?: string;
}

export const getLessonListUrl = (o: GetLessonListUrlOptions) => {
  const options = { ...o, lessonType: lessonTypeToUrlParam[o.lessonType as LessonType] ?? o.lessonType };

  if (o.lessonType === LessonType.Supplement && o.moduleId) return getModuleUrl(options as GetModuleUrlOptions);

  return generatePath(`/content/:lessonType/lessons`, options);
};

interface GetLessonUrlOptions extends GetLessonListUrlOptions {
  lessonId: string;
}

export const getLessonUrl = (o: GetLessonUrlOptions) => {
  return `${getLessonListUrl(o)}${generatePath(`/:lessonId`, o as {})}`;
};

interface GetMaterialUrlOptions extends GetLessonUrlOptions {
  materialId: string;
}

export const getMaterialUrl = (o: GetMaterialUrlOptions) => {
  return `${getLessonUrl(o)}${generatePath(`/materials/:materialId`, o as {})}`;
};

interface GetReaderParamsOptions {
  selector?: string;
  listen?: boolean;
}

export const getReaderParams = (o: GetReaderParamsOptions) => ({ state: o });
