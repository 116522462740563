import React from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, useLocation } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { urls } from '@/constants';
import { translations } from '@/locales';

import { HeaderMenuButtonWithArrow, HeaderMenuDropdown } from '../HeaderMenu';
import { OtherContentDropdownMenu } from './OtherContentDropdownMenu';

const text = translations.application.header;

export const OtherContentMenu = () => {
  const location = useLocation();

  const active = !!matchPath({ path: urls.otherContent.root, end: false }, location.pathname);

  return (
    <Menu as="div" className="relative font-semibold">
      <HeaderMenuButtonWithArrow {...{ active }}>
        <FormattedMessage id={text.otherContent.title} />
      </HeaderMenuButtonWithArrow>

      <HeaderMenuDropdown className="left-0 top-1 w-60">
        <OtherContentDropdownMenu />
      </HeaderMenuDropdown>
    </Menu>
  );
};
