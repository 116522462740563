import React from 'react';
import { useAsyncFn } from 'react-use';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { InstanceProps } from '@/components/Modal';

interface Props extends InstanceProps<[]> {}

export const ConfirmationModal: React.FC<Props> = ({ onAction, onClose }) => {
  const [{ loading }, onClick] = useAsyncFn(async () => {
    await onAction();
    onClose();
  }, [onAction, onClose]);

  return (
    <div className="w-80">
      <p className="mb-6 text-title">
        <FormattedMessage id={translations.modals.confirmation.text} />
      </p>

      <div className="flex justify-between space-x-4">
        <Button appearance="scarlet" className="w-full" {...{ loading, onClick }}>
          <FormattedMessage id={translations.modals.confirmation.yes} />
        </Button>

        <Button className="w-full bg-gray-100 hover:bg-gray-200" onClick={onClose}>
          <FormattedMessage id={translations.modals.confirmation.no} />
        </Button>
      </div>
    </div>
  );
};
