import { SessionContainer } from '@/containers/SessionContainer';
import React from 'react';

export const useReaderSession = (publicationId?: string | number) => {
  const { me } = SessionContainer.useContainer();

  return React.useMemo(
    () => ({
      userToken: me?.id ?? 'guest_user_token',
      publicationToken: publicationId?.toString() ?? 'unknown_publication'
    }),
    [me?.id, publicationId]
  );
};
