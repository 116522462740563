import React from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';

import { analytics, convertLessonType, convertMaterialType } from '@/services/analytics';
import { getAuxiliaryMaterial } from '@/services/api';
import { AuxiliaryAudioMaterial, AuxiliaryMaterialType, AuxiliaryVideoMaterial, Lesson } from '@/domains';

import { Modal } from '@/components/Modal';
import { VideoPlayerModal } from '@/components/VideoPlayerModal';

export const LessonAuxiliaryMaterialDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const lesson = useOutletContext<Lesson>();

  const { materialId } = useParams<{ materialId: string }>();

  const [handled, setHandled] = React.useState(false);
  const [playerUrl, setPlayerUrl] = React.useState<string>();

  const material = lesson.auxiliaryMaterials.find((m) => `${m.id}` === materialId);

  React.useEffect(() => {
    if (!material) return;

    if (!material) return navigate('..', { replace: true });

    switch (material.type) {
      case AuxiliaryMaterialType.Audio:
      case AuxiliaryMaterialType.Video:
        getAuxiliaryMaterial(lesson.id, material.id)
          .then((value) => setPlayerUrl((value as AuxiliaryAudioMaterial | AuxiliaryVideoMaterial).player))
          .catch(() => navigate('..', { replace: true }));
        break;
      case AuxiliaryMaterialType.Epub:
        return navigate('./reader', { replace: true });
      case AuxiliaryMaterialType.Weblink:
        analytics.trackEvent('view auxiliary content', {
          lesson_title: lesson.title,
          lesson_number: lesson.displayedNumber,
          lesson_type: convertLessonType(lesson.type),
          lesson_language: lesson.language,
          auxiliary_title: material.title,
          auxiliary_type: convertMaterialType(material.type)
        });

        window.open(material.url, '_blank');
        setHandled(true);
        break;
    }
  }, [material, lesson]);

  if (handled) return <Navigate to=".." replace />;

  if (!material || !playerUrl) return null;

  return (
    <Modal
      isOpen
      modal={VideoPlayerModal}
      {...{ playerUrl }}
      onAction={() => null}
      onClose={() => setHandled(true)}
      onTrackDuration={(duration: number) => {
        analytics.trackEvent('view auxiliary content', {
          lesson_title: lesson.title,
          lesson_number: lesson.displayedNumber,
          lesson_type: convertLessonType(lesson.type),
          lesson_language: lesson.language,
          auxiliary_title: material.title,
          auxiliary_type: convertMaterialType(material.type),
          consumption_duration: Math.round(duration / 1000)
        });
      }}
    />
  );
};
