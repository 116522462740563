import React from 'react';
import { useOutletContext, Outlet } from 'react-router';
import classnames from 'classnames';
import { PrinterIcon } from '@heroicons/react/outline';

import { Lesson } from '@/domains';
import { isEpubAuxiliaryMaterial } from '@/utils';

import { AuxiliaryMaterial } from '@/components/AuxiliaryMaterial';
import { Link } from '@/components/Link';

import { useLessonDisabled } from '../hooks/useLessonDisabled';

export const LessonAuxiliaryMaterialPage: React.FC = () => {
  const lesson = useOutletContext<Lesson>();
  const disabled = useLessonDisabled(lesson);

  return (
    <React.Fragment>
      <ul>
        {lesson.auxiliaryMaterials.map((material) => {
          const hasPdf = isEpubAuxiliaryMaterial(material) && !!material.pdf;

          return (
            <li key={material.id} className="flex items-center gap-4 whitespace-nowrap">
              <AuxiliaryMaterial
                is={Link}
                item={material}
                className={classnames('py-4', disabled ? 'cursor-default pointer-events-none' : 'cursor-pointer')}
                {...{ disabled }}
                to={`./${material.id}`}
              />

              {!!hasPdf && (
                <Link external to={material.pdf!} className="px-0 h-fit" appearance="scarlet" {...{ disabled }}>
                  <PrinterIcon className="h-6 w-6" />
                </Link>
              )}
            </li>
          );
        })}
      </ul>

      <Outlet context={lesson} />
    </React.Fragment>
  );
};
