import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { ContentLanguage } from '@/domains';
import { RemoteConfigKey } from '@/services/remote-config';

import { TenantContainer } from '@/containers/TenantContainer';
import { SessionContainer } from '@/containers/SessionContainer';
import { defaultLanguages } from '@/containers/LanguageContainer';
import { Loading, LoadingIndicator } from '@/components/Loading';

import { LanguageListItem } from './LanguageListItem';
import { BackToViewButton } from './BackToViewButton';

export interface Props {
  onBack: () => void;
}

export const LessonsLanguageSelectionView: React.FC<Props> = ({ onBack }) => {
  const { config, tenant } = TenantContainer.useContainer();
  const { preferences, updatePreferences } = SessionContainer.useContainer();

  const languages = config[RemoteConfigKey.LessonLanguages];

  const pinnedLanguage = defaultLanguages[tenant];

  const options: { value: ContentLanguage; text: string }[] = React.useMemo(
    () => [
      { value: 'auto', text: translations.misc.auto },
      { value: pinnedLanguage, text: translations.i18n.languages[pinnedLanguage] },
      ...languages
        .filter((language) => pinnedLanguage !== language)
        .sort()
        .map((language) => ({
          value: language,
          text: translations.i18n.languages[language]
        }))
    ],
    [languages]
  );

  const setUserLessonsLanguage = React.useCallback(
    (contentLanguage: ContentLanguage) => updatePreferences({ contentLanguage }),
    [updatePreferences]
  );

  return (
    <div>
      <BackToViewButton onClick={onBack}>
        <div className="flex items-center gap-2">
          <FormattedMessage id={translations.misc.mainMenu} />

          <Loading visible={preferences.loading}>
            <LoadingIndicator className="w-4 h-4" />
          </Loading>
        </div>
      </BackToViewButton>

      <ul>
        {options.map(({ value, text }) => (
          <LanguageListItem
            key={value}
            selected={preferences.contentLanguage === value}
            onClick={() => setUserLessonsLanguage(value)}
          >
            <FormattedMessage id={text} />
          </LanguageListItem>
        ))}
      </ul>
    </div>
  );
};
