import React from 'react';

import { Reader } from '@/components/Reader/containers/ReaderContainer';
import { HighlightAnnotationLayer } from '@/components/Reader/HighlightAnnotations/highlight-annotation-layer';

export interface Options {
  selection: string;
}

export const useHighlightSearchResult = () => {
  const { readerService, loading, selector } = Reader.useContainer();
  const searchHighlightAnnotationLayer = React.useMemo(
    () => new HighlightAnnotationLayer('search-highlight-annotation-layer', readerService.view),
    []
  );

  React.useEffect(() => {
    if (loading || !selector) return;

    searchHighlightAnnotationLayer.unloadAll();
    searchHighlightAnnotationLayer.addHighlight({ location: selector, colorCode: '#f7ddcc' });

    const timeout = setTimeout(() => searchHighlightAnnotationLayer.deleteHighlight({ location: selector }), 3000);

    return () => clearTimeout(timeout);
  }, [loading, selector, searchHighlightAnnotationLayer]);
};
