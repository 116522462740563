import { ContentLanguage } from '@/domains/content-language';

export type UserPreferences = Partial<{
  contentLanguage: ContentLanguage;
  supplementLessonsView: SupplementLessonsView;
  videoQuality: VideoQuality;
}>;

export enum SupplementLessonsView {
  All = 'ALL',
  Modules = 'MODULES'
}

export enum VideoQuality {
  Value4K = '4K',
  Value2K = '2K',
  Value1080p = '1080p',
  Value720p = '720p',
  Value540p = '540p',
  Value360p = '360p',
  Value240p = '240p'
}
