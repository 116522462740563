import { Language } from '@/constants';
import { Tenant } from '@/domains/tenant';

import { LessonListItem } from './lesson-list-item';

export enum SupplementLessonModuleContentSummary {
  HasAvailableLessons = 'HAS_AVAILABLE_LESSONS',
  NoAvailableLessons = 'NO_AVAILABLE_LESSONS'
}

export interface SupplementLessonModule {
  id: string;
  name: string;
  imageUrl: string;
  createdAt: string;
  updatedAt: string;
  language: Language;
  tenant: Tenant;
  contentSummary: SupplementLessonModuleContentSummary;
}

export interface SupplementLessonModuleLessons extends SupplementLessonModule {
  lessons: LessonListItem[];
}
