import React from 'react';
import { Menu } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { translations } from '@/locales';
import { getLanguageFamily, Language, languageToSettingsUrlMap, urls } from '@/constants';

import { useLogout } from '@/hooks/useLogout';
import { Link } from '@/components/Link';
import { SessionContainer } from '@/containers/SessionContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';

import { ApplicationLanguageSelectionView } from './ApplicationLanguageSelectionView';
import { LessonsLanguageSelectionView } from './LessonsLanguageSelectionView';

import { OpenViewButton } from './OpenViewButton';

const text = translations.application.header;

type View = 'main' | 'languages' | 'lessons-language';

interface Props {
  onSupport(): void;
}

export const ProfileDropdownMenu: React.FC<Props> = ({ onSupport }) => {
  const { language } = LanguageContainer.useContainer();
  const { authenticated, preferences } = SessionContainer.useContainer();
  const logout = useLogout('regular');

  const [view, setView] = React.useState<View>('main');

  const legalPages = [
    {
      url: languageToSettingsUrlMap[language].privacyPolicy,
      text: text.settings.pp
    },
    {
      url: languageToSettingsUrlMap[language].terms,
      text: text.settings.tos
    },
    {
      url: languageToSettingsUrlMap[language].faq,
      text: text.settings.faq
    }
  ];

  return (
    <React.Fragment>
      {view === 'main' && (
        <ul>
          {authenticated && (
            <React.Fragment>
              <li>
                <Menu.Item
                  as={RouterLink}
                  to={[urls.annotations.root].join('')}
                  className="inline-block px-4 py-3 w-full hover:bg-gray-100"
                >
                  <FormattedMessage id={text.settings.bookmarksAndHighlights} />
                </Menu.Item>
              </li>

              <li>
                <Menu.Item
                  as={RouterLink}
                  to={[urls.flashcards].join('')}
                  className="inline-block px-4 py-3 w-full hover:bg-gray-100"
                >
                  <FormattedMessage id={text.settings.flashcards} />
                </Menu.Item>
              </li>
            </React.Fragment>
          )}

          <li>
            <OpenViewButton onClick={() => setView('languages')}>
              <FormattedMessage
                id={text.settings.language}
                values={{ code: getLanguageFamily(language.toUpperCase() as Language) }}
              />
            </OpenViewButton>
          </li>

          {authenticated && (
            <li>
              <OpenViewButton onClick={() => setView('lessons-language')}>
                <FormattedMessage
                  id={text.settings.lessonsLanguage}
                  values={{
                    code:
                      preferences.contentLanguage === 'auto'
                        ? preferences.contentLanguage
                        : getLanguageFamily(preferences.contentLanguage?.toUpperCase() as Language)
                  }}
                />
              </OpenViewButton>
            </li>
          )}

          {legalPages.map((item) => (
            <li key={item.text}>
              <Menu.Item as={Link} to={item.url} className="inline-block px-4 py-3 w-full hover:bg-gray-100" external>
                <FormattedMessage id={item.text} />
              </Menu.Item>
            </li>
          ))}

          <li>
            <Menu.Item
              as="button"
              className="text-left inline-block px-4 py-3 w-full font-semibold hover:bg-gray-100"
              onClick={onSupport}
            >
              <FormattedMessage id={translations.misc.support} />
            </Menu.Item>
          </li>

          {authenticated && (
            <li className="hover:bg-gray-100 border-solid border-t-[1px] border-gray-200">
              <Menu.Item
                as="button"
                className="text-left inline-block px-4 py-3 w-full font-semibold text-scarlet"
                onClick={logout}
              >
                <FormattedMessage id={translations.misc.signOut} />
              </Menu.Item>
            </li>
          )}
        </ul>
      )}

      {view === 'languages' && <ApplicationLanguageSelectionView onBack={() => setView('main')} />}

      {authenticated && view === 'lessons-language' && <LessonsLanguageSelectionView onBack={() => setView('main')} />}
    </React.Fragment>
  );
};
