import { v4 as uuidv4 } from 'uuid';

import { UpsertHighlightOptions } from '@/services/api';
import { Highlight, ReadLessonSummary } from '@/domains';

import { OnHighlightUpsertOptions } from '@/components/Reader/HighlightAnnotations';

export const isHighlight = (data: OnHighlightUpsertOptions): data is Highlight => !!(data as Highlight).id;

export const toUpsertHighlight = (
  data: OnHighlightUpsertOptions,
  lesson: ReadLessonSummary
): UpsertHighlightOptions => {
  if (!isHighlight(data)) {
    return { ...data, createdAt: new Date().toISOString(), id: uuidv4(), lesson };
  }

  return data;
};
