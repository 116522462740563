import React from 'react';

import { Loading, LoadingCenter, LoadingIndicator } from '@/components/Loading';

export const LoadComponent: React.FC = ({ children }) => (
  <React.Suspense
    fallback={
      <Loading visible={true}>
        <LoadingCenter className="z-30">
          <LoadingIndicator />
        </LoadingCenter>
      </Loading>
    }
  >
    {children}
  </React.Suspense>
);
