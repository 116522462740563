import React from 'react';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tab } from '@headlessui/react';

import { translations } from '@/locales';
import { AuxiliaryMaterial, AuxiliaryMaterialType, Lesson } from '@/domains';
import { analytics, convertLessonType, convertMaterialType } from '@/services/analytics';

import { useTrackPageTime } from '@/hooks/useTrackPageTime';
import { useReaderSession } from '@/components/Reader/hooks/useReaderSession';
import { useConsumptionDepth } from '@/components/Reader/hooks/useConsumptionDepth';
import { useHasTOC } from '@/components/Reader/hooks/useHasTOC';

import { Sidebar } from '@/components/Reader/Sidebar';
import { ReaderHeader } from '@/components/Reader/ReaderHeader';
import { ReaderView } from '@/components/Reader/ReaderView';
import { SidebarTab } from '@/components/Reader/Sidebar/SidebarTab';
import { SidebarTabList } from '@/components/Reader/Sidebar/SidebarTabList';
import { TableOfContentsTab } from '@/components/Reader/Sidebar/TableOfContentsTab';
import { ReaderForwardIframeEvents } from '@/components/Reader/ReaderForwardIframeEvents';
import { Reader as ReaderContainer } from '@/components/Reader/containers/ReaderContainer';
import { SidebarButton } from '@/components/Reader/ReaderHeader/ReaderHeaderButton/SidebarButton';
import { FontSizeButton } from '@/components/Reader/ReaderHeader/ReaderHeaderButton/FontSizeButton';
import { ReaderFooter } from '@/components/Reader/ReaderFooter';
import { ReaderLessonBreadcrumb } from '@/components/Reader/ReaderHeader/ReaderLessonBreadcrumb';

import { useLoadLesson } from '../hooks/useLoadLesson';

const text = translations.pages.reader;

const AuxiliaryMaterialReader: React.FC<{ lesson: Lesson; material: AuxiliaryMaterial }> = ({ lesson, material }) => {
  const { readerService, audiobook } = ReaderContainer.useContainer();

  const getConsumptionDepth = useConsumptionDepth(readerService);

  const hasTOC = useHasTOC(3);

  useTrackPageTime(
    (duration) => {
      if (!lesson.id) return;

      analytics.trackEvent('view auxiliary content', {
        method: audiobook.on ? 'listen' : 'read',
        lesson_title: lesson.title,
        lesson_number: lesson.displayedNumber,
        lesson_type: convertLessonType(lesson.type),
        lesson_language: lesson.language,
        auxiliary_title: material.title,
        auxiliary_type: convertMaterialType(material.type),
        consumption_depth: getConsumptionDepth(),
        consumption_duration: Math.round(duration / 1000)
      });
    },
    [lesson?.id, getConsumptionDepth]
  );

  return (
    <div className="flex flex-col relative h-full">
      <ReaderHeader>
        <div className="w-full">
          <ReaderLessonBreadcrumb {...{ lesson }} />
        </div>

        <FontSizeButton />

        {!!hasTOC && <SidebarButton />}
      </ReaderHeader>

      {!!hasTOC && (
        <Sidebar>
          <Tab.Group>
            <SidebarTabList>
              <SidebarTab>
                <FormattedMessage id={text.sidebar.toc} />
              </SidebarTab>
            </SidebarTabList>

            <Tab.Panels>
              <Tab.Panel>
                <TableOfContentsTab />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Sidebar>
      )}

      <ReaderForwardIframeEvents />

      <ReaderView />

      <ReaderFooter />
    </div>
  );
};

export const AuxiliaryMaterialReaderPage: React.FC = () => {
  const { lessonId, materialId } = useParams<{ lessonId: string; materialId: string }>();

  const { data: lesson, isValidating } = useLoadLesson(lessonId!, { revalidate: false });

  const material = lesson?.auxiliaryMaterials.find((m) => `${m.id}` === materialId);

  const session = useReaderSession(material?.id);

  if (isValidating || !lesson) return null;

  if (!material || material.type !== AuxiliaryMaterialType.Epub) return <Navigate to="../.." replace />;

  return (
    <ReaderContainer.Provider initialState={{ file: { url: material.url }, session }}>
      <AuxiliaryMaterialReader {...{ lesson, material }} />
    </ReaderContainer.Provider>
  );
};
