declare let CONFIG: {
  ENVIRONMENT: string;
  YSS_DOMAIN: string;
  SRF_DOMAIN: string;
  API: string;
  RELEASE: string;
  COLIBRIO_LICENSE_API_KEY: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
  AMPLITUDE_API_KEY?: string;
  FIREBASE_API_KEY?: string;
  FIREBASE_APP_ID?: string;
  FIREBASE_MEASUREMENT_ID?: string;
  SENTRY_DSN?: string;
};

export const {
  ENVIRONMENT,
  YSS_DOMAIN,
  SRF_DOMAIN,
  API,
  RELEASE,
  COLIBRIO_LICENSE_API_KEY,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
  AMPLITUDE_API_KEY,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  SENTRY_DSN
} = CONFIG;
