import React from 'react';

import { Icon } from '@/components/Icon';
import { Reader } from '@/components/Reader/containers/ReaderContainer';
import { Modal, useModal } from '@/components/Modal';
import { ConfirmationModal } from '@/components/Modals/ConfirmationModal';
import { BookmarkAnnotations } from '@/components/Reader/BookmarkAnnotations';

import { ReaderHeaderButton } from './ReaderHeaderButton';

export interface Props {}

export const BookmarkButton: React.FC<Props> = () => {
  const { loading } = Reader.useContainer();
  const bookmarks = BookmarkAnnotations.useContainer();

  const modal = useModal(ConfirmationModal);

  return (
    <React.Fragment>
      <Modal {...modal.props} />

      <ReaderHeaderButton
        is="button"
        active={bookmarks.active}
        onClick={() => (bookmarks.active ? modal.open(() => bookmarks.toggle()) : bookmarks.toggle())}
        disabled={loading}
      >
        <Icon type="bookmark-empty" />
      </ReaderHeaderButton>
    </React.Fragment>
  );
};
