import React from 'react';
import Helmet from 'react-helmet';

import 'tailwindcss/tailwind.css';
import './styles/main.tailwind.css';
import './styles/base.sass';

import { TenantContainer } from '@/containers/TenantContainer';
import { SessionContainer } from '@/containers/SessionContainer';

import { ForceUpdate } from '@/components/ForceUpdate';
import { ScrollToTop } from '@/components/ScrollToTop';
import { AppContent } from '@/components/App/AppContent';
import { StoresRedirect } from '@/components/StoresRedirect';
import { Loading, LoadingOverlay, LoadingIndicator, LoadingCenter } from '@/components/Loading';

export const App: React.FC = () => {
  const { loading: tenantLoading } = TenantContainer.useContainer();
  const { loading: sessionLoading } = SessionContainer.useContainer();

  const loading = tenantLoading || sessionLoading;

  return (
    <React.Fragment>
      <ScrollToTop />
      <Helmet defaultTitle="Teachings" titleTemplate="%s - Yogananda SRF" />

      <Loading visible={loading}>
        <LoadingOverlay className="z-30 opacity-80" />

        <LoadingCenter className="z-30">
          <LoadingIndicator />
        </LoadingCenter>
      </Loading>

      <StoresRedirect className="lg:hidden" />

      {!loading && (
        <ForceUpdate>
          <AppContent className="hidden flex-col lg:flex" />
        </ForceUpdate>
      )}
    </React.Fragment>
  );
};
