import useSWR from 'swr';
import React from 'react';
import { createContainer } from 'unstated-next';

import { Tenant } from '@/domains';
import { SRF_DOMAIN, YSS_DOMAIN } from '@/config';
import { getTenantFeatures, LanguageFeature, remoteConfig, RemoteConfigKey } from '@/services/remote-config';

const tenantDomainMap: Record<Tenant, string> = {
  [Tenant.SRF]: SRF_DOMAIN,
  [Tenant.YSS]: YSS_DOMAIN
};

const getTenantFromHost = (host: string) => {
  if (host === YSS_DOMAIN) return Tenant.YSS;
  return Tenant.SRF;
};

const redirectByTenant = (tenant: Tenant) => {
  window.open(`https://${tenantDomainMap[tenant]}`, '_self');
};

const useTenant = () => {
  const tenant = React.useMemo(() => getTenantFromHost(window.location.host), []);

  const lowercasedTenant = tenant.toLowerCase() as Lowercase<Tenant>;

  const { data: appConfig, isValidating: appConfigLoading } = useSWR({ key: 'remoteConfig.getValue' }, () =>
    Promise.all([
      remoteConfig.getValue(RemoteConfigKey.LessonLanguages),
      remoteConfig.getValue(RemoteConfigKey.LanguageFeatures),
      remoteConfig.getValue(RemoteConfigKey.AppLanguages)
    ]).then(([lessonLanguages, languageFeatures, appLanguages]) => {
      return {
        lessonLanguages,
        languageFeatures: getTenantFeatures(lowercasedTenant, languageFeatures),
        appLanguages
      };
    })
  );

  const loading = appConfigLoading;

  const defaultLanguageFeatures = {
    [LanguageFeature.TodaysInpiration]: [],
    [LanguageFeature.NewContent]: [],
    [LanguageFeature.LiveEvents]: [],
    [LanguageFeature.BasicLessons]: [],
    [LanguageFeature.KriyaLessons]: [],
    [LanguageFeature.SupplementLessons]: [],
    [LanguageFeature.OnlineMeditation]: [],
    [LanguageFeature.GuidedMeditation]: []
  };

  return React.useMemo(() => {
    return {
      tenant,
      loading,
      redirectByTenant,
      config: {
        [RemoteConfigKey.LessonLanguages]: appConfig?.lessonLanguages?.[lowercasedTenant] ?? [],
        [RemoteConfigKey.LanguageFeatures]: appConfig?.languageFeatures ?? defaultLanguageFeatures,
        [RemoteConfigKey.AppLanguages]: appConfig?.appLanguages?.[lowercasedTenant] ?? []
      }
    };
  }, [tenant, loading, appConfig, redirectByTenant]);
};

export const TenantContainer = createContainer(useTenant);
