import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Tenant } from '@/domains';
import { TenantContainer } from '@/containers/TenantContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';

const connections: Record<Tenant, string> = {
  [Tenant.SRF]: 'srf-users',
  [Tenant.YSS]: 'yss-users'
};

export const useLogin = () => {
  const { loginWithRedirect } = useAuth0();
  const { tenant } = TenantContainer.useContainer();
  const { language } = LanguageContainer.useContainer();

  return React.useCallback(() => {
    loginWithRedirect({
      connection: connections[tenant],
      appState: `${window.location.pathname}${window.location.search ?? ''}`,
      language,
      ui_locales: language
    });
  }, [language]);
};
