import React from 'react';
import classnames from 'classnames';

import style from './Input.sass';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  is?: any;
  id: string;
}

export const Input: React.FC<Props> = ({ is: Component = 'input', className, ...rest }) => {
  const input = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (rest.autoFocus) setTimeout(() => input.current && input.current.focus(), 100);
  }, [rest.autoFocus]);

  return <Component className={classnames(style.root, className)} {...rest} ref={input} />;
};
