import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Rule, usePermissions } from '@/hooks/usePermissions';

import { BasicLessonPage } from './BasicLessonPage';
import { KriyaLessonPage } from './KriyaLessonPage';
import { SupplementLessonPage } from './SupplementLessonPage';

export const ContentPage: React.FC = () => {
  const { permissions } = usePermissions();

  return (
    <Routes>
      <Route path="basic/*" element={<BasicLessonPage />} />

      {permissions[Rule.LessonsKriyaView] && <Route path="kriya/*" element={<KriyaLessonPage />} />}

      {permissions[Rule.LessonsSupplementView] && <Route path="supplement/*" element={<SupplementLessonPage />} />}

      <Route path="*" element={<Navigate to="/content/basic/lessons" />} />
    </Routes>
  );
};
