import useSWR from 'swr';

import { getLessonById } from '@/services/api';

import { LanguageContainer } from '@/containers/LanguageContainer';

export const useLoadLesson = (
  lessonId: string,
  { revalidate = true }: { revalidate: boolean } = { revalidate: true }
) => {
  const { language } = LanguageContainer.useContainer();

  return useSWR({ key: `getLessonById`, lessonId, language }, ({ key, ...o }) => getLessonById(o), {
    revalidateOnMount: true,
    revalidateIfStale: revalidate,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
