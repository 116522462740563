import React from 'react';
import classnames from 'classnames';
import { useLocalStorage } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { Transition } from '@headlessui/react';

import { translations } from '@/locales';

import { Button } from '@/components/Button';

import lotu7lightBlue from '@/assets/lotus-7-light-blue.svg';

export interface Props {}

export const ConfidentialityNotice: React.FC<Props> = () => {
  const [accepted, setAccepted] = useLocalStorage('confidentiality-notice-accepted', false);

  if (accepted) return null;

  return (
    <div className="h-full w-full flex items-center justify-center absolute inset-0 bg-black bg-opacity-30 z-40">
      <div
        className={classnames(
          'bg-blue-900 rounded-lg px-6 text-white h-[256px] w-[512px] relative flex flex-col items-center justify-evenly '
        )}
      >
        <div className="text-question">
          <FormattedMessage id={translations.application.confidentialityNotice.text} />
        </div>

        <Transition
          as="img"
          show={true}
          src={lotu7lightBlue}
          enter="transition-all duration-1000 ease-out"
          enterFrom="opacity-0 translate-y-10"
          enterTo="opacity-1 translate-y-0"
          className="h-[12rem] opacity-5 absolute bottom-0 right-0"
          appear
        />

        <Button onClick={() => setAccepted(true)} appearance="scarlet">
          <FormattedMessage id={translations.application.confidentialityNotice.button} />
        </Button>
      </div>
    </div>
  );
};
