import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { SearchType } from '@/domains';
import { analytics, convertSearchType } from '@/services/analytics';
import { translations } from '@/locales';

import { useScreenName } from '@/hooks/useScreenName';
import { Rule, usePermissions } from '@/hooks/usePermissions';
import { useLogin } from '@/hooks/useLogin';
import { TenantContainer } from '@/containers/TenantContainer';
import { SessionContainer } from '@/containers/SessionContainer';
import { Logo } from '@/components/Logo';
import { SearchBar } from '@/components/SearchBar';
import { Button } from '@/components/Button';

import { LessonsMenu } from './LessonsMenu';
import { ProfileMenu } from './ProfileMenu';
import { OtherContentMenu } from './OtherContentMenu';

const searchCategories = [
  SearchType.AuxiliaryMaterial,
  SearchType.Glossary,
  SearchType.LessonContent,
  SearchType.Highlight
];

export const Header = () => {
  const { tenant } = TenantContainer.useContainer();
  const { authenticated, loading } = SessionContainer.useContainer();
  const login = useLogin();

  const { permissions } = usePermissions();

  const screenName = useScreenName();

  return (
    <header className="flex items-center justify-between h-full z-[100] w-full px-[5.25rem] bg-blue-900 text-white">
      <div className="flex items-center space-x-4">
        <Link to="/">
          <Logo tenant={tenant} type="white" />
        </Link>

        <LessonsMenu />

        {permissions[Rule.VldHandbookView] && <OtherContentMenu />}
      </div>

      <div className="flex items-center space-x-4">
        <SearchBar
          className="w-96 border-none bg-white/10 text-white/80"
          {...{ searchCategories }}
          onSearchSuccess={({ query }) =>
            analytics.trackEvent('search global', { screen_name: screenName, search_query: query })
          }
          onResultClick={({ query, item, type }) =>
            analytics.trackEvent('click search result', {
              screen_name: screenName,
              search_query: query,
              search_click_text: item,
              search_type: 'global',
              app_search_category: convertSearchType(type)
            })
          }
        />

        {!authenticated && !loading && (
          <Button appearance="scarlet" size="px-6 h-10" onClick={login}>
            <FormattedMessage id={translations.misc.signIn} />
          </Button>
        )}

        <ProfileMenu />
      </div>
    </header>
  );
};
