import { Buffer } from 'buffer';

import { HttpDataSource, HttpDataSourceOptions } from './http-data-source';

export interface EncryptedHttpDataSourceOptions extends HttpDataSourceOptions {
  key: string;
  iv: string;
}

export class EncryptedHttpDataSource extends HttpDataSource<EncryptedHttpDataSourceOptions> {
  private blockSize = 16;

  async fetchChunk(startOffset: number, endOffset: number): Promise<ArrayBuffer> {
    const [encrypted, key, iv] = await Promise.all([
      super.fetchChunk(this.getChunkOffset(startOffset), endOffset),
      this.hashSHA256(Buffer.from(this.options.key)).then((sha) => Buffer.from(sha.slice(0, 16))),
      this.hashSHA256(Buffer.from(this.options.iv)).then((sha) => Buffer.from(sha.slice(0, 16)))
    ]);

    const decrypted = await this.decryptAESCTR(encrypted, key, this.incrementIV(iv, this.getIVOffset(startOffset)));

    return decrypted.slice(this.getOffsetToCorrectDecryptBuffer(startOffset));
  }

  protected async decryptAESCTR(buffer: ArrayBuffer, key: Buffer, counter: Buffer): Promise<ArrayBuffer> {
    const algorithm = 'AES-CTR';

    if (!crypto?.subtle) throw new Error(`Your browser doesn't support subtle crypto.`);

    const cryptoKey = await crypto.subtle.importKey('raw', key, algorithm, true, ['decrypt']);

    return crypto.subtle.decrypt({ name: algorithm, counter, length: 64 }, cryptoKey, buffer);
  }

  protected async hashSHA256(buffer: ArrayBuffer): Promise<ArrayBuffer> {
    return await crypto.subtle.digest('SHA-256', buffer);
  }

  protected getChunkOffset(offset: number) {
    return Math.floor(offset / this.blockSize) * this.blockSize;
  }

  protected getIVOffset(offset: number) {
    return Math.floor(offset / this.blockSize);
  }

  protected getOffsetToCorrectDecryptBuffer(offset: number) {
    return offset % this.blockSize;
  }

  /**
   * Adds number to a big number stored in a buffer.
   * For example:
   * iv        = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 4] +
   * increment = 400
   * iv        = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 148]
   */
  protected incrementIV(iv: Buffer, increment: number) {
    if (iv.length !== 16) throw new Error('Only implemented for 16 bytes IV.');

    const MAX_UINT32 = 0xffffffff;
    const incrementBig = ~~(increment / MAX_UINT32);
    const incrementLittle = (increment % MAX_UINT32) - incrementBig;

    // split the 128bits IV in 4 numbers, 32bits each
    let overflow = 0;
    for (let idx = 0; idx < 4; ++idx) {
      let num = iv.readUInt32BE(12 - idx * 4);

      let inc = overflow;
      if (idx == 0) inc += incrementLittle;
      if (idx == 1) inc += incrementBig;

      num += inc;

      const numBig = ~~(num / MAX_UINT32);
      const numLittle = (num % MAX_UINT32) - numBig;
      overflow = numBig;

      iv.writeUInt32BE(numLittle, 12 - idx * 4);
    }

    return iv;
  }
}
