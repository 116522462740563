import React from 'react';
import classnames from 'classnames';

import { Input, Props as InputProps } from '@/components/Input';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<InputProps, 'is'> {
  invalid?: boolean;
  label?: string;
}

export const TextAreaInput: React.FC<Props> = ({ className, invalid, label, ...rest }) => (
  <div className={classnames('relative inline-flex w-full flex-col', { 'text-gray-dark': rest.disabled }, className)}>
    {label && (
      <div className="text-xs">
        <label htmlFor={rest.id} className={classnames('inline-block pb-2 text-gray-dark uppercase')}>
          {label}
        </label>
      </div>
    )}

    <Input
      is="textarea"
      className={classnames(
        'resize-none relative box-border px-4 py-2 border border-solid border-gray-600 rounded text-sm focus:border-yellow transition',
        {
          'border-gray-darker': !invalid,
          'border-red': invalid,
          'text-gray-dark': rest.disabled
        },
        className
      )}
      {...rest}
    />
  </div>
);

export const TextAreaInputWithValidation = withValidation(TextAreaInput);
