import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { SENTRY_DSN, ENVIRONMENT, RELEASE } from '@/config';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: RELEASE,
  integrations: [new BrowserTracing()],
  normalizeDepth: 10,
  autoSessionTracking: true,
  tracesSampleRate: 1.0
});
