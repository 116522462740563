import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import classnames from 'classnames';

import { urls } from '@/constants';
import { translations } from '@/locales';

import { TenantContainer } from '@/containers/TenantContainer';

export const OtherContentDropdownMenu = () => {
  const { tenant } = TenantContainer.useContainer();

  return (
    <li>
      <Menu.Item
        as={NavLink}
        to={[urls.otherContent.root, urls.otherContent.vld.root].join('')}
        // @ts-ignore
        className={({ isActive }) =>
          classnames('inline-block px-4 py-3 w-full', { 'bg-gray-100': isActive, 'hover:bg-gray-100': !isActive })
        }
      >
        <FormattedMessage id={translations.application.header.otherContent.vld[tenant].menuItem} />
      </Menu.Item>
    </li>
  );
};
