import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { Footnote } from '@/domains/footnote';

import { Icon } from '@/components/Icon';
import { InstanceProps } from '@/components/Modal';

export interface Props extends InstanceProps<[]> {
  footnote: Footnote;
}

export const FootnoteModal: React.FC<Props> = ({ footnote, onClose }) => {
  return (
    <div className="text-gray-800 w-[45rem]">
      <div className="flex w-full justify-between items-center">
        <div className="text-question font-bold">
          <FormattedMessage id={translations.modals.footnoteModal.title} />
        </div>

        <button
          onClick={onClose}
          className="inline-flex justify-center items-center p-0 hover:text-scarlet transition z-30"
        >
          <Icon type="close" className="text-[1.5rem]" />
        </button>
      </div>

      <div dangerouslySetInnerHTML={{ __html: footnote?.content }} className="mt-6" />
    </div>
  );
};
