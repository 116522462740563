import useSWR from 'swr';

import { getSupplementLessonsModules } from '@/services/api';

import { TenantContainer } from '@/containers/TenantContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { SessionContainer } from '@/containers/SessionContainer';

export const useLoadSupplementModules = () => {
  const { tenant } = TenantContainer.useContainer();
  const { language } = LanguageContainer.useContainer();
  const { preferences } = SessionContainer.useContainer();

  return useSWR(
    { key: `getSupplementLessonsModules`, language, tenant, contentLanguage: preferences.contentLanguage },
    ({ key: contentLanguage, ...o }) => getSupplementLessonsModules(o),
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  );
};
