import React from 'react';
import ReactSlider from 'react-slider';
import classnames from 'classnames';

interface Props {
  value: number;
  onChange(value: number): void;
}

const convertFromRangeToRange = (fromMin: number, fromMax: number, toMin: number, toMax: number) => {
  return (value: number) => ((value - fromMin) / (fromMax - fromMin)) * (toMax - toMin) + toMin;
};

// it will convert a number between 0.5-3 to a number between 0-10
const format = convertFromRangeToRange(0.5, 3, 0, 10);

// it will convert a number between 0-10 to a number between 0.5-3 (inverted)
const parse = convertFromRangeToRange(0, 10, 0.5, 3);

export const ReaderFontScaleSlider: React.FC<Props> = ({ value, onChange }) => {
  const formattedValue = React.useMemo(() => format(value), [value]);
  const onAfterChange = React.useCallback((value: number) => onChange(parse(value)), [onChange]);

  return (
    <ReactSlider
      {...{ onAfterChange }}
      value={formattedValue}
      min={0}
      max={10}
      marks={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
      className="w-full h-8 cursor-pointer"
      renderThumb={(props) => (
        // the thumb should be 0px wide, otherwise the last mark space would be longer
        <div {...props} className="w-0 h-3 top-1/2">
          <div className="w-3 h-3 -translate-x-1/2 -translate-y-1/2 rounded-full bg-gray-800" />
        </div>
      )}
      renderTrack={(props, { index }) => (
        <div {...props} className="flex items-center h-8">
          <div
            className={classnames('w-full h-1', {
              'bg-gray-800 opacity-40': index == 0,
              'bg-gray-200': index > 0
            })}
          />
        </div>
      )}
      renderMark={(props) => (
        <span {...props} className="flex items-center h-full">
          <span className="w-[1px] h-1 bg-gray-800" />
        </span>
      )}
    />
  );
};
