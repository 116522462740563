import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Bookmark } from '@/domains';
import { translations } from '@/locales';

import { Icon } from '@/components/Icon';

export interface Props {
  bookmark: Bookmark;
  createdAt?: boolean;
  className?: string;
  onClick(): void;
  onDelete(): void;
}

export const BookmarkListItem: React.FC<Props> = ({ bookmark, createdAt = true, className, onClick, onDelete }) => {
  const intl = useIntl();

  return (
    <li className={classnames('text-gray-600 text-title flex', { 'hover:cursor-pointer': !!onClick }, className)}>
      <div className="flex flex-1 items-center space-x-2" {...{ onClick }}>
        <span className="flex-1">{bookmark.chapterName ?? bookmark.content}</span>

        {!!createdAt && (
          <span className="text-gray text-right">
            <FormattedMessage
              id={translations.misc.savedDate}
              values={{ date: intl.formatDate(bookmark.createdAt, { month: 'long', day: 'numeric', year: 'numeric' }) }}
            />
          </span>
        )}
      </div>

      <div className="p-1 w-1/12 flex items-center justify-end">
        <button
          onClick={onDelete}
          className="flex items-center text-red text-[1.5rem] transition hover:cursor-pointer hover:text-red-600"
        >
          <Icon type="bin" />
        </button>
      </div>
    </li>
  );
};
