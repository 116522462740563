import React from 'react';
import classnames from 'classnames';
import { Menu } from '@headlessui/react';

interface Props {
  active?: boolean;
}

export const HeaderMenuButton: React.FC<Props> = ({ active, children, ...props }) => (
  <Menu.Button as="div">
    {({ open }) => (
      <button
        {...props}
        className={classnames('flex items-center px-3 h-10 rounded', {
          ['text-white']: active,
          ['bg-white/10']: open || active,
          ['text-white/80 hover:bg-white/10 hover:text-white']: !open && !active
        })}
      >
        {children}
      </button>
    )}
  </Menu.Button>
);
