import React from 'react';
import classnames from 'classnames';
import { Menu, Transition } from '@headlessui/react';

export interface HeaderMenuDropdownProps {
  className?: string;
}

export const HeaderMenuDropdown: React.FC<HeaderMenuDropdownProps> = ({ className, children, ...props }) => (
  <Transition
    enter="transition ease-out duration-100"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-75"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
  >
    <Menu.Items
      {...props}
      as="ul"
      className={classnames('bg-white rounded overflow-hidden shadow text-gray-900 absolute z-header', className)}
    >
      {children}
    </Menu.Items>
  </Transition>
);
