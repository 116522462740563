import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover, Transition } from '@headlessui/react';

import { Icon } from '@/components/Icon';
import { Reader } from '@/components/Reader/containers/ReaderContainer';
import { ReaderFontScaleSlider } from '@/components/Reader/ReaderHeader/ReaderFontScaleSlider';

import { ReaderHeaderButton } from './ReaderHeaderButton';
import { translations } from '@/locales';

const text = translations.pages.reader.header;

export interface Props {}

export const FontSizeButton: React.FC<Props> = () => {
  const { scale, loading } = Reader.useContainer();

  return (
    <Popover className="relative inline-block">
      {({ open }) => (
        <React.Fragment>
          <ReaderHeaderButton is={Popover.Button} active={open} disabled={loading}>
            <Icon type="font-size" />
          </ReaderHeaderButton>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm mt-3 transform right-0">
              <div className="flex items-center w-full px-4 py-6 space-x-4 overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="text-[0.875rem] text-gray-600">
                  <FormattedMessage id={text.textSize} />
                </div>

                <div className="flex flex-1 items-center space-x-3">
                  <Icon type="font-size-scale" className="text-[0.75rem]" />

                  <ReaderFontScaleSlider value={scale.value} onChange={scale.update} />

                  <Icon type="font-size-scale" className="text-[1.25rem]" />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </React.Fragment>
      )}
    </Popover>
  );
};
