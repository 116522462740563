import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, Outlet } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locales';

import { TabButton } from '@/components/TabButton';

const text = translations.pages.forMe.annotations;

const tabs = [
  {
    title: text.tabs.bookmarks,
    url: [urls.annotations.root, urls.annotations.bookmarks].join('')
  },
  {
    title: text.tabs.highlights,
    url: [urls.annotations.root, urls.annotations.highlights].join('')
  }
];

export const AnnotationPage: React.FC = () => (
  <main className="py-10 px-20">
    <h1 className="text-headline-1 font-semibold text-gray-800 mb-10">
      <FormattedMessage id={text.title} />
    </h1>

    <div className="border-b border-solid border-gray-200 mb-6">
      <div className="flex -mx-5">
        {tabs.map(({ title, url }) => (
          <NavLink key={title} to={url} className="relative">
            {({ isActive }) => (
              <TabButton is="div" selected={isActive} className="text-title mx-5">
                <FormattedMessage id={title} />
              </TabButton>
            )}
          </NavLink>
        ))}
      </div>
    </div>

    <Outlet />
  </main>
);
