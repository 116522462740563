import React from 'react';
import {
  FieldProps as ReactFinalFieldProps,
  FieldRenderProps as ReactFinalFieldRenderProps,
  FieldInputProps as ReactFinalFieldInputProps,
  useField
} from 'react-final-form';

export type FieldProps<FieldValue, T extends HTMLElement = HTMLElement> = ReactFinalFieldInputProps<FieldValue, T> & {
  invalid: boolean;
  error: any;
};

type Common<A, B> = { [K in keyof A & keyof B]: A[K] };

export type FormFieldProps<
  AsProps,
  FieldValue,
  RP extends ReactFinalFieldRenderProps<FieldValue, T>,
  T extends HTMLElement = HTMLElement
> = ReactFinalFieldProps<FieldValue, RP, T> & {
  as: React.ComponentType<AsProps>;
} & Omit<AsProps, keyof ReactFinalFieldProps<FieldValue, RP, T>> &
  Partial<Common<AsProps, ReactFinalFieldProps<FieldValue, RP, T>>> & { invalid?: boolean };

export const FormField = <
  AsProps,
  FieldValue,
  RP extends ReactFinalFieldRenderProps<FieldValue, T>,
  T extends HTMLElement = HTMLElement
>(
  props: React.PropsWithChildren<FormFieldProps<AsProps, FieldValue, RP, T>>
): React.ReactElement<FieldProps<FieldValue, T> & AsProps> => {
  const {
    afterSubmit,
    allowNull,
    as: Component,
    beforeSubmit,
    component,
    data,
    defaultValue,
    format,
    formatOnBlur,
    initialValue,
    isEqual,
    multiple,
    name,
    parse,
    ref,
    render,
    subscription,
    type,
    validate,
    validateFields,
    value,
    invalid: outerInvalid,
    ...rest
  } = props;

  // component, render & children are excluded from here.
  const { input, meta } = useField<FieldValue, T>(name, {
    afterSubmit,
    allowNull,
    beforeSubmit,
    data,
    defaultValue,
    format,
    formatOnBlur,
    initialValue,
    isEqual,
    multiple,
    parse,
    subscription,
    type,
    validate,
    validateFields,
    value
  });

  const invalid = meta.submitError ? !!meta.submitError && !meta.dirtySinceLastSubmit : meta.invalid;
  const errorVisible = !meta.active && meta.touched && invalid;
  const errorMessage = errorVisible ? meta.error || meta.submitError : null;

  //@ts-ignore
  return <Component {...input} {...rest} error={errorMessage} invalid={errorVisible || outerInvalid} />;
};
