import React from 'react';
import ReactSlider from 'react-slider';
import classnames from 'classnames';

interface Props {
  current: number;
  total: number;
  className?: string;
  disabled?: boolean;
  onChange(value: number): void;
}

export const ReaderProgressBar: React.FC<Props> = ({
  current: value,
  total: max,
  className,
  disabled,
  onChange: onAfterChange
}) => (
  <ReactSlider
    {...{ value, max, disabled, onAfterChange }}
    min={0}
    className={classnames('w-full', { 'cursor-pointer': !disabled }, className)}
    renderThumb={(props) => (
      // the thumb should be 0px wide, otherwise the last mark space would be longer
      <div {...props} className="w-0 h-3 top-1/2">
        <div
          className={classnames('w-5 h-5 -translate-x-1/2 -translate-y-1/2 rounded-full', {
            'bg-gray-200': disabled,
            'bg-scarlet hover:text-scarlet-700': !disabled
          })}
        />
      </div>
    )}
    renderTrack={(props, { index }) => (
      <div {...props} className="flex items-center h-8">
        <div
          className={classnames('w-full h-1', {
            'bg-scarlet opacity-50': index == 0,
            'bg-gray-200': index > 0
          })}
        />
      </div>
    )}
  />
);
