import React from 'react';
import classnames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type Appearance = 'gray' | 'white' | 'scarlet';

export interface Props extends NavLinkProps {
  appearance?: Appearance;
  className?: string;
  external?: boolean;
  disabled?: boolean;
}

const style: Record<Appearance, string> = {
  gray: 'text-gray hover:text-gray-200',
  white: 'text-white xs:underline',
  scarlet: 'text-scarlet hover:opacity-80'
};

const disabledStyle = 'text-gray cursor-default hover:opacity-100';

export const Link = React.forwardRef<HTMLAnchorElement, Props>(
  ({ appearance, className, external, children, disabled, ...rest }, ref) => {
    const root = 'no-underline ' + (disabled ? disabledStyle : 'cursor-pointer');

    className = classnames(root, !!appearance && style[appearance], className, disabled && disabledStyle);

    if (disabled) return <span {...{ ref, className }}>{children}</span>;

    return external ? (
      <a {...{ ref, className }} target="_blank" rel="noopener noreferrer" href={rest.to.toString()}>
        {children}
      </a>
    ) : (
      <NavLink {...{ ref, className }} {...rest}>
        {children}
      </NavLink>
    );
  }
);
