import {
  IReaderView,
  IReaderViewAnnotation,
  NavigationCollectionType
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

import { AnnotationLayer, IAnnotationLayer } from '../services/reader-service/annotation-layer/annotation-layer';

export interface BasicBookmarkAnnotationData {
  id: string;
  location: string;
  content: string;
  chapterName: string;
}

export interface AddBookmarkOptions<T extends BasicBookmarkAnnotationData> extends Pick<T, 'id'> {}
export interface DeleteBookmarkOptions<T extends BasicBookmarkAnnotationData> extends Pick<T, 'location'> {}

export interface IBookmarkAnnotationLayer<T extends BasicBookmarkAnnotationData> extends IAnnotationLayer {
  addBookmark(options: AddBookmarkOptions<T>): Promise<IReaderViewAnnotation<T> | undefined>;
  deleteBookmark(options: DeleteBookmarkOptions<T>): IReaderViewAnnotation<T> | undefined;
}

export class BookmarkAnnotationLayer<T extends BasicBookmarkAnnotationData>
  extends AnnotationLayer<T>
  implements IBookmarkAnnotationLayer<T>
{
  private readonly epubCfiToAnnotationMap: Map<string, IReaderViewAnnotation<T>> = new Map();

  constructor(name: string, readonly readerView: IReaderView) {
    super(name, readerView);

    this.annotationLayer.setLayerOptions({
      layerClassName: 'bookmarks-layer',
      annotationInputEngineEventsEnabled: true
    });
    this.annotationLayer.setDefaultAnnotationOptions({
      positionStyle: {
        width: '100%',
        height: '100%'
      },
      containerClassName: 'bookmark-container',
      containerStyle: {
        left: '0',
        width: '32px',
        height: '32px'
      }
    });
  }

  public async addBookmark(options: AddBookmarkOptions<T>): Promise<IReaderViewAnnotation<T> | undefined> {
    const location = this.readerView.getReadingPosition();

    if (!location) return;

    const fetchResult = await location.fetchNavigationItemReferences({
      collectionTypes: Object.values(NavigationCollectionType),
      greedy: true
    });

    const itemsInRange = fetchResult.getItemsInRange();

    const content = itemsInRange?.length > 0 ? itemsInRange[0].getNavigationItem().getTextContent() : undefined;

    if (!content) return;

    const locator = location.getLocator();
    const epubcfi = locator.getSelectors()[0]?.toString();

    const annotation = this.annotationLayer.createAnnotation<T>(locator, {
      id: options.id,
      location: epubcfi,
      content,
      chapterName: content
    } as T);
    this.epubCfiToAnnotationMap.set(epubcfi, annotation);

    return annotation;
  }

  public deleteBookmark({ location }: DeleteBookmarkOptions<T>): IReaderViewAnnotation<T> | undefined {
    const annotation = this.epubCfiToAnnotationMap.get(location);

    if (!annotation) return;

    this.annotationLayer.destroyAnnotation(annotation);

    this.epubCfiToAnnotationMap.delete(location);

    return annotation;
  }
}
