import React from 'react';

import { LessonAccessState, LessonType } from '@/domains';
import { LanguageFeature } from '@/services/remote-config';

import { SessionContainer } from '@/containers/SessionContainer';

import { Rule } from './rules';
import { useIsFeatureEnabled } from './useIsFeatureEnabled';

const VLD_HANDBOOK_ALLOWED_STUDENT_TYPES = ['SRF Kriyaban', 'YSS Kriyaban'];

export const usePermissions = () => {
  const { me, authenticated } = SessionContainer.useContainer();

  const kriyaEnabled = useIsFeatureEnabled(LanguageFeature.KriyaLessons);
  const supplementEnabled = useIsFeatureEnabled(LanguageFeature.SupplementLessons);

  const permissions = React.useMemo(
    () => ({
      [Rule.LessonsKriyaView]:
        !!me?.lessonsAccess.find(
          ({ type, access }) => type === LessonType.Kriya && access === LessonAccessState.Accessible
        ) && kriyaEnabled,
      [Rule.VldHandbookView]: authenticated && VLD_HANDBOOK_ALLOWED_STUDENT_TYPES.includes(me?.studentType ?? ''),
      [Rule.ForMeView]: authenticated,
      [Rule.LessonsSupplementView]: supplementEnabled
    }),
    [authenticated, me?.lessonsAccess, me?.studentType, kriyaEnabled, supplementEnabled]
  );

  return { permissions };
};
