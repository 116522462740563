import React from 'react';
import { useUpdateEffect } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate, useParams, Outlet } from 'react-router';

import { translations } from '@/locales';
import { analytics, convertLessonType, convertLessonTypeToEnrollment } from '@/services/analytics';
import { ContentLanguage, LessonAccessState, LessonType } from '@/domains';
import { languageToEnrollmentUrlMap, urls } from '@/constants';

import { useTrackPageTime } from '@/hooks/useTrackPageTime';

import { Icon } from '@/components/Icon';
import { Image } from '@/components/Image';
import { PageTitle } from '@/components/PageTitle';
import { TabButton } from '@/components/TabButton';
import { SessionContainer } from '@/containers/SessionContainer';
import { ActivityItem } from '@/components/ActivityItem';
import { FormattedLessonPages } from '@/components/FormattedLessonPages';
import { Loading, LoadingIndicator, LoadingCenter } from '@/components/Loading';
import { LanguageContainer } from '@/containers/LanguageContainer';

import { useLoadLesson } from '../hooks/useLoadLesson';
import { useLessonDisabled } from '../hooks/useLessonDisabled';
import { Button } from '@/components/Button';

const text = translations.pages.lessons.details;

export const LessonDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { authenticated, me, preferences, updatePreferences } = SessionContainer.useContainer();
  const { language } = LanguageContainer.useContainer();

  const { moduleId, lessonId } = useParams<{ moduleId?: string; lessonId: string }>();
  const { data: lesson, isValidating: loading } = useLoadLesson(lessonId!);

  const disabled = useLessonDisabled(lesson);

  useUpdateEffect(() => {
    if (!lesson) return;

    navigate('..', { replace: true });
  }, [preferences.contentLanguage]);

  useUpdateEffect(() => {
    if (authenticated && preferences.contentLanguage !== 'auto') return;

    navigate('..', { replace: true });
  }, [language]);

  useTrackPageTime(
    (duration) => {
      if (!lesson?.id) return;

      analytics.trackEvent('view lesson landing', {
        title: lesson.title,
        lesson_number: lesson.displayedNumber,
        lesson_type: convertLessonType(lesson.type),
        modules: lesson.supplementModules.map(({ title }) => title),
        consumption_duration: Math.round(duration / 1000)
      });
    },
    [lesson?.id]
  );

  const enrollRedirectUrl = me?.lessonsLanguage
    ? lesson?.type === LessonType.Supplement
      ? languageToEnrollmentUrlMap[me.lessonsLanguage]?.supplementLesson
      : languageToEnrollmentUrlMap[me.lessonsLanguage]?.basicLesson
    : undefined;

  const shouldDisplayEnrollMessage =
    authenticated && enrollRedirectUrl && lesson?.type === LessonType.Supplement
      ? me?.lessonsAccess.some(({ type, access }) => type === lesson?.type && access === LessonAccessState.CanEnroll)
      : me?.lessonsAccess.some(
          ({ type, access }) => type === lesson?.type && access === LessonAccessState.NotAccessible
        );

  const accessibleLanguages = React.useMemo(
    () => me?.lessonsAccess.find(({ type }) => type === lesson?.type)?.accessibleLanguages,
    [me, lesson]
  );

  const setUserLessonsLanguage = React.useCallback(
    (contentLanguage: ContentLanguage) => updatePreferences({ contentLanguage }),
    [updatePreferences]
  );

  return (
    <div className="relative py-8 px-[5.125rem] min-h-full">
      {!!lesson && <PageTitle title={lesson.title} />}

      <Loading visible={loading && !lesson}>
        <LoadingCenter>
          <LoadingIndicator />
        </LoadingCenter>
      </Loading>

      {!!lesson && (
        <React.Fragment>
          {!!shouldDisplayEnrollMessage && (
            <div className="p-8 mb-10 bg-scarlet-50 rounded flex flex-col items-center justify-between gap-6">
              <p>
                <FormattedMessage id={text.enroll} />
              </p>

              <div className="flex justify-between gap-6 w-full">
                <Button
                  className="-my-2 -mr-2 flex-grow"
                  appearance="scarlet"
                  onClick={() => {
                    analytics.trackEvent('click enroll button', { type: convertLessonTypeToEnrollment(lesson.type) });

                    window.location.href = enrollRedirectUrl as string;
                  }}
                >
                  <span className="-mb-[2px]">
                    <FormattedMessage id={translations.misc.enroll} />
                  </span>

                  <Icon type="arrow-right" className="text-[16px]" />
                </Button>

                {!!accessibleLanguages?.length && (
                  <Button
                    appearance="none"
                    className="-my-2 -mr-2 justify-center items-center flex-grow border-2 hover:bg-scarlet-100 text-scarlet border-scarlet"
                    onClick={() => setUserLessonsLanguage(accessibleLanguages[0])}
                  >
                    <span className="-mb-[2px]">
                      <FormattedMessage
                        id={translations.pages.lessons.details.switchToLanguageSubscription}
                        values={{
                          language: <FormattedMessage id={translations.i18n.languages[accessibleLanguages[0]]} />
                        }}
                      />
                    </span>

                    <Icon type="arrow-right" className="text-[16px]" />
                  </Button>
                )}
              </div>
            </div>
          )}

          <div className="mb-6 after:clear-both after:table">
            <div className="float-left">
              <Image url={lesson.imageUrl} className="w-64 min-w-[16rem] h-[328px] mr-8 mb-6 rounded-md bg-cover" />
            </div>

            <div>
              <p className="uppercase text-gray-600 text-note mb-2">
                <FormattedMessage id={translations.misc.lesson} values={{ number: lesson.displayedNumber }} />
              </p>

              <h1 className="text-headline-1 font-semibold mb-3">{lesson.title}</h1>

              {lesson.type === LessonType.PubliclyAvailable ? (
                <p className="text-gray-600">
                  <FormattedMessage id={translations.misc.pages} values={{ number: lesson.totalNumberOfPages }} />
                </p>
              ) : (
                <p className="text-gray-600">
                  <FormattedLessonPages {...{ lesson }} />
                </p>
              )}

              <div className="flex mb-4 mt-6 space-x-4">
                <ActivityItem
                  is={Link}
                  disabled={!lesson.epubUrl || disabled}
                  to={`${urls.getLessonUrl({ moduleId, lessonType: lesson.type, lessonId: lesson.id })}/reader`}
                  state={{ selector: lesson.readingLocationEpubCfi }}
                  className="-ml-3"
                >
                  <Icon type="book" className="text-[1.5rem]" />
                  <span className="-mb-[2px]">
                    <FormattedMessage id={translations.misc.read} />
                  </span>
                </ActivityItem>

                {!!lesson.audioBook?.url && !disabled && (
                  <ActivityItem
                    is={Link}
                    to={`${urls.getLessonUrl({ moduleId, lessonType: lesson.type, lessonId: lesson.id })}/reader`}
                    state={{ selector: lesson.readingLocationEpubCfi, listen: true }}
                  >
                    <Icon type="headphones" className="text-[1.5rem]" />
                    <span className="-mb-[2px]">
                      <FormattedMessage id={translations.misc.listen} />
                    </span>
                  </ActivityItem>
                )}
              </div>
            </div>
          </div>

          <section>
            <ul className="relative flex w-full space-x-8 mb-6 border-b border-solid border-gray-200 text-title">
              <NavLink
                end
                to={urls.getLessonUrl({ moduleId, lessonType: lesson.type, lessonId: lesson.id })}
                className="relative"
              >
                {({ isActive }) => (
                  <TabButton is="div" selected={isActive}>
                    <FormattedMessage id={text.toc.title} />
                  </TabButton>
                )}
              </NavLink>

              {lesson.auxiliaryMaterials.length > 0 && (
                <NavLink
                  to={`${urls.getLessonUrl({ moduleId, lessonType: lesson.type, lessonId: lesson.id })}/materials`}
                  className="relative"
                >
                  {({ isActive }) => (
                    <TabButton is="div" selected={isActive}>
                      <FormattedMessage id={text.auxiliary.title} />
                    </TabButton>
                  )}
                </NavLink>
              )}
            </ul>

            <Outlet context={lesson} />
          </section>
        </React.Fragment>
      )}

      {!loading && !lesson && (
        <div className="absolute inset-0 flex items-center justify-center text-title font-semibold">
          <p>
            <FormattedMessage id={text.empty} values={{ br: <br /> }} />
          </p>
        </div>
      )}
    </div>
  );
};
