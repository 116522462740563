import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { Language, languageToSettingsUrlMap } from '@/constants';

import { Link } from '@/components/Link';
import { Icon } from '@/components/Icon';

interface Props {
  language: Language;
}

export const FAQ: React.FC<Props> = ({ language }) => (
  <div className="w-full px-6 pt-6 pb-2">
    <Link
      external
      to={languageToSettingsUrlMap[language].generalFaq}
      className="bg-scarlet-50 w-full h-12 rounded flex justify-between text-scarlet items-center px-4"
    >
      <span>
        <FormattedMessage id={translations.application.search.faq} />
      </span>

      <Icon type="chevron-right" className="text-[24px]" />
    </Link>
  </div>
);
