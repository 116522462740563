import React from 'react';
import { Menu } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';

import { Icon } from '@/components/Icon';
import { Modal, useModal } from '@/components/Modal';
import { SupportTicketModal } from '@/components/Modals/SupportTicketModal';

import { HeaderMenuButton, HeaderMenuDropdown } from '../HeaderMenu';
import { ProfileDropdownMenu } from './SettingsDropdownMenu';
import { SessionContainer } from '@/containers/SessionContainer';

export const ProfileMenu = () => {
  const { authenticated, me } = SessionContainer.useContainer();
  const modal = useModal(SupportTicketModal);

  const name = [me?.firstName, me?.lastName].filter(Boolean).join(' ');
  const email = me?.emailAddress;

  return (
    <React.Fragment>
      <Menu as="div" className="relative font-semibold">
        <HeaderMenuButton>
          {authenticated ? (
            <React.Fragment>
              <div className="mr-3 text-right text-[0.875rem] -space-y-1">
                {!!name && <div className="font-semibold text-white">{name}</div>}
                {!!email && <div>{email}</div>}
                {!name && !email && (
                  <div className="space-y-1">
                    <div className="w-14 h-3 ml-auto bg-white/10 rounded" />
                    <div className="w-32 h-3 ml-auto bg-white/10 rounded" />
                  </div>
                )}
              </div>

              <Icon type="profile" className="text-question font-semibold" />
            </React.Fragment>
          ) : (
            <MenuIcon className="w-6 h-6" />
          )}
        </HeaderMenuButton>

        <HeaderMenuDropdown className="right-0 top-1 w-72">
          <ProfileDropdownMenu onSupport={() => modal.open(() => null)} />
        </HeaderMenuDropdown>
      </Menu>

      <Modal {...modal.props} />
    </React.Fragment>
  );
};
