import React from 'react';

interface Props {
  text: string;
  term: string;
}

export const BoldedHighlight: React.FC<Props> = ({ text, term }) => (
  <span>
    {text.split(new RegExp(term, 'gi')).reduce(
      (content, substring, index, array) => (
        <React.Fragment>
          {content}
          {substring}
          {index < array.length - 1 && <b className="font-bold">{term}</b>}
        </React.Fragment>
      ),
      <React.Fragment />
    )}
  </span>
);
