import React from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

interface Props {
  id?: string;
  title?: string;
}

export const PageTitle: React.FC<Props> = ({ id, title }) => {
  const { formatMessage } = useIntl();

  return (
    <Helmet>
      {!!id && <title>{formatMessage({ id })}</title>}
      {!id && !!title && <title>{title}</title>}
    </Helmet>
  );
};
