import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { GlossaryResult } from '@/domains';

interface Props {
  data: GlossaryResult[];
}

export const GlossaryResults: React.FC<Props> = ({ data }) => (
  <div className="flex flex-col mb-6">
    <div className="text-title mb-2 text-scarlet">
      <FormattedMessage id={translations.modals.learnMoreModal.fromGlossary} />
    </div>

    {data.map((item, index) => (
      <React.Fragment key={index}>
        <div className="font-semibold">{item.content}</div>

        {item.definitions.map((definition, index) => (
          <div key={index} className="mb-3">
            {definition}
          </div>
        ))}
      </React.Fragment>
    ))}
  </div>
);
