import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { groupByLesson } from '@/services';
import { HighlightColor } from '@/domains';
import { deleteHighlight, getHighlights, getLessonById } from '@/services/api';

import { PageTitle } from '@/components/PageTitle';
import { Loading, LoadingOverlay, LoadingCenter, LoadingIndicator } from '@/components/Loading';
import { LessonGroup } from '@/components/LessonGroup';
import { EmptyPlaceholder } from '@/components/EmptyPlaceholder';
import { HighlightListItem } from '@/components/HighlightListItem';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { HighlightColorSelect } from '@/components/Select/HighlightColorSelect';
import { LessonFilterSimpleSelect } from '@/components/Select/LessonFilterSelect';
import { Modal, useModal } from '@/components/Modal';
import { ConfirmationModal } from '@/components/Modals/ConfirmationModal';

const text = translations.pages.forMe.annotations.highlights;

export const HighlightsPage: React.FC = () => {
  const navigate = useNavigate();

  const { language } = LanguageContainer.useContainer();
  const [selectedLessonId, setSelectedLessonId] = React.useState<string | undefined>();
  const [color, setColor] = React.useState<HighlightColor>();
  const {
    data: items = [],
    isValidating: loading,
    mutate: reload
  } = useSWR({ key: `getHighlights` }, () => getHighlights(), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const lessonSelectItems = groupByLesson(items).map(({ lesson: { id, title, displayedNumber } }) => ({
    id,
    title,
    displayedNumber
  }));

  const highlights = React.useMemo(() => {
    const filteredByLessonAndColor = items
      .filter((item) => (!selectedLessonId ? true : item.lesson.id === selectedLessonId))
      .filter((item) => (!color ? true : item.colorCode === color));

    const groupedByLesson = groupByLesson(filteredByLessonAndColor ?? []);

    return groupedByLesson;
  }, [items, color, selectedLessonId]);

  const modal = useModal(ConfirmationModal);

  return (
    <React.Fragment>
      <PageTitle id={text.helmet} />

      {!!lessonSelectItems.length && (
        <div className="flex items-center justify-between">
          <LessonFilterSimpleSelect
            value={selectedLessonId}
            items={lessonSelectItems}
            onChange={setSelectedLessonId}
            className="w-1/4"
          />

          <div className="-mr-1">
            <HighlightColorSelect value={color} onChange={setColor} className="-m-1" />
          </div>
        </div>
      )}

      <ul className="relative min-h-[10rem]">
        <Loading visible={!highlights.length && loading}>
          <LoadingOverlay className="z-30" />

          <LoadingCenter className="z-30">
            <LoadingIndicator />
          </LoadingCenter>
        </Loading>

        {!loading && !highlights.length && (
          <EmptyPlaceholder className="mt-8">
            <FormattedMessage id={text.empty} />
          </EmptyPlaceholder>
        )}

        <Modal {...modal.props} />

        {highlights.map((group) => (
          <LessonGroup key={group.lesson.id} className="mt-8" {...{ group }}>
            {({ items }) => (
              <React.Fragment>
                {items.map((item) => (
                  <HighlightListItem
                    key={item.id}
                    highlight={item}
                    className="py-2.5"
                    onClick={() => {
                      getLessonById({ lessonId: item.lesson.id, language }).then((lesson) => {
                        return navigate(
                          urls.getLessonUrl({ lessonType: lesson.type, lessonId: lesson.id }) + '/reader',
                          urls.getReaderParams({ selector: item.location })
                        );
                      });
                    }}
                    onDelete={() => {
                      modal.open(() => deleteHighlight(item.id).then(() => reload()));
                    }}
                  />
                ))}
              </React.Fragment>
            )}
          </LessonGroup>
        ))}
      </ul>
    </React.Fragment>
  );
};
