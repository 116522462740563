import React from 'react';
import { Outlet } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { LessonType, SupplementLessonsView } from '@/domains';

import { SessionContainer } from '@/containers/SessionContainer';
import { PageTitle } from '@/components/PageTitle';

const text = translations.pages.lessons.supplement;

const views = [
  {
    title: text.tabs.all,
    to: urls.getLessonListUrl({ lessonType: LessonType.Supplement }),
    view: SupplementLessonsView.All
  },
  { title: text.tabs.modules, to: '/content/supplement/modules', view: SupplementLessonsView.Modules }
];

export const SupplementHeader = () => {
  const { updatePreferences } = SessionContainer.useContainer();

  return (
    <React.Fragment>
      <PageTitle id={text.title} />

      <div className="relative flex flex-col h-full">
        <div className="flex justify-between px-[5.25rem] py-6 border-b border-solid border-gray-200">
          <h1 className="text-headline-1 font-semibold">
            <FormattedMessage id={text.title} />
          </h1>

          <div className="flex border border-solid border-scarlet rounded overflow-hidden text-gray-600 text-2xl">
            {views.map(({ title, to, view }) => (
              <NavLink
                key={title}
                {...{ to }}
                className={({ isActive }) =>
                  classNames('transition flex items-center h-10 px-6 font-semibold', {
                    'bg-scarlet text-white': isActive,
                    'hover:bg-scarlet-50 hover:text-scarlet-700': !isActive
                  })
                }
                onClick={() => updatePreferences({ supplementLessonsView: view })}
              >
                <div className="-mb-[2px]">
                  <FormattedMessage id={title} />
                </div>
              </NavLink>
            ))}
          </div>
        </div>

        <div className="flex-grow overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
};
