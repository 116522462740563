export class EpubCfi {
  /**
   * Transforms an epubcfi into a lexicographically comparable string
   * @param cfi A valid epubcfi
   * @returns A lexicograpchically comparable string coresponding to the epubcfi
   */
  public static toLexicographicallyComparableString(cfi: string) {
    const match = cfi.match(/^epubcfi\(\/(.+)\)$/);

    if (!match || match.length < 1) return;

    return (
      match[1]
        // Replace root slash
        .replace(/^\//, '')
        // Convert to unescaped form
        .replace(/\^./g, '')
        // Remove bracketed assertions
        .replace(/\[(.*?)\]+/g, '')
        // Remove indirections and commas (for ranges)
        .replace(/!|\^|,/g, '')
        // Convert to 5 digit number (0 padded)
        .replace(/[0-9]+/g, (match) => match.padStart(5, '0'))
    );
  }

  public static compare(oneCfi: string, otherCfi: string) {
    const one = EpubCfi.toLexicographicallyComparableString(oneCfi);
    const other = EpubCfi.toLexicographicallyComparableString(otherCfi);

    if (!one || !other) throw new Error('Invalid Epub CFI argument');

    return one.localeCompare(other);
  }
}
