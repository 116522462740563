import React from 'react';

import { Reader } from '@/components/Reader/containers/ReaderContainer';

import { useForwardEvents } from './useForwardEvents';

export const ReaderForwardIframeEvents: React.FC = () => {
  const { readerService } = Reader.useContainer();
  const ref = useForwardEvents(readerService);

  return <div {...{ ref }} className="hidden absolute -top-16 -left-16" />;
};
