import { FlashcardCreate, Lesson } from '@/domains';
import { CreateFlashcardOptions } from '@/services/api';

export const toCreateFlashcard = ({
  values,
  lesson: { id, title, displayedNumber, lessonClaimNumber, type, language, tenant }
}: {
  values: FlashcardCreate;
  lesson: Lesson;
}): CreateFlashcardOptions => {
  return {
    ...values,
    lesson: {
      id,
      title,
      displayedNumber,
      lessonClaimNumber,
      type,
      language,
      tenant
    }
  };
};
