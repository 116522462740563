import React from 'react';
import { Dialog } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { useOpen } from '@/hooks/useOpen';

import { ConfirmationModal } from '@/components/Modals/ConfirmationModal';
import { HighlightAnnotations } from '@/components/Reader/HighlightAnnotations';
import { Modal, useModal } from '@/components/Modal';
import { HighlightColorSelect } from '@/components/Select/HighlightColorSelect';

import { ActionButton } from './ActionButton';
import { LearnMoreModal } from './LearnMoreModal';
import { CreateFlashcardModal, Props as CreateFlashcardModalProps } from './CreateFlashcardModal';

export interface Props extends Pick<CreateFlashcardModalProps, 'onFlashcardCreate'> {}

const text = translations.modals.readerHighlightsDialog;

export const ReaderHighlightsDialog: React.FC<Props> = ({ onFlashcardCreate }) => {
  const { selection, setSelection, upsert, remove } = HighlightAnnotations.useContainer();
  const { isOpen, open, close } = useOpen();

  const learnMoreModal = useModal(LearnMoreModal);
  const createFlashCardModal = useModal(CreateFlashcardModal);
  const highlightDeleteModal = useModal(ConfirmationModal);

  React.useEffect(() => {
    if (selection) return open();
    return close();
  }, [selection]);

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        className="absolute inset-1/2 z-30 w-[200px]"
        style={{ top: selection?.position.y, left: selection?.position.x }}
        onClose={close}
      >
        <div className="flex flex-col w-full items-center rounded-lg shadow-lg bg-white px-4">
          <div className="flex p-4">
            <HighlightColorSelect
              value={selection?.color}
              onChange={(color) => {
                if (!selection) return;

                if (!color)
                  return highlightDeleteModal.open(() => remove({ location: selection.contentLocation }).then(close));

                setSelection((selection) => ({ ...selection!, color }));

                return upsert({
                  colorCode: color,
                  content: selection.text,
                  contentLocation: selection.contentLocation
                });
              }}
            />
          </div>

          <ActionButton
            icon="lessons"
            onClick={() => {
              close();
              learnMoreModal.open(() => null);
            }}
          >
            <FormattedMessage id={text.learnMore} />
          </ActionButton>

          <ActionButton
            icon="plus"
            onClick={() => {
              close();
              createFlashCardModal.open(() => null);
            }}
          >
            <FormattedMessage id={text.createFlashcard} />
          </ActionButton>
        </div>
      </Dialog>

      {!!selection && <Modal {...learnMoreModal.props} content={selection.text} />}

      {!!selection && <Modal {...createFlashCardModal.props} {...{ onFlashcardCreate }} content={selection.text} />}

      <Modal {...highlightDeleteModal.props} />
    </React.Fragment>
  );
};
