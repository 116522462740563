import React from 'react';
import classnames from 'classnames';

import { Tenant } from '@/domains';

import srfLogoBlue from '@/assets/srf-logo-blue.png';
import yssLogoBlue from '@/assets/yss-logo-blue.png';

import srfLogoWhite from '@/assets/srf-logo.png';
import yssLogoWhite from '@/assets/yss-logo.png';

export const tenantToLogoMap: Record<Tenant, { blue: string; white: string }> = {
  [Tenant.SRF]: {
    blue: srfLogoBlue,
    white: srfLogoWhite
  },
  [Tenant.YSS]: {
    blue: yssLogoBlue,
    white: yssLogoWhite
  }
};

export interface Props {
  tenant: Tenant;
  type: 'blue' | 'white';
  className?: string;
}

export const Logo: React.FC<Props> = ({ className, tenant, type }) => (
  <img className={classnames('w-10 h-10 min-w-[2.5rem] mr-6', className)} src={tenantToLogoMap[tenant][type]} />
);
