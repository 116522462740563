import React from 'react';
import classnames from 'classnames';

import { Icon } from '@/components/Icon';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const CloseButton: React.FC<Props> = ({ className, ...rest }) => (
  <button
    className={classnames('inline-flex justify-center items-center p-0 transition hover:text-scarlet-700', className)}
    {...rest}
  >
    <Icon type="close" className="text-[1.5rem]" />
  </button>
);
