import React from 'react';
import classnames from 'classnames';

import { Is, IsComponent, Props as IsProps } from './Is';

type Props<T extends IsComponent> = IsProps<T> & {
  disabled?: boolean;
};

export const ActivityItem = <T extends IsComponent>({ className, ...props }: Props<T>) => (
  <Is
    {...props}
    className={classnames(
      'flex items-center py-2 px-3 space-x-2 font-semibold rounded text-title',
      {
        'text-scarlet hover:text-scarlet-700 hover:bg-scarlet-700/10': !props.disabled,
        'text-gray cursor-default pointer-events-none': props.disabled
      },
      className
    )}
  />
);
