import axios from 'axios';

import { MediaType, IRandomAccessDataSource } from '@colibrio/colibrio-reader-framework/colibrio-core-io-base';

export interface HttpDataSourceOptions {
  url: string;
  size: number;
  type: MediaType;
}

export class HttpDataSource<O extends HttpDataSourceOptions> implements IRandomAccessDataSource {
  constructor(protected options: O) {}

  static async getOptionsFromUrl(url: string): Promise<HttpDataSourceOptions> {
    const response = await axios.head(url);

    const size = parseInt(response.headers['content-length']);
    const type = response.headers['content-type'] as MediaType;

    return { url, size, type };
  }

  getType(): MediaType {
    return this.options.type;
  }

  getSize(): number {
    return this.options.size;
  }

  async fetchChunk(startOffset: number, endOffset: number): Promise<ArrayBuffer> {
    return await axios
      .get<ArrayBuffer>(this.options.url, {
        headers: { range: `bytes=${startOffset}-${endOffset}` },
        responseType: 'arraybuffer'
      })
      .then(({ data }) => data);
  }
}
