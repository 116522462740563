import React from 'react';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { SessionContainer } from '@/containers/SessionContainer';
import { TenantContainer } from '@/containers/TenantContainer';
import { LanguageFeature } from '@/services/remote-config';

export const useIsFeatureEnabled = (feature: LanguageFeature) => {
  const { me } = SessionContainer.useContainer();

  const { config, loading } = TenantContainer.useContainer();

  const { language: appLanguage } = LanguageContainer.useContainer();

  const language = React.useMemo(() => {
    return me?.lessonsLanguage ?? appLanguage;
  }, [appLanguage, me?.lessonsLanguage]);

  if (loading) return false;

  return config.enabled_lang_based_features[feature].includes(language);
};
