import React from 'react';

import { Icon } from '@/components/Icon';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const BackToViewButton: React.FC<Props> = ({ children, ...rest }) => (
  <button className="flex items-center py-3 px-4 w-full hover:cursor-pointer text-scarlet hover:bg-gray-100" {...rest}>
    <Icon type="arrow-left" className="text-[16px] mr-3" />

    <span className="text-[0.8em] -mb-[2px] tracking-wide uppercase font-semibold">{children}</span>
  </button>
);
