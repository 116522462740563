import React from 'react';
import classnames from 'classnames';

interface Props {
  url: string;
  className?: string;
}

export const Image: React.FC<Props> = ({ url, className }) => (
  <div style={{ backgroundImage: `url('${url}')` }} className={classnames('bg-center bg-no-repeat', className)} />
);
