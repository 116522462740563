import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { FlashcardColor, FlashcardCreate, FLASHCARD_COLORS } from '@/domains';
import { STRING_LONG_MAX_LENGTH } from '@/constants';

import { Form, FormField } from '@/components/Form';
import { InstanceProps } from '@/components/Modal';
import { Flashcard, Orientation } from '@/components/Flashcard';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { FlashcardColorSelect } from '@/components/Select/FlashcardColorSelect';
import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { SubmitError } from '@/components/Error';
import { CloseButton } from '@/components/CloseButton';

export interface Props extends InstanceProps<[]> {
  content: string;
  onFlashcardCreate: (values: FlashcardCreate) => Promise<unknown>;
}

const schema = yup
  .object({
    color: yup.mixed<FlashcardColor>().required().default(FLASHCARD_COLORS[0]).label(translations.inputs.color.label),
    content: yup.string().required().default(''),
    title: yup.string().required().default('').max(STRING_LONG_MAX_LENGTH).label(translations.inputs.title.label)
  })
  .required();

const text = translations.modals.createFlashcard;

export const CreateFlashcardModal: React.FC<Props> = ({ content, onFlashcardCreate, onClose }) => {
  const intl = useIntl();

  return (
    <div className="w-1/2 min-w-[600px] text-gray-800">
      <h6 className="mb-2">
        <div className="mt-1 text-headline-2 font-semibold">
          <FormattedMessage id={text.title} />
        </div>

        <CloseButton onClick={onClose} className="absolute top-7 right-7" />
      </h6>

      <p className="text-gray-600 mb-6">
        <FormattedMessage id={text.subtitle} />
      </p>

      <Form
        id="create-flashcard"
        schema={schema}
        initialValues={{ content, color: FLASHCARD_COLORS[0] }}
        subscription={{
          submitError: true,
          submitting: true,
          valid: true,
          dirty: true,
          values: true
        }}
        onSubmit={(values) => onFlashcardCreate(values).then(onClose)}
      >
        {({ id, submitError, submitting, values }) => (
          <div>
            <div className="flex">
              <Flashcard
                title={values.title}
                content={values.content}
                color={values.color}
                orientation={Orientation.BackwardFacing}
              />

              <div className="flex-grow flex flex-col justify-between ml-4">
                <div>
                  <div className="mb-6">
                    <FormField
                      as={TextInputWithValidation}
                      id={`${id}-title`}
                      name="title"
                      label={intl.formatMessage({ id: translations.inputs.title.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.title.placeholder })}
                    />
                  </div>

                  <Label htmlFor={`${id}-color`}>
                    <FormattedMessage id={translations.inputs.color.label} />
                  </Label>

                  <FormField as={FlashcardColorSelect} id={`${id}-color`} name="color" className="mb-6" />
                </div>

                <Button form={id} appearance="scarlet" type="submit" className="w-full" loading={submitting}>
                  <FormattedMessage id={translations.misc.save} />
                </Button>
              </div>
            </div>

            <SubmitError error={submitError} className="mt-3" />
          </div>
        )}
      </Form>
    </div>
  );
};
