import { IReaderView, IReaderViewAnnotation } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

import { Highlight, HIGHLIGHT_COLORS } from '@/domains';

import { getLocatorFromEpubCfi, getPublicationUrl } from '@/components/Reader/services/reader-service/utils';

import { AnnotationLayer, IAnnotationLayer } from '../services/reader-service/annotation-layer/annotation-layer';

export type TemporaryHighlight = Pick<Highlight, 'colorCode' | 'content' | 'location' | 'chapterName'>;

export type HighlightAnnotationData = Highlight | TemporaryHighlight;

export interface BasicHighlightAnnotationData {
  location: string;
  colorCode: string;
}

export type AddHighlightOptions<T extends BasicHighlightAnnotationData> = T;

export type UpdateHighlightOptions<T extends BasicHighlightAnnotationData> = T;

export interface DeleteHighlightOptions<T extends BasicHighlightAnnotationData> extends Pick<T, 'location'> {}

export interface IHighlightAnnotationLayer<T extends BasicHighlightAnnotationData> extends IAnnotationLayer {
  addHighlight: (options: AddHighlightOptions<T>) => IReaderViewAnnotation<T> | undefined;
  upsertHighlight: (options: UpdateHighlightOptions<T>) => IReaderViewAnnotation<T> | undefined;
  deleteHighlight: (options: DeleteHighlightOptions<T>) => IReaderViewAnnotation<T> | undefined;
}

export class HighlightAnnotationLayer<T extends BasicHighlightAnnotationData>
  extends AnnotationLayer<T>
  implements IHighlightAnnotationLayer<T>
{
  private readonly epubCfiToAnnotationMap: Map<string, IReaderViewAnnotation<T>> = new Map();

  constructor(name: string, readonly readerView: IReaderView) {
    super(name, readerView);

    this.annotationLayer.setLayerOptions({
      annotationInputEngineEventsEnabled: true,
      layerStyle: { 'mix-blend-mode': 'multiply' }
    });
    this.annotationLayer.setDefaultAnnotationOptions({
      rangeClassName: 'highlights-layer',
      rangeStyle: { 'background-color': HIGHLIGHT_COLORS[0], cursor: 'pointer' }
    });
  }

  public addHighlight(options: AddHighlightOptions<T>): IReaderViewAnnotation<T> | undefined {
    const locator = getLocatorFromEpubCfi(getPublicationUrl(this.readerView), options.location);
    if (!locator) return;

    const annotation = this.annotationLayer.createAnnotation<T>(locator);

    annotation.setOptions({
      rangeStyle: {
        'background-color': options.colorCode
      }
    });
    annotation.setCustomData(options);

    this.epubCfiToAnnotationMap.set(options.location, annotation);

    return annotation;
  }

  public upsertHighlight(highlight: UpdateHighlightOptions<T>): IReaderViewAnnotation<T> | undefined {
    const locator = getLocatorFromEpubCfi(getPublicationUrl(this.readerView), highlight.location);
    if (!locator) return;

    let annotation = this.epubCfiToAnnotationMap.get(highlight.location);

    if (!annotation) {
      annotation = this.annotationLayer.createAnnotation<T>(locator);
    }

    annotation.setOptions({
      rangeStyle: {
        'background-color': highlight.colorCode
      }
    });
    annotation.setCustomData({ ...annotation.getCustomData(), ...highlight });

    this.epubCfiToAnnotationMap.set(highlight.location, annotation);

    return annotation;
  }

  public deleteHighlight({ location }: DeleteHighlightOptions<T>): IReaderViewAnnotation<T> | undefined {
    const annotation = this.epubCfiToAnnotationMap.get(location);

    if (!annotation) return;

    this.annotationLayer.destroyAnnotation(annotation);

    this.epubCfiToAnnotationMap.delete(location);

    return annotation;
  }
}
