import React from 'react';

import { IReaderService } from '@/components/Reader/services';

/**
 * This hook subscribes to events in colibrio's iframe and forwards them to parent document.
 * Is needed because events in the iframe do not trigger Dialog close when clicking outside or pressing Escape key
 * @param readerService
 */
export const useForwardEvents = (readerService: IReaderService) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    return readerService.subscribeToEvent('pointerdown', () => {
      if (!ref.current) return;

      const event = new MouseEvent('mousedown');
      Object.defineProperty(event, 'target', { writable: false, value: ref.current });

      window.dispatchEvent(event);
    });
  });

  React.useEffect(() => {
    return readerService.subscribeToEvent('keydown', (event) => {
      switch (event.key) {
        case 'Esc':
        case 'Escape':
          window.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
          break;
      }
    });
  }, []);

  return ref;
};
