import {
  ISyncMediaEngineEvent,
  ISyncMediaPlayer
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

import { ReaderProgress } from './reader-progress';

export interface IMediaService {
  get isPlaying(): boolean;
  play(): void;
  pause(): void;
  seekNextFragment(): void;
  seekPreviousFragment(): void;
  subscribeToStateChange(callback: (isPlaying: boolean) => void): () => void;
  subscribeToProgressChange(callback: (progress: ReaderProgress) => void): () => void;
  destroy(): void;
}

export class MediaService implements IMediaService {
  constructor(private mediaPlayer: ISyncMediaPlayer, private onDestroy: () => void) {}

  public get isPlaying() {
    return this.mediaPlayer.isPlaying();
  }

  public play() {
    this.mediaPlayer.play();
  }

  public pause() {
    this.mediaPlayer.pause();
  }

  public seekNextFragment() {
    this.mediaPlayer.seekToNextSegment();
  }

  public seekPreviousFragment() {
    this.mediaPlayer.seekToPreviousSegment();
  }

  public subscribeToProgressChange(callback: (progress: ReaderProgress) => void): () => void {
    const handler = ({ approximateElapsedTimeMs, timelinePosition }: ISyncMediaEngineEvent) => {
      callback({ current: approximateElapsedTimeMs, total: timelinePosition.getTimeline().getApproximateDurationMs() });
    };

    this.mediaPlayer.addEngineEventListener('syncMediaTimelinePositionChanged', handler);

    return () => this.mediaPlayer.removeEngineEventListener('syncMediaTimelinePositionChanged', handler);
  }

  public subscribeToStateChange(callback: (isPlaying: boolean) => void): () => void {
    const handlePlay = () => callback(true);
    const handlePause = () => callback(false);

    this.mediaPlayer.addEngineEventListener('syncMediaPlay', handlePlay);
    this.mediaPlayer.addEngineEventListener('syncMediaPaused', handlePause);

    return () => {
      this.mediaPlayer.removeEngineEventListener('syncMediaPlay', handlePlay);
      this.mediaPlayer.removeEngineEventListener('syncMediaPaused', handlePause);
    };
  }

  public destroy() {
    this.onDestroy();
  }
}
