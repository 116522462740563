import React from 'react';

class Timer {
  private startedAt?: number = undefined;
  private timeSpent = 0;

  constructor(start: boolean) {
    if (start) this.start();
  }

  private get timeSinceStarted() {
    if (!this.startedAt) return 0;
    return Date.now() - this.startedAt;
  }

  start() {
    if (this.startedAt) return;
    this.startedAt = Date.now();
  }

  pause() {
    this.timeSpent += this.timeSinceStarted;
    this.startedAt = undefined;
  }

  get() {
    return this.timeSpent + this.timeSinceStarted;
  }
}

export const useTimeSpent = (start?: boolean) => {
  const [timer, setTimer] = React.useState(new Timer(!!start));

  return React.useMemo(
    () => ({
      timer,
      reset: () => setTimer(new Timer(!!start))
    }),
    [timer]
  );
};
