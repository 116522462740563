import React from 'react';

import { HighlightListItem } from '@/components/HighlightListItem';
import { Reader } from '@/components/Reader/containers/ReaderContainer';
import { HighlightAnnotations } from '@/components/Reader/HighlightAnnotations';
import { Modal, useModal } from '@/components/Modal';
import { ConfirmationModal } from '@/components/Modals/ConfirmationModal';

export const HighlightsTab: React.FC = () => {
  const { readerService } = Reader.useContainer();
  const highlights = HighlightAnnotations.useContainer();

  const modal = useModal(ConfirmationModal);

  return (
    <React.Fragment>
      <Modal {...modal.props} />

      <ul>
        {highlights.list?.map((item) => (
          <HighlightListItem
            key={item.id}
            highlight={item}
            className="py-2.5"
            onClick={() => readerService.goToSelector(item.location)}
            onDelete={() => modal.open(() => highlights.remove(item))}
          />
        ))}
      </ul>
    </React.Fragment>
  );
};
