import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { createSupportTicket } from '@/services/api/support';
import { STRING_LONG_MAX_LENGTH, STRING_SUPPORT_MAX_LENGTH } from '@/constants';

import { TenantContainer } from '@/containers/TenantContainer';
import { SessionContainer } from '@/containers/SessionContainer';

import { Icon } from '@/components/Icon';
import { Form, FormField } from '@/components/Form';
import { Button } from '@/components/Button';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { TextAreaInputWithValidation } from '@/components/Input/TextAreaInput';
import { CloseButton } from '@/components/CloseButton';

export interface Props extends InstanceProps<[]> {}

const schema = yup
  .object({
    subject: yup.string().max(STRING_LONG_MAX_LENGTH).label(translations.modals.supportTicket.subject).required(),
    message: yup.string().max(STRING_SUPPORT_MAX_LENGTH).label(translations.modals.supportTicket.message).required(),
    email: yup.string().email().label(translations.modals.supportTicket.email).required(),
    firstName: yup.string().label(translations.modals.supportTicket.firstName).required(),
    lastName: yup.string().label(translations.modals.supportTicket.lastName).required()
  })
  .required();

export const SupportTicketModal: React.FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { tenant } = TenantContainer.useContainer();
  const { me } = SessionContainer.useContainer();

  return (
    <Form
      schema={schema}
      initialValues={{
        email: me?.emailAddress ?? '',
        firstName: me?.firstName ?? '',
        lastName: me?.lastName ?? ''
      }}
      onSubmit={(values) => createSupportTicket({ ...values, tenant })}
    >
      {({ submitError, submitting, values, submitSucceeded }) => (
        <div className="flex flex-col w-[40rem]">
          <CloseButton type="button" onClick={onClose} className="absolute top-6 right-6" />

          {!submitSucceeded ? (
            <React.Fragment>
              <div className="text-question font-semibold mb-3 mt-4 text-gray-800">
                <FormattedMessage id={translations.modals.supportTicket.title} />
              </div>

              <div className="mb-6 text-gray-800">
                <FormattedMessage id={translations.modals.supportTicket.subtitle} />
              </div>

              <div className="flex space-x-6 w-full mb-4">
                {!me?.firstName && (
                  <div className="w-full">
                    <FormField
                      as={TextInputWithValidation}
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder={intl.formatMessage({ id: translations.modals.supportTicket.firstName })}
                    />
                  </div>
                )}

                {!me?.lastName && (
                  <div className="w-full">
                    <FormField
                      as={TextInputWithValidation}
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder={intl.formatMessage({ id: translations.modals.supportTicket.lastName })}
                    />
                  </div>
                )}
              </div>

              {!me?.emailAddress && (
                <div className="w-full mb-4">
                  <FormField
                    as={TextInputWithValidation}
                    id="email"
                    name="email"
                    type="email"
                    placeholder={intl.formatMessage({ id: translations.modals.supportTicket.email })}
                    className=""
                  />
                </div>
              )}
              <div className="w-full mb-4">
                <FormField
                  as={TextInputWithValidation}
                  id="subject"
                  name="subject"
                  type="text"
                  placeholder={intl.formatMessage({ id: translations.modals.supportTicket.subject })}
                />
              </div>

              <div className="h-[140px]">
                <FormField
                  as={TextAreaInputWithValidation}
                  id="message"
                  name="message"
                  type="text"
                  className="h-[140px]"
                  placeholder={intl.formatMessage({ id: translations.modals.supportTicket.message })}
                />
              </div>

              <div className="ml-auto text-note mt-3 text-gray-600">
                <FormattedMessage
                  id={translations.modals.supportTicket.characterLimit}
                  values={{
                    count: values.message
                      ? STRING_SUPPORT_MAX_LENGTH - values.message.length
                      : STRING_SUPPORT_MAX_LENGTH
                  }}
                />
              </div>

              {submitError ? <SubmitError error={submitError} /> : <div className="h-8" />}

              <Button
                type="submit"
                appearance="scarlet"
                className="font-medium w-[322px] mx-auto"
                disabled={submitting}
                loading={submitting}
              >
                <FormattedMessage id={translations.modals.supportTicket.send} />
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="rounded-full flex items-center justify-center bg-green-100 h-[60px] mt-5 w-[60px] border border-solid border-green-800 mx-auto">
                <Icon type="check" className="text-green-800 text-question" />
              </div>

              <div className="text-question font-semibold mb-3 mt-6 text-gray-800 mx-auto">
                <FormattedMessage id={translations.modals.supportTicket.sentTitle} />
              </div>

              <div className="mb-6 text-gray-800 mx-auto">
                <FormattedMessage id={translations.modals.supportTicket.sentSubtitle} />
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </Form>
  );
};
