import React from 'react';

import { useTimeSpent } from './useTimeSpent';

export const useTrackPageTime = (tracker: (duration: number) => unknown, deps: React.DependencyList) => {
  const { timer, reset } = useTimeSpent(true);

  React.useEffect(() => {
    return () => {
      tracker(timer.get());
      reset();
    };
  }, deps);

  return timer;
};
