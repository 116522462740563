import React from 'react';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';

import { translations } from '@/locales';
import { deleteFlashcard, getFlashcards } from '@/services/api';

import { PageTitle } from '@/components/PageTitle';
import { Loading, LoadingOverlay, LoadingCenter, LoadingIndicator } from '@/components/Loading';
import { EmptyPlaceholder } from '@/components/EmptyPlaceholder';
import { Flashcard } from '@/components/Flashcard';
import { Modal, useModal } from '@/components/Modal';
import { ConfirmationModal } from '@/components/Modals/ConfirmationModal';

const text = translations.pages.forMe.flashcards;

export const FlashcardsPage: React.FC = () => {
  const {
    data: items = [],
    isValidating: loading,
    mutate: reload
  } = useSWR({ key: `getFlashcards` }, () => getFlashcards(), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const modal = useModal(ConfirmationModal);

  return (
    <main className="relative py-10 px-20">
      <PageTitle id={text.helmet} />

      <h1 className="text-headline-1 font-semibold text-gray-800 mb-10">
        <FormattedMessage id={text.title} />
      </h1>

      <div className="relative min-h-[10rem]">
        <Loading visible={!items.length && loading}>
          <LoadingOverlay className="z-30" />

          <LoadingCenter className="z-30">
            <LoadingIndicator />
          </LoadingCenter>
        </Loading>

        {!loading && !items.length && (
          <EmptyPlaceholder className="mt-8">
            <FormattedMessage id={text.empty} />
          </EmptyPlaceholder>
        )}

        <Modal {...modal.props} />

        <div className="flex -m-4 flex-wrap">
          {items.map((flashcard) => (
            <Flashcard
              key={flashcard.id}
              className="m-4"
              onDelete={() => modal.open(() => deleteFlashcard(flashcard.id).then(() => reload()))}
              {...flashcard}
            />
          ))}
        </div>
      </div>
    </main>
  );
};
