import { AnnotationOrder, Content, Highlight } from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';
import { orderAnnotations } from '@/services/annotation';

export interface GetHighlightsOptions {
  order?: AnnotationOrder;
  lessonId?: string;
}

export interface UpsertHighlightOptions extends Omit<Highlight, 'sortingLocation'> {}

export const getHighlights = async ({ lessonId, order }: GetHighlightsOptions = {}) => {
  if (!lessonId) return authorizedApi.get<Content<Highlight>>(`/v1/highlights`).then((r) => r.content);

  return authorizedApi
    .get<Content<Highlight>>(`/v1/highlights`, { lessonId })
    .then((r) => orderAnnotations(r.content, order));
};

export const upsertHighlight = async ({ id, ...options }: UpsertHighlightOptions) =>
  authorizedApi.put<Highlight>(`/v1/highlights/${id}`, options);

export const deleteHighlight = (id: string) => authorizedApi.delete<{ id: string }>(`/v1/highlights/${id}`);
