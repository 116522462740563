import React, { HtmlHTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import { LessonType, MaterialType } from '@/domains';
import { translations } from '@/locales';
import { Icon } from '@/components/Icon';
import energization1 from '@/assets/energization1.png';
import energization2 from '@/assets/energization2.png';
import energization3 from '@/assets/energization3.png';
import { useLoadLessons } from '@/pages/ContentPage/hooks/useLoadLessons';

import { Link } from '../Link';
import { Loading } from '../Loading';

const images = [energization1, energization2, energization3];

export const EnergizationShortcut: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = ({ ...rest }) => {
  const { data: lessons = [], isValidating: areLessonsLoading } = useLoadLessons({
    type: LessonType.Basic,
    materialType: MaterialType.Default
  });

  const lessonId = React.useMemo(() => lessons.find((l) => l.lessonClaimNumber === 6.2)?.id, [lessons]);

  if (areLessonsLoading) return <Loading visible />;

  return (
    <div className="w-full px-6 pt-4 pb-2" {...rest}>
      <Link to={`/content/basic/lessons/${lessonId}/materials`}>
        <div className="bg-yellow-500 bg-opacity-[8%] rounded flex flex-col justify-between items-center p-4">
          <div className="flex justify-between">
            {images.map((image, index) => (
              <img key={index} src={image} className="flex-1 max-w-[30%]" />
            ))}
          </div>

          <div className="flex justify-between w-full mt-6 items-center">
            <span className="font-semibold">
              <FormattedMessage id={translations.application.search.energization} />
            </span>

            <Icon type="chevron-right" className="text-[24px]" />
          </div>
        </div>
      </Link>
    </div>
  );
};
