import React from 'react';
import classnames from 'classnames';

interface LessonAsideProps {
  className?: string;
}

export const LessonAside: React.FC<LessonAsideProps> = ({ className, ...props }) => (
  <aside className="w-1/3 h-full overflow-x-hidden overflow-y-auto border-r border-solid border-gray-200">
    <LessonAsideContent
      {...props}
      className={classnames('flex flex-nowrap transition-transform duration-500', className)}
    />
  </aside>
);

interface LessonAsideContentProps {
  className?: string;
}

export const LessonAsideContent: React.FC<LessonAsideContentProps> = ({ className, ...props }) => (
  <div {...props} className={classnames('relative flex-shrink-0 w-full h-full', className)} />
);

interface LessonAsideSectionProps {
  className?: string;
}

export const LessonAsideSection: React.FC<LessonAsideSectionProps> = ({ className, ...props }) => (
  <LessonAsideContent {...props} className={classnames('overflow-x-hidden overflow-y-auto', className)} />
);
