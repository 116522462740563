import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { authorizedApi } from '@/services/network/authorized-api';

import { Button } from '@/components/Button';

import regularError from '@/assets/regular-error.svg';

const text = translations.application.error;

export const ForceUpdate: React.FC = ({ children }) => {
  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    authorizedApi.configureBaseOptions({
      onUnsupportedVersionError: () => setError(true)
    });
  }, []);

  if (!error) return <React.Fragment>{children}</React.Fragment>;

  return (
    <React.Fragment>
      <div className="h-full w-full flex relative">
        <div className="pt-16 z-10 max-w-2xl lg:max-w-3xl mx-auto">
          <div className="text-headline-1 mb-6">
            <FormattedMessage id={text.title} values={{ br: <br /> }} />
          </div>

          <div className="mb-10 text-title">
            <FormattedMessage id={text.message} />
          </div>

          <Button onClick={() => location.reload()} appearance="scarlet">
            <FormattedMessage id={text.button} />
          </Button>
        </div>

        <img className="h-full absolute top-20 right-0 bottom-0" src={regularError} />
      </div>
    </React.Fragment>
  );
};
