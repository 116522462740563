import { FirebaseApp } from 'firebase/app';
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
  RemoteConfig as FirebaseRemoteConfig
} from 'firebase/remote-config';

import { Language } from '@/constants';
import { Tenant } from '@/domains';
import { ENVIRONMENT } from '@/config';

import { firebaseApp } from './firebase';

export enum RemoteConfigKey {
  LessonLanguages = 'active_lesson_languages',
  LanguageFeatures = 'enabled_lang_based_features',
  AppLanguages = 'active_app_languages'
}

export enum LanguageFeature {
  TodaysInpiration = 'todays_inspiration_screen',
  NewContent = 'new_content',
  LiveEvents = 'live_events',
  BasicLessons = 'basic_lessons',
  KriyaLessons = 'kriya_lessons',
  SupplementLessons = 'supplement_lessons',
  OnlineMeditation = 'online_meditation',
  GuidedMeditation = 'guided_meditation'
}

interface TenantRemoteConfig<V> extends Record<Lowercase<Tenant>, V> {}

interface RemoteConfigValues {
  [RemoteConfigKey.LessonLanguages]: TenantRemoteConfig<Language[]>;
  [RemoteConfigKey.LanguageFeatures]: Record<LanguageFeature, TenantRemoteConfig<Language[]>>;
  [RemoteConfigKey.AppLanguages]: TenantRemoteConfig<Language[]>;
}

export const getTenantFeatures = (
  tenant: Lowercase<Tenant>,
  data: Record<LanguageFeature, TenantRemoteConfig<Language[]>>
) =>
  Object.values(LanguageFeature).reduce(
    (prev, current) => ({ ...prev, [current]: data[current][tenant] }),
    {}
  ) as Record<LanguageFeature, Language[]>;

interface RemoteConfigOptions {
  app: FirebaseApp;
  getKey(key: string): string;
}

class RemoteConfig {
  private instance: Promise<FirebaseRemoteConfig>;

  constructor(private options: RemoteConfigOptions) {
    this.instance = new Promise((resolve, reject) => {
      const rm = getRemoteConfig(options.app);

      const disabledConfig: TenantRemoteConfig<Language[]> = { srf: [], yss: [] };

      rm.settings.minimumFetchIntervalMillis = 12 * 60 * 60 * 1000;
      rm.defaultConfig = {
        [RemoteConfigKey.LessonLanguages]: JSON.stringify(
          disabledConfig as RemoteConfigValues[RemoteConfigKey.LessonLanguages]
        ),
        [RemoteConfigKey.LanguageFeatures]: JSON.stringify({
          [LanguageFeature.TodaysInpiration]: disabledConfig,
          [LanguageFeature.NewContent]: disabledConfig,
          [LanguageFeature.LiveEvents]: disabledConfig,
          [LanguageFeature.BasicLessons]: disabledConfig,
          [LanguageFeature.KriyaLessons]: disabledConfig,
          [LanguageFeature.SupplementLessons]: disabledConfig,
          [LanguageFeature.OnlineMeditation]: disabledConfig,
          [LanguageFeature.GuidedMeditation]: disabledConfig
        } as RemoteConfigValues[RemoteConfigKey.LanguageFeatures]),
        [RemoteConfigKey.AppLanguages]: JSON.stringify(
          disabledConfig as RemoteConfigValues[RemoteConfigKey.AppLanguages]
        )
      } as Record<RemoteConfigKey, string>;

      return fetchAndActivate(rm)
        .then(() => resolve(rm))
        .catch(reject);
    });
  }

  async getValue<K extends RemoteConfigKey>(key: K): Promise<RemoteConfigValues[K]> {
    const remoteConfig = await this.instance;

    return JSON.parse(getValue(remoteConfig, this.options.getKey(key)).asString());
  }
}

const getKey = (key: RemoteConfigKey) => {
  if (ENVIRONMENT === 'prod') return key;

  return `${key}_staging`;
};

export const remoteConfig = new RemoteConfig({ app: firebaseApp, getKey });
