import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

// @ts-ignore
import FormattedDuration from 'react-intl-formatted-duration';

import { translations } from '@/locales';
import { isEpubAuxiliaryMaterial, isWeblinkAuxiliaryMaterial } from '@/utils';
import { AnyAuxiliaryMaterialItem, AuxiliaryMaterialType } from '@/domains';

import { Icon, Type } from '@/components/Icon';
import { Is, IsComponent, Props as IsProps } from '@/components/Is';

export type Props<T extends IsComponent> = IsProps<T> & {
  item: AnyAuxiliaryMaterialItem;
  as?: React.ElementType;
  disabled?: boolean;
  [x: string]: any;
};

const typeToIcon: Record<AuxiliaryMaterialType, Type> = {
  [AuxiliaryMaterialType.Weblink]: 'link',
  [AuxiliaryMaterialType.Audio]: 'headphones',
  [AuxiliaryMaterialType.Video]: 'video',
  [AuxiliaryMaterialType.Epub]: 'file'
};

export const AuxiliaryMaterial = <T extends IsComponent>({ item, className, ...rest }: Props<T>) => {
  return (
    <Is
      key={item.id}
      {...rest}
      className={classnames(
        'flex items-center group gap-3',
        { 'group-hover:cursor-pointer': !rest.disabled, 'text-gray': rest.disabled },
        className
      )}
    >
      <Icon
        type={typeToIcon[item.type]}
        className={classnames('text-scarlet text-[1.5rem]', { 'text-gray-600': rest.disabled })}
      />
      <p className={classnames('text-left', { 'group-hover:underline': !rest.disabled })}>{item.title}</p>
      <p className="text-gray-600">
        {isEpubAuxiliaryMaterial(item) ? (
          <FormattedMessage id={translations.misc.pages} values={{ number: item.numberOfPages }} />
        ) : isWeblinkAuxiliaryMaterial(item) ? (
          <FormattedMessage id={translations.misc.link} />
        ) : (
          <FormattedDuration seconds={item.durationInSeconds} format="{hours} {minutes}" unitDisplay="short" />
        )}
      </p>
    </Is>
  );
};
