import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { Flashcard as DomainFlashcard, FlashcardColor } from '@/domains';

import { Icon } from '@/components/Icon';

import { FlashcardFace } from './FlashcardFace';

export enum Orientation {
  ForwardFacing,
  BackwardFacing
}

export interface Props
  extends Pick<DomainFlashcard, 'title' | 'content' | 'color'>,
    Partial<Pick<DomainFlashcard, 'createdAt'>> {
  orientation?: Orientation;
  className?: string;
  onDelete?: () => void;
}

export const flashcardColorToGradient: Record<FlashcardColor, string> = {
  [FlashcardColor.Blue]: 'from-[#1465DF] to-[#042959]',
  [FlashcardColor.Orange]: 'from-[#FFA058] to-[#DC6A10]',
  [FlashcardColor.Red]: 'from-[#C20000] to-[#9C005D]',
  [FlashcardColor.Yellow]: 'from-[#D6A216] to-[#696C0B]'
};

export const Flashcard: React.FC<Props> = ({
  orientation: initialOrientation = Orientation.ForwardFacing,
  title,
  content,
  color,
  createdAt,
  onDelete,
  className
}) => {
  const intl = useIntl();

  const [orientation, setOrientation] = React.useState(initialOrientation);

  const onOrientationChange = () => {
    setOrientation((orientation) => {
      if (orientation === Orientation.ForwardFacing) return Orientation.BackwardFacing;

      return Orientation.ForwardFacing;
    });
  };

  return (
    <div
      className={classnames(
        'flex flex-col px-6 pt-6 pb-4 h-[19rem] w-[19rem] text-white rounded bg-gradient-to-br',
        flashcardColorToGradient[color],
        className
      )}
    >
      <div
        className={classnames(
          'mb-4 relative flex justify-center items-center flex-1 transition duration-1000 preserve-3d',
          { 'rotate-y-180': orientation === Orientation.BackwardFacing }
        )}
      >
        <FlashcardFace
          className={classnames('items-center duration-1000 transition-opacity opacity-100', {
            'opacity-[0]': orientation === Orientation.BackwardFacing
          })}
        >
          {title}
        </FlashcardFace>

        <FlashcardFace className="rotate-y-180">{content}</FlashcardFace>
      </div>

      <div className="flex justify-center mb-4">
        <button onClick={onOrientationChange}>
          <Icon type="shuffle" className="text-[1.5rem] hover:cursor-pointer hover:animate-pulse" />
        </button>
      </div>

      {(createdAt || onDelete) && (
        <div className="flex justify-between text-gray-200 text-note opacity-75">
          {createdAt && (
            <FormattedMessage
              id={translations.misc.savedDate}
              values={{ date: intl.formatDate(createdAt, { month: 'long', day: 'numeric', year: 'numeric' }) }}
            />
          )}

          <button onClick={onDelete}>
            <Icon
              type="bin"
              className="text-[1.5rem] transition hover:animate-pulse hover:cursor-pointer justify-end"
            />
          </button>
        </div>
      )}
    </div>
  );
};
