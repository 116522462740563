import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Annotation, GroupedByLesson } from '@/domains';
import { translations } from '@/locales';

interface ChildrenProps<T> {
  items: T[];
}

export interface Props<T extends Annotation> {
  group: GroupedByLesson<T>;
  children: (props: ChildrenProps<T>) => React.ReactElement;
  className?: string;
}

export const LessonGroup = <T extends Annotation>({
  group: { lesson, items },
  className,
  children
}: React.PropsWithChildren<Props<T>>) => (
  <li className={classnames('border-b border-solid pb-8 border-gray-200 last:border-b-0', className)}>
    <div className="text-gray-800 font-semibold pb-6 text-title">
      <FormattedMessage
        id={translations.misc.lessonNumberAndTitle}
        values={{ number: lesson.displayedNumber, title: lesson.title }}
      />
    </div>

    <ul>{children({ items })}</ul>
  </li>
);
