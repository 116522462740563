import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { Icon, Type as IconType } from '@/components/Icon';

interface Props {
  shape: Record<string, any>;
  separator?: IconType;
  className?: string;
}

export const Breadcrumb: React.FC<Props> = ({ className, separator = 'chevron-right', shape }) => (
  <div className={classnames('text-gray', className)}>
    {Object.entries(shape).map(([name, to], index) => (
      <React.Fragment key={index}>
        {index !== 0 && <Icon type={separator} className="text-[1.5rem] mx-4 text-gray-600" />}

        {to ? (
          <Link {...{ to }} className="hover:text-gray-800 last:text-gray-800">
            {name}
          </Link>
        ) : (
          <span className="cursor-default last:text-gray-800">{name}</span>
        )}
      </React.Fragment>
    ))}
  </div>
);
