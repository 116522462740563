import React from 'react';
import classnames from 'classnames';

import { HighlightColor, HIGHLIGHT_COLORS } from '@/domains';

import { ColoredDot } from '@/components/ColoredDot';

export interface Props {
  onChange: (value?: HighlightColor) => void;
  value?: HighlightColor;
  className?: string;
}

export const HighlightColorSelect: React.FC<Props> = ({ value, onChange, className }) => {
  return (
    <div className={classnames('flex', className)}>
      {HIGHLIGHT_COLORS.map((color) => (
        <ColoredDot
          key={color}
          className="h-9 w-9 m-1"
          selected={color === value}
          onClick={() => onChange(value !== color ? color : undefined)}
          {...{ color }}
        />
      ))}
    </div>
  );
};
