import React from 'react';
import classnames from 'classnames';
import { Is, IsComponent, Props as IsProps } from './Is';

export type Props<T extends IsComponent> = IsProps<T> & {
  selected: boolean;
};

export const TabButton = React.forwardRef(
  <T extends IsComponent>({ selected, className, children, ...rest }: Props<T>, ref: React.Ref<any>) => (
    <Is
      {...rest}
      {...{ ref }}
      className={classnames(
        'relative group font-semibold hover:text-scarlet-700',
        { 'text-scarlet': selected, 'text-gray-600': !selected },
        className
      )}
    >
      <span className="mb-1">{children}</span>

      <div
        className={classnames(
          'absolute inset-x-0 mx-auto w-full h-[2px] transition transform group-hover:scale-x-50 group-hover:bg-scarlet-700',
          { 'bg-scarlet': selected }
        )}
      />
    </Is>
  )
);
