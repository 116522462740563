import React from 'react';
import { Transition } from '@headlessui/react';

import { Reader } from '@/components/Reader/containers/ReaderContainer';

import { ReaderProgress } from '../services';
import { getEpubCfiFromLocator } from '../services/reader-service/utils';

import { ReaderAudioControls } from './ReaderAudioControls';
import { ReaderProgressBar } from './ReaderProgressBar';

export interface OnProgressChangeOptions {
  epubCfi: string;
  percent: number;
}

export interface Props {
  onProgressChange?: (options: OnProgressChangeOptions) => Promise<void>;
}

export const ReaderFooter: React.FC<Props> = ({ onProgressChange: onProgressChanged }) => {
  const { audiobook, loading, readerService } = Reader.useContainer();
  const [progress, setProgress] = React.useState<ReaderProgress>({ current: 0, total: 1 });

  const percent = React.useMemo(
    () => Math.round(Math.min(Math.max((progress.current / progress.total) * 100, 0), 100)),
    [progress]
  );

  const offset = 10000;
  const duration = 100000;

  React.useEffect(() => readerService.subscribeToProgressChange(setProgress), [readerService, percent]);

  React.useEffect(() => {
    const locator = readerService.view.getReadingPosition()?.getLocator();
    if (!locator) return;

    const epubCfi = getEpubCfiFromLocator(locator);
    if (!epubCfi) return;

    onProgressChanged?.({ epubCfi, percent });
  }, [percent]);

  const onProgressChange = React.useCallback(
    (current: number) => {
      // optimistic update so the progress bar can be updated instantly
      setProgress((progress) => ({ ...progress, current }));
      return readerService.goToPage(current).catch(() => null);
    },
    [readerService]
  );

  return (
    <div className="pt-4 pb-4 px-[5.25rem]">
      <ReaderProgressBar {...progress} className="h-8 -mt-4" disabled={loading} onChange={onProgressChange} />

      <div className="relative h-12">
        <Transition
          as="div"
          show={!audiobook.on}
          enter="transition ease-in duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="absolute inset-0 flex items-center w-full h-full"
        >
          <div className="w-full text-center text-[0.875rem] text-gray-800">{percent}%</div>
        </Transition>

        <Transition
          as="div"
          show={audiobook.on}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 translate-y-[150%]"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-[150%]"
          className="absolute inset-0 w-full"
        >
          <ReaderAudioControls {...{ percent, offset, duration }} className="w-full" />
        </Transition>
      </div>
    </div>
  );
};
