// if (!Intl.PluralRules) {
//   require('@formatjs/intl-pluralrules/polyfill');
//   require('@formatjs/intl-pluralrules/dist/locale-data/en');
//   require('@formatjs/intl-pluralrules/dist/locale-data/es');
//   require('@formatjs/intl-pluralrules/dist/locale-data/fr');
//   require('@formatjs/intl-pluralrules/dist/locale-data/nl');
// }

// if (!Intl.DateTimeFormat) {
//   require('@formatjs/intl-relativetimeformat/polyfill');
//   require('@formatjs/intl-datetimeformat/dist/locale-data/en');
//   require('@formatjs/intl-datetimeformat/dist/locale-data/es');
//   require('@formatjs/intl-datetimeformat/dist/locale-data/fr');
//   require('@formatjs/intl-datetimeformat/dist/locale-data/nl');
// }

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import '@/services/sentry';

import { analytics } from '@/services/analytics';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/config';

import { TenantContainer } from '@/containers/TenantContainer';
import { SessionContainer } from '@/containers/SessionContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { ErrorBoundary } from '@/components/ErrorBoundary';

import { App } from './App';

const root = createRoot(document.getElementById('app')!);

root.render(
  <TenantContainer.Provider>
    <LanguageContainer.Provider>
      <ErrorBoundary>
        <BrowserRouter>
          <SessionContainer.Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            audience={AUTH0_AUDIENCE}
            /**
             * Due to our current multi-tenant setup and that the user is not being updated when we
             * call `getAccessTokenSilently({ ignoreCache: true })` (https://github.com/auth0/auth0-react/issues/109)
             * we have to use memory cache instead of localStorage.
             */
            cacheLocation="memory"
            scope="read:all"
            useRefreshTokens
          >
            <App />
          </SessionContainer.Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </LanguageContainer.Provider>
  </TenantContainer.Provider>
);

const now = new Date();

analytics.trackEvent('app open', {}, { 'last seen date web': now, 'last seen date': now });

analytics.trackUser((user) => {
  return user
    .setOnce('app first use date', now)
    .increment('count of web sessions', 1)
    .increment('count of all sessions', 1);
});

window.addEventListener('beforeunload', () => {
  analytics.trackEvent('app close', {});
});
