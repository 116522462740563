import React from 'react';
import classnames from 'classnames';

import { Icon, Props as IconProps } from '@/components/Icon';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon: IconProps['type'];
}

export const PageControlButton: React.FC<Props> = ({ icon, className, ...props }) => (
  <button {...props} className={classnames('w-[5.25rem] group', className)}>
    <Icon type={icon} className="text-[2.5rem] text-gray-600 transition group-hover:text-scarlet" />
  </button>
);
