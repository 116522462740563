import React from 'react';
import { Tab } from '@headlessui/react';

import { TabButton } from '@/components/TabButton';

export interface Props {}

export const SidebarTab = ({ children }: React.PropsWithChildren<Props>) => {
  return (
    <Tab as={React.Fragment}>
      {({ selected }) => (
        <TabButton is="button" {...{ selected }} className="mx-4">
          {children}
        </TabButton>
      )}
    </Tab>
  );
};
