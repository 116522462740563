import React from 'react';
import type {
  IContentLocation,
  IPointerEngineEvent
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

import { HighlightColor } from '@/domains';
import { IReaderService } from '@/components/Reader/services';

interface SelectionData {
  text: string;
  contentLocation: IContentLocation;
  color?: HighlightColor;
}

interface Coordinates {
  x: number;
  y: number;
}

interface TextSelectionOptions extends SelectionData {
  position: Coordinates;
}

const getMouseCoordinates = (event: IPointerEngineEvent): Coordinates => ({
  x: Math.floor(event.clientX),
  y: Math.floor(event.clientY)
});

export const useTextSelection = (readerService: IReaderService) => {
  const data = React.useRef<SelectionData>();
  const [selection, setSelection] = React.useState<TextSelectionOptions>();

  React.useEffect(() => {
    return readerService.subscribeToEvent('pointerup', (event) => {
      const position = getMouseCoordinates(event);

      if (!data.current) return;

      setSelection({ position, ...data.current });
    });
  }, [readerService, setSelection]);

  React.useEffect(() => {
    return readerService.subscribeToEvent('selectionChanged', ({ isRange, contentLocation, selectionText }) => {
      const text = selectionText?.trim();

      data.current = !isRange || !contentLocation || !text ? undefined : { text, contentLocation };

      if (!data.current) setSelection(undefined);
    });
  }, [readerService, data]);

  return { selection, setSelection };
};
