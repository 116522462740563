import React from 'react';
import { Tab } from '@headlessui/react';

import { Icon } from '@/components/Icon';
import { Reader } from '@/components/Reader/containers/ReaderContainer';

export interface Props {}

export const SidebarTabList = ({ children }: React.PropsWithChildren<Props>) => {
  const { sidebar } = Reader.useContainer();

  return (
    <div className="flex flex-1 items-center mb-6">
      <button onClick={sidebar.close} className="inline-flex justify-center items-center w-8 h-8 -ml-2 mr-4">
        <Icon type="chevron-right" className="text-[1.5rem] mr-1" />
      </button>

      <Tab.List className="text-title w-full -mx-4">{children}</Tab.List>
    </div>
  );
};
