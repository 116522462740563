import React from 'react';
import { Combobox } from '@headlessui/react';

import { GlossaryResult, SearchType } from '@/domains';

import { SectionTitle } from './SectionTitle';

interface Props {
  data: GlossaryResult[];
  showSectionTitle: boolean;
}

export const GlossaryResults: React.FC<Props> = ({ data, showSectionTitle }) => (
  <React.Fragment>
    {showSectionTitle && <SectionTitle type={SearchType.Glossary} />}

    {data.map((item, index) => (
      <Combobox.Option key={index} value={item} disabled className="mb-3 px-6 text-gray-800">
        <span className="capitalize">{`${item.content}: `}</span>

        <span>{`${item.definitions.map((definition) => definition)}`}</span>
      </Combobox.Option>
    ))}
  </React.Fragment>
);
