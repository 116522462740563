import { Lesson, LessonListItem, Content, Tenant, LessonType, AnyAuxiliaryMaterialItem } from '@/domains';
import { Header } from '@/services/network/http';
import { authorizedApi } from '@/services/network/authorized-api';
import { ContentLanguage } from '@/domains/content-language';

export interface GetLessonByIdOptions {
  lessonId: string;
  language: ContentLanguage;
}

export const getLessonById = ({ lessonId, language }: GetLessonByIdOptions) =>
  authorizedApi.get<Lesson>(`/v1/lessons/${lessonId}`, undefined, {
    headers: { [Header.AcceptLanguage]: language }
  });

export const getAuxiliaryMaterial = (lessonId: string, materialId: string) =>
  authorizedApi.get<AnyAuxiliaryMaterialItem>(`/v1/lessons/${lessonId}/auxiliary-materials/${materialId}`);

export const getLessons = ({
  language,
  ...params
}: {
  type?: LessonType;
  language: ContentLanguage;
  tenant: Tenant;
  supplementLessonModuleId?: string;
  materialType?: 'DEFAULT' | 'WELCOME';
}) =>
  authorizedApi.get<Content<LessonListItem>>(`/v1/lessons`, params, {
    headers: { [Header.AcceptLanguage]: language }
  });
