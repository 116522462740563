import React from 'react';
import classnames from 'classnames';

import { Is, Props as IsProps, IsComponent } from '@/components/Is';

export type Props<T extends IsComponent> = IsProps<T> & {
  active?: boolean;
  disabled?: boolean;
};

const Button = <T extends IsComponent>(
  { active, disabled, className, ...props }: React.PropsWithChildren<Props<T>>,
  ref: React.Ref<any>
) => (
  <Is
    {...props}
    {...{ ref, disabled }}
    className={classnames(
      className,
      'inline-flex flex-shrink-0 justify-center items-center w-10 h-10 text-[1.5rem] rounded transition',
      { 'text-gray-200': disabled, 'hover:bg-scarlet-50 hover:text-scarlet-700 hover:cursor-pointer': !disabled },
      { 'text-scarlet': !disabled && !active, 'text-white bg-scarlet': !disabled && active }
    )}
  />
);

export const ReaderHeaderButton = React.forwardRef(Button);
