import { ReadLessonSummary } from './read-lesson-summary';

export interface Highlight {
  id: string;
  content: string;
  location: string;
  createdAt: string;
  colorCode: string;
  sortingLocation: string;
  chapterName?: string;
  lesson: ReadLessonSummary;
}

export const HIGHLIGHT_COLORS = ['#ff7979', '#65ceff', '#ff9544', '#ffe356'] as const;
export type HighlightColor = typeof HIGHLIGHT_COLORS[number];
