import { Identify, Types } from '@amplitude/analytics-browser';

import { UserProperties } from '../user-properties';
import { FilteredProps, UserTracker } from '../user-tracker';

export class AmplitudeUserTracker implements UserTracker {
  private identify = new Identify();

  private getValidValue(value: UserProperties[keyof UserProperties]): Types.ValidPropertyType {
    if (value instanceof Date) return value.toISOString();

    return value;
  }

  set<T extends keyof UserProperties>(key: T, value: UserProperties[T]): this {
    this.identify.set(key, this.getValidValue(value));
    return this;
  }

  setOnce<T extends keyof UserProperties>(key: T, value: UserProperties[T]): this {
    this.identify.setOnce(key, this.getValidValue(value));
    return this;
  }

  increment<T extends keyof FilteredProps<UserProperties, number>>(key: T, value: UserProperties[T]): this {
    this.identify.add(key, value);
    return this;
  }

  toIdentify() {
    return this.identify;
  }
}
