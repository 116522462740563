import React from 'react';

import { BookmarkListItem } from '@/components/BookmarkListItem';
import { Reader } from '@/components/Reader/containers/ReaderContainer';
import { BookmarkAnnotations } from '@/components/Reader/BookmarkAnnotations';
import { Modal, useModal } from '@/components/Modal';
import { ConfirmationModal } from '@/components/Modals/ConfirmationModal';

export const BookmarksTab: React.FC = () => {
  const { readerService } = Reader.useContainer();
  const bookmarks = BookmarkAnnotations.useContainer();

  const modal = useModal(ConfirmationModal);

  return (
    <React.Fragment>
      <Modal {...modal.props} />

      <ul>
        {bookmarks.list?.map((item) => (
          <BookmarkListItem
            key={item.id}
            bookmark={item}
            createdAt={false}
            className="py-2.5"
            onClick={() => readerService.goToSelector(item.location)}
            onDelete={() => modal.open(() => bookmarks.remove(item))}
          />
        ))}
      </ul>
    </React.Fragment>
  );
};
