import React from 'react';
import { useLocalStorage } from 'react-use';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { languageToSettingsUrlMap } from '@/constants';

import { LanguageContainer } from '@/containers/LanguageContainer';

import { Button } from '@/components/Button';

import { Link } from './Link';

export const CookieBar = () => {
  const [accepted, setAccepted] = useLocalStorage('cookies-accepted', false);
  const { language } = LanguageContainer.useContainer();

  if (accepted) return null;

  return (
    <div className="bg-blue-900 fixed text-white inset-x-0 bottom-0 h-auto z-50 p-6 flex justify-center items-center">
      <div className="mr-6">
        <FormattedMessage
          id={translations.application.cookieBar.text}
          values={{
            a: (children: React.ReactNode) => (
              <Link appearance="white" external to={languageToSettingsUrlMap[language].privacyPolicy}>
                {children}
              </Link>
            )
          }}
        />
      </div>

      <Button onClick={() => setAccepted(true)} appearance="scarlet">
        <FormattedMessage id={translations.application.cookieBar.button} />
      </Button>
    </div>
  );
};
