import React from 'react';
import { useAsync } from 'react-use';
import { Dialog } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';

import { SearchType } from '@/domains';
import { translations } from '@/locales';
import { search } from '@/services/api/search';

import { TenantContainer } from '@/containers/TenantContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { SessionContainer } from '@/containers/SessionContainer';

import { Icon } from '@/components/Icon';
import { Button } from '@/components/Button';
import { InstanceProps } from '@/components/Modal';
import { Loading, LoadingCenter, LoadingIndicator } from '@/components/Loading';

import { GlossaryResults } from './GlossaryResults';
import { DictionaryResults } from './DictionaryResults';

import lotus from '@/assets/blue-wide-open-1.svg';

export interface Props extends InstanceProps<[]> {
  content: string;
}

export const LearnMoreModal: React.FC<Props> = ({ content, onClose }) => {
  const { tenant } = TenantContainer.useContainer();
  const { language } = LanguageContainer.useContainer();
  const { authenticated, me } = SessionContainer.useContainer();

  const searchCategoriesWithLanguage = [SearchType.Glossary, SearchType.Dictionary].map((item) => ({
    type: item,
    language: authenticated && me?.lessonsLanguage ? me.lessonsLanguage : language
  }));

  const { value, loading } = useAsync(() => search(content, tenant, language, searchCategoriesWithLanguage), []);

  return (
    <div className="text-gray-800 w-[45rem] min-h-[10rem]">
      <div>
        {(!!value?.glossary?.length || !!value?.dictionary?.length) && (
          <Dialog.Title className="text-gray-800 text-question pb-6 mb-6 border-b border-solid border-gray-200">
            <FormattedMessage id={translations.modals.learnMoreModal.title} />
            <span className="font-bold">{` "${content}"`}</span>
          </Dialog.Title>
        )}

        <button
          onClick={onClose}
          className="absolute top-7 right-7 inline-flex justify-center items-center p-0 hover:text-scarlet-700 transition z-30"
        >
          <Icon type="close" className="text-[1.5rem]" />
        </button>

        <Loading visible={loading}>
          <LoadingCenter>
            <LoadingIndicator />
          </LoadingCenter>
        </Loading>
      </div>

      {!!value?.glossary?.length && <GlossaryResults data={value?.glossary} />}

      {!!value?.dictionary?.length && <DictionaryResults data={value?.dictionary} />}

      {!!value && !value.glossary?.length && !value.dictionary?.length && (
        <div className="flex flex-col items-center justify-between">
          <img src={lotus} className="h-20" />

          <div className="text-question mt-4 flex">
            <FormattedMessage id={translations.modals.learnMoreModal.empty.title} />
          </div>
          <div className="text-question font-bold mb-4">{`"${content}"`}</div>

          <div className="mb-8 text-gray-600">
            <FormattedMessage id={translations.modals.learnMoreModal.empty.subtitle} />
          </div>

          <Button type="button" appearance="scarlet" className="font-medium w-[322px] mx-auto" onClick={onClose}>
            <FormattedMessage id={translations.modals.learnMoreModal.empty.keepReading} />
          </Button>
        </div>
      )}
    </div>
  );
};
