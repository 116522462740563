import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useOutletContext } from 'react-router-dom';

import { urls } from '@/constants';
import { VLDHandbook } from '@/domains';
import { translations } from '@/locales';

import { TenantContainer } from '@/containers/TenantContainer';
import { Button } from '@/components/Button';

export const VLDHandbookPage: React.FC = () => {
  const { tenant } = TenantContainer.useContainer();
  const value = useOutletContext<VLDHandbook>();

  return (
    <main className="flex flex-col items-center">
      {!!value && (
        <div className="max-w-4xl flex flex-col pb-16">
          <img src={value.imageUrl} className="my-6 bg-cover aspect-video" />

          <div className="text-headline-1 font-semibold mb-6">{value.title}</div>

          <div className="text-body mb-10 whitespace-pre-wrap">{value.content}</div>

          <Button
            is={Link}
            // TODO
            // @ts-ignore
            to={[urls.otherContent.root, urls.otherContent.vld.root, urls.otherContent.vld.reader].join('')}
            className="inline-flex items-center font-medium h-12 self-center rounded bg-scarlet text-white"
          >
            <FormattedMessage id={translations.application.header.otherContent.vld[tenant].readButton} />
          </Button>
        </div>
      )}
    </main>
  );
};
