import { AMPLITUDE_API_KEY } from '@/config';

import { EventType, EventProperties } from './event-properties';
import { UserProperties } from './user-properties';
import { Analytics } from './types';
import { UserTrackerCallback } from './user-tracker';

import { AmplitudeAnalytics } from './amplitude';
import { FirebaseAnalytics } from './firebase';
import { firebaseApp } from '../firebase';

class AnalyticsService implements Analytics {
  constructor(private providers: Analytics[]) {}

  private executeOnProvider(callback: (provider: Analytics) => void) {
    this.providers.forEach((provider) => {
      try {
        callback(provider);
      } catch (e) {
        console.error(e);
      }
    });
  }

  getCrossDomainParameters(): Record<string, string | undefined> {
    return this.providers.reduce((params, provider) => ({ ...params, ...provider.getCrossDomainParameters() }), {});
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E], userProperties?: Partial<UserProperties>): void {
    this.executeOnProvider((provider) => provider.trackEvent(type, event, userProperties));
  }

  setCurrentUser(userId?: string): void {
    this.executeOnProvider((provider) => provider.setCurrentUser(userId));
  }

  trackUser(callback: UserTrackerCallback): void {
    this.executeOnProvider((provider) => provider.trackUser(callback));
  }
}

export const analytics = new AnalyticsService([
  ...(AMPLITUDE_API_KEY ? [new AmplitudeAnalytics({ apiKey: AMPLITUDE_API_KEY })] : []),
  ...(firebaseApp.options.apiKey && firebaseApp.options.appId && firebaseApp.options.measurementId
    ? [new FirebaseAnalytics({ app: firebaseApp })]
    : [])
]);
