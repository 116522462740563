import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { urls } from '@/constants';
import { SupplementLessonModule } from '@/domains';
import { translations } from '@/locales';

import { Loading, LoadingCenter, LoadingIndicator } from '@/components/Loading';
import { Image } from '@/components/Image';

import { useLoadSupplementModules } from '../hooks/useLoadSupplementModules';

const text = translations.pages.lessons.supplement.modules;

const SupplementModuleGrid: React.FC = (props) => (
  <ul {...props} className="grid gap-4 grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6" />
);

const SupplementModuleTile: React.FC<{ module: SupplementLessonModule }> = ({ module }) => (
  <li>
    <Link
      to={urls.getModuleUrl({ moduleId: module.id })}
      className="relative flex h-36 rounded p-4 group overflow-hidden"
    >
      <Image
        url={module.imageUrl}
        className="absolute inset-0 bg-cover after:bg-black/25 after:absolute after:inset-0 transition duration-500 group-hover:scale-105"
      />

      <p className="self-end z-10 text-white font-semibold">{module.name}</p>
    </Link>
  </li>
);

export const SupplementModulesPage: React.FC = () => {
  const { data: value, isValidating: loading } = useLoadSupplementModules();

  return (
    <div className="relative h-full px-[5.25rem]">
      <Loading visible={!value && loading}>
        <LoadingCenter>
          <LoadingIndicator />
        </LoadingCenter>
      </Loading>

      {!!value?.available.length && (
        <section className="py-6">
          <h3 className="font-semibold text-question mb-6">
            <FormattedMessage id={text.available} />
          </h3>

          <SupplementModuleGrid>
            {value.available.map((module) => (
              <SupplementModuleTile key={module.id} {...{ module }} />
            ))}
          </SupplementModuleGrid>
        </section>
      )}

      {!!value?.upcoming.length && (
        <section className="py-6">
          <h3 className="font-semibold text-question mb-6">
            <FormattedMessage id={text.upcoming} />
          </h3>

          <SupplementModuleGrid>
            {value.upcoming.map((module) => (
              <SupplementModuleTile key={module.id} {...{ module }} />
            ))}
          </SupplementModuleGrid>
        </section>
      )}
    </div>
  );
};
