import React from 'react';
import { createContainer } from 'unstated-next';
import { useLocalStorage, useAsync } from 'react-use';

import { useOpen } from '@/hooks/useOpen';
import { ReaderService, IReaderService } from '@/components/Reader/services';

export interface FileOptions {
  url?: string;
  audio?: string;
}

export interface Options {
  file?: FileOptions;
  audiobookMode?: boolean;
  selector?: string;
  encryption?: {
    key: string;
    iv: string;
  };
  session?: {
    userToken: string;
    publicationToken: string;
  };
}

const useReader = ({ file, audiobookMode, selector, encryption, session }: Options = {}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const sidebar = useOpen(false);
  const [isAudiobookOn, setIsAudiobookOn] = React.useState(() => audiobookMode ?? false);
  const [scale, setScale] = useLocalStorage<number>('scale');
  const readerService = React.useMemo<IReaderService>(() => new ReaderService(ref), [ref]);

  const { loading } = useAsync(async () => {
    const url = isAudiobookOn ? file?.audio ?? file?.url : file?.url ?? file?.audio;

    if (!file || !url) throw new Error(`No publication url was received.`);

    if (!session) throw new Error(`No reader session was configured.`);

    await readerService.load({ url, audioEnabled: isAudiobookOn, selector, encryption, session });
    // for whatever reason we can't start the player instantly, so we have to use a timeout
    if (isAudiobookOn) setTimeout(readerService.mediaService!.play.bind(readerService.mediaService), 0);
  }, [isAudiobookOn, file?.audio, file?.url, readerService, session, selector]);

  const tableOfContents = useAsync(async () => {
    if (loading) return;

    return readerService.getTableOfContents();
  }, [loading]);

  React.useEffect(() => {
    return readerService.subscribeToEvent('keydown', (event) => {
      switch (event.code) {
        case 'ArrowRight':
          readerService.goToNextPage();
          break;
        case 'ArrowLeft':
          readerService.goToPreviousPage();
          break;
      }
    });
  }, [readerService]);

  React.useEffect(() => {
    readerService.updateFontScale(scale ?? 1);
  }, [readerService, scale]);

  React.useEffect(() => {
    return () => readerService.destroy();
  }, [readerService]);

  return React.useMemo(
    () => ({
      audiobook: {
        on: isAudiobookOn,
        disabled: !file?.audio,
        toggle: () => setIsAudiobookOn(!isAudiobookOn)
      },
      sidebar,
      tableOfContents,
      loading: loading,
      ref,
      readerService,
      scale: {
        value: scale ?? 1,
        update: setScale
      },
      selector
    }),
    [
      isAudiobookOn,
      setIsAudiobookOn,
      sidebar,
      loading,
      ref,
      readerService,
      scale,
      setScale,
      tableOfContents,
      selector,
      file?.audio
    ]
  );
};

export const Reader = createContainer(useReader);
