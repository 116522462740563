import { Language } from '@/constants';
import { UserDetails, UserPreferences } from '@/domains';
import { Header } from '@/services/network/http';
import { authorizedApi } from '@/services/network/authorized-api';

export const getUserPreferences = () => authorizedApi.get<UserPreferences>(`/v1/users/me/preferences`);

export const getMe = (language: Language) =>
  authorizedApi.get<UserDetails>(`/v1/users/me`, undefined, { headers: { [Header.AcceptLanguage]: language } });

export const updateUserPreferences = (preferences: UserPreferences) =>
  authorizedApi.patch<UserPreferences>(`/v1/users/me/preferences`, preferences);
