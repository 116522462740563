import { EpubCfi } from '@/utils';
import { Annotation, AnnotationOrder, Group, GroupedByLesson, LocationSortableAnnotation } from '@/domains';

import { orderByLesson } from './lesson';

export const groupByLesson = <T extends LocationSortableAnnotation>(items: T[]) => {
  const map = items.reduce<Group<GroupedByLesson<T>>>((map, item) => {
    const { lesson } = item;
    const found = map[lesson.id];

    if (!found) {
      map[lesson.id] = { lesson, items: [item] };
      return map;
    }

    found.items = [item, ...found.items].sort((one, other) => {
      const locationComparisonResult = EpubCfi.compare(one.location, other.location);

      if (!locationComparisonResult) return one.createdAt.localeCompare(other.createdAt);

      return locationComparisonResult;
    });

    return map;
  }, {});

  return orderByLesson(Object.values(map), (item) => item.lesson);
};

export const ascendingByLocation = <T extends LocationSortableAnnotation>(one: T, other: T) => {
  return one.sortingLocation.localeCompare(other.sortingLocation);
};

export const descendingByCreatedAt = <T extends Annotation>(one: T, other: T) => {
  return other.createdAt.localeCompare(one.createdAt);
};

export const orderAnnotations = <T extends LocationSortableAnnotation>(result: T[], order?: AnnotationOrder) => {
  if (order != 'ascending-by-location') return result;

  return result.sort(ascendingByLocation);
};
