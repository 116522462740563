import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, Link, Outlet } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { analytics } from '@/services/analytics';

import { Icon } from '@/components/Icon';

import { LessonList } from '../components/LessonList';
import { useLoadSupplementModuleLessons } from '../hooks/useLoadSupplementModuleLessons';
import { LessonAside, LessonAsideSection } from '../components/LessonAside';
import { LessonListItemLink, LessonListItemProgress } from '../components/LessonListItemLink';

const text = translations.pages.lessons.supplement.modules;

export const SupplementModuleDetailPage: React.FC = () => {
  const { moduleId } = useParams<{ moduleId: string }>();
  const { data: value, isValidating: loading } = useLoadSupplementModuleLessons(moduleId!);

  React.useEffect(() => {
    if (!value?.id) return;

    analytics.trackEvent('view module', {
      module_title: value.name
    });
  }, [value?.id]);

  return (
    <div className="flex h-full w-full">
      <LessonAside>
        <LessonAsideSection>
          <div className="pr-[1.875rem] pl-[5.25rem]">
            <Link
              to={urls.getModuleListUrl()}
              className="inline-flex items-center px-3 py-2 -ml-3 my-4 font-semibold rounded text-scarlet hover:text-scarlet-700 hover:bg-scarlet-50"
            >
              <Icon type="chevron-left" className="text-[20px] mr-2" />

              <div className="-mb-[2px]">
                <FormattedMessage id={text.back} />
              </div>
            </Link>

            {!!value && <p className="mb-4 text-question font-semibold">{value.name}</p>}
          </div>

          <LessonList loading={!value && loading}>
            {value?.lessons.map((lesson) => (
              <LessonListItemLink
                key={lesson.id}
                {...{ lesson }}
                href={urls.getLessonUrl({ moduleId, lessonType: lesson.type, lessonId: lesson.id })}
                onClick={() =>
                  analytics.trackEvent('click module lesson', {
                    module_title: value.name,
                    lesson_number: lesson.displayedNumber,
                    lesson_title: lesson.title
                  })
                }
              >
                <LessonListItemProgress {...{ lesson }} />
              </LessonListItemLink>
            ))}
          </LessonList>
        </LessonAsideSection>
      </LessonAside>

      <main className="w-2/3 overflow-y-auto">
        <Outlet />
      </main>
    </div>
  );
};
