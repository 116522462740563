import flat from 'flat';
import * as yup from 'yup';

import { Language } from '@/constants/language';

import englishUsMessage from './en-us.json';
import englishIndianMessage from './en-in.json';
import italianMessage from './it.json';
import thamilMessage from './ta.json';
import portugueseMessage from './pt.json';
import teluguMessage from './te.json';
import hindiMessage from './hi.json';
import spanishMessage from './es.json';
import bengaliMessage from './bn.json';

export type LocaleTranslation = typeof englishUsMessage;

const messages: { [k in Language]?: LocaleTranslation } = {
  [Language.EnglishUS]: englishUsMessage,
  [Language.EnglishIndian]: englishIndianMessage,
  [Language.Italian]: italianMessage,
  [Language.Tamil]: thamilMessage,
  [Language.Portuguese]: portugueseMessage,
  [Language.Telugu]: teluguMessage,
  [Language.Hindi]: hindiMessage,
  [Language.Spanish]: spanishMessage,
  [Language.Bengali]: bengaliMessage
};

const traverse = (obj: any, path = ''): any => {
  if (obj === null) {
    return null;
  }

  const result: any = {};

  if (typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      result[key] = traverse(obj[key], path ? `${path}.${key}` : key);
    });
  } else {
    return path;
  }

  return result;
};

export const translations: LocaleTranslation = traverse(englishUsMessage);

yup.setLocale(translations.validation.basic);

export const getTranslationMessages = (language: Language): any => flat(messages[language]);
