import React from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

import { urls } from '@/constants';
import { Lesson } from '@/domains';

import { Breadcrumb } from '@/components/Breadcrumb';

import { translations } from '@/locales';

export interface Props {
  lesson: Lesson;
}

const text = translations.pages.reader.header;

export const ReaderLessonBreadcrumb: React.FC<Props> = ({ lesson }) => {
  const intl = useIntl();
  const { moduleId } = useParams<{ moduleId?: string }>();

  const shape = {
    [intl.formatMessage({ id: text.breadcrumb[lesson.type] })]: urls.getLessonListUrl({
      moduleId,
      lessonType: lesson.type
    }),
    [`${lesson.displayedNumber}. ${lesson.title}`]: urls.getLessonUrl({
      moduleId,
      lessonType: lesson.type,
      lessonId: lesson.id
    })
  };

  return <Breadcrumb {...{ shape }} className="flex items-center" />;
};
