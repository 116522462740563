import React from 'react';

import { Loading, LoadingCenter, LoadingIndicator } from '@/components/Loading';

export const LoadingPage: React.FC = () => {
  return (
    <Loading visible>
      <LoadingCenter>
        <LoadingIndicator />
      </LoadingCenter>
    </Loading>
  );
};
