import React from 'react';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { useAsyncFn } from 'react-use';
import { useNavigate } from 'react-router';
import { Combobox, Transition } from '@headlessui/react';

import { urls } from '@/constants';
import { getLessonById, search } from '@/services/api';
import { Lesson, SearchResult, SearchType } from '@/domains';

import { TenantContainer } from '@/containers/TenantContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { SessionContainer } from '@/containers/SessionContainer';

import { Icon } from '@/components/Icon';
import { Loading, LoadingIndicator } from '@/components/Loading';

import { FAQ } from './FAQ';
import { NoResults } from './NoResults';
import { LessonResults } from './LessonResults';
import { GlossaryResults } from './GlossaryResults';
import { HighlightResults } from './HighlightResults';
import { AuxiliaryMaterialResults } from './AuxiliaryMaterialResults';
import { EnergizationShortcut } from './EnergizationShortcut';

export interface Props {
  className?: string;
  lesson?: Lesson;
  searchCategories: SearchType[];
  onLessonSearch?(locator: string): void;
  onSearchSuccess?(o: { query: string }): void;
  onResultClick?(o: { query: string; item: string; type: SearchType }): void;
}

const useDebouncedValue = ({
  value,
  timeout,
  onChange
}: {
  value: string;
  timeout: number;
  onChange(value: string): void;
}) => {
  const [input, setInput] = React.useState(value);

  const onDebouncedChange = React.useCallback(debounce(onChange, timeout), [onChange]);

  return [
    input,
    React.useCallback(
      (value: string) => {
        setInput(value);
        onDebouncedChange(value);
      },
      [setInput, onDebouncedChange]
    )
  ] as const;
};

export const SearchBar: React.FC<Props> = ({
  className,
  lesson,
  searchCategories,
  onLessonSearch,
  onSearchSuccess,
  onResultClick
}) => {
  const navigate = useNavigate();
  const { tenant } = TenantContainer.useContainer();
  const { language } = LanguageContainer.useContainer();
  const { me } = SessionContainer.useContainer();

  const [isClosed, setIsClosed] = React.useState(false);

  const onOpen = () => setIsClosed(false);
  const onClose = () => setIsClosed(true);

  const searchCategoriesWithLanguage = React.useMemo(() => {
    return searchCategories.map((item) => ({
      type: item,
      language: me?.lessonsLanguage ?? language
    }));
  }, [searchCategories, me?.lessonsLanguage, language]);

  const withSectionTitles = searchCategories.length > 1;

  const [{ loading, value: results }, onSearch] = useAsyncFn(
    async (query: string) => {
      if (query.length < 2) return;

      return search(query, tenant, language, searchCategoriesWithLanguage, lesson?.id).then((results) => {
        onSearchSuccess?.({ query });
        return results;
      });
    },
    [lesson?.id, tenant, language, searchCategoriesWithLanguage]
  );

  const [value, onChange] = useDebouncedValue({
    value: '',
    timeout: 300,
    onChange: React.useCallback(onSearch, [onSearch])
  });

  const handleSelection = (item: NonNullable<SearchResult[keyof SearchResult]>[number]) => {
    onResultClick?.({ query: value, item: item.content, type: item.type });

    switch (item.type) {
      case SearchType.LessonContent:
        if (lesson?.id && item.locator) onLessonSearch?.(item.locator);

        return getLessonById({ lessonId: item.lessonId, language })
          .then((lesson) =>
            navigate(
              `${urls.getLessonUrl({ lessonType: lesson.type, lessonId: item.lessonId })}/reader`,
              urls.getReaderParams({ selector: item.locator })
            )
          )
          .catch(() => null);
      case SearchType.AuxiliaryMaterial: {
        return getLessonById({ lessonId: item.lessonId, language })
          .then((lesson) =>
            navigate(
              `${urls.getLessonUrl({ lessonType: lesson?.type, lessonId: item.lessonId })}/materials/${item.materialId}`
            )
          )
          .catch(() => null);
      }
      case SearchType.Highlight: {
        return getLessonById({ lessonId: item.lessonId, language })
          .then((lesson) =>
            navigate(
              `${urls.getLessonUrl({ lessonType: lesson.type, lessonId: item.lessonId })}/reader`,
              urls.getReaderParams({ selector: item.locator })
            )
          )
          .catch(() => null);
      }
    }
  };

  return (
    <div className="relative">
      <Icon type="search" className="text-[24px] absolute top-1/2 left-3 pointer-events-none z-10 -translate-y-1/2" />

      {/** @ts-ignore */}
      <Combobox as="div" {...{ value }} onChange={handleSelection}>
        {({ open }) => (
          <React.Fragment>
            <Combobox.Input
              className={classnames(className, 'pl-11 h-10 rounded')}
              onChange={(event) => {
                onChange(event.target.value ?? '');
                onOpen();
              }}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.code === 'Enter') event.preventDefault();
              }}
            />

            <Transition
              as="div"
              show={!isClosed && open && value.length > 2}
              enter="transition duration-200 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-150 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              className="absolute top-full right-0"
              onClick={onClose}
            >
              <Combobox.Options
                as="div"
                className="mt-1 text-gray-800 flex flex-col bg-white w-96 rounded shadow max-h-[500px] min-h-[200px] overflow-y-auto relative"
              >
                <FAQ {...{ language }} />

                <EnergizationShortcut onClick={onClose} />

                <Loading visible={loading} className="flex justify-center">
                  <LoadingIndicator />
                </Loading>

                {!!results && (
                  <React.Fragment>
                    {!!results.glossary?.length && (
                      <GlossaryResults data={results.glossary} showSectionTitle={withSectionTitles} />
                    )}

                    {!!results.highlights?.length && (
                      <HighlightResults
                        data={results.highlights}
                        searchTerm={value}
                        showSectionTitle={withSectionTitles}
                      />
                    )}

                    {!!results.auxiliaryMaterials?.length && (
                      <AuxiliaryMaterialResults
                        data={results.auxiliaryMaterials}
                        showSectionTitle={withSectionTitles}
                      />
                    )}

                    {!!results.lessons?.length && (
                      <LessonResults data={results.lessons} searchTerm={value} showSectionTitle={withSectionTitles} />
                    )}

                    {!results.dictionary?.length &&
                      !results.glossary?.length &&
                      !results.highlights?.length &&
                      !results.lessons?.length &&
                      !results.auxiliaryMaterials?.length &&
                      !results.guidedMeditations?.length &&
                      !results.news?.length && <NoResults />}
                  </React.Fragment>
                )}
              </Combobox.Options>
            </Transition>
          </React.Fragment>
        )}
      </Combobox>
    </div>
  );
};
