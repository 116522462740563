import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';

import { translations } from '@/locales';

import { Button } from '@/components/Button';

import regularError from '@/assets/regular-error.svg';

const text = translations.application.error;

export const ErrorBoundary: React.FC = ({ children }) => (
  <Sentry.ErrorBoundary
    showDialog={false}
    fallback={({ eventId, resetError }) => (
      <div className="h-full w-full flex relative">
        <div className="pt-16 z-10 max-w-2xl lg:max-w-3xl mx-auto">
          <div className="text-headline-1 mb-6">
            <FormattedMessage id={text.title} values={{ br: <br /> }} />
          </div>

          <div className="mb-2 text-title">
            <FormattedMessage id={text.event} values={{ eventId }} />
          </div>

          <div className="mb-10 text-title">
            <FormattedMessage id={text.message} />
          </div>

          <Button onClick={resetError} appearance="scarlet">
            <FormattedMessage id={text.button} />
          </Button>
        </div>

        <img className="h-full absolute top-0 right-0 bottom-0" src={regularError} />
      </div>
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
);
