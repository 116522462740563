import { MediaType } from '@colibrio/colibrio-reader-framework/colibrio-core-io-base';
import { ILocator, Locator } from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import { IReaderPublication, IReaderView } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import { IEpubReaderPublication } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub';
import type { IPdfReaderPublication } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-pdf';

import { epubCfiSelectorFactory } from './epubcfi-selector-factory';

export function getMediaTypeFromPublication(publication: IReaderPublication) {
  return publication.getSourcePublication().getMediaType();
}

export function isEpubPublication(publication: IReaderPublication): publication is IEpubReaderPublication {
  return getMediaTypeFromPublication(publication) === MediaType.APPLICATION_EPUB_ZIP;
}

export function isPdfPublication(publication: IReaderPublication): publication is IPdfReaderPublication {
  return getMediaTypeFromPublication(publication) === MediaType.APPLICATION_PDF;
}

export const getLocatorFromEpubCfi = (publicationUrl: string, epubCfi: string) => {
  const selector = epubCfiSelectorFactory.createFromFragment(epubCfi);

  if (!selector) return;

  return new Locator(publicationUrl, [selector]);
};

export const getPublicationUrl = (readerView: IReaderView) => {
  return readerView.getReaderPublications()[0]?.getDefaultLocatorUrl();
};

export const getEpubCfiFromLocator = (locator: ILocator) => {
  const selectors = locator.getSelectors();

  if (!selectors.length) return;

  return selectors[0].toString();
};

export const getContentLocation = (readerView: IReaderView, locator: ILocator) => {
  return readerView.getReaderPublications()[0]?.fetchContentLocation(locator);
};
