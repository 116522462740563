import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { Loading, LoadingCenter, LoadingIndicator } from '@/components/Loading';

const text = translations.pages.lessons.basic;

export interface LessonListProps {
  loading: boolean;
}

export const LessonList: React.FC<LessonListProps> = ({ loading, children }) => {
  const count = React.Children.count(children);

  return (
    <React.Fragment>
      {count > 0 && <ul className="relative divide-y divide-solid divide-gray-200">{children}</ul>}

      <Loading visible={loading}>
        <LoadingCenter>
          <LoadingIndicator />
        </LoadingCenter>
      </Loading>

      {!loading && count < 1 && (
        <p className="pr-[1.875rem] pl-[5.25rem] my-5 font-semibold text-title">
          <FormattedMessage id={text.empty} />
        </p>
      )}
    </React.Fragment>
  );
};
