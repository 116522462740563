import React from 'react';
import { Routes, Route } from 'react-router';

import { RandomQuotePlaceholder } from '@/components/RandomQuotePlaceholder';
import { LoadComponent } from '@/components/LoadComponent';

import { SupplementHeader } from './SupplementHeader';
import { SupplementLessonListPage } from './SupplementLessonListPage';
import { LessonDetailPage } from '../LessonDetailPage';
import { LessonTOCPage } from '../LessonDetailPage/LessonTOCPage';
import { LessonAuxiliaryMaterialPage } from '../LessonDetailPage/LessonAuxiliaryMaterialPage';
import { SupplementModulesPage } from './SupplementModulesPage';
import { SupplementModuleDetailPage } from './SupplementModuleDetailPage';
import { LessonAuxiliaryMaterialDetailPage } from '../LessonDetailPage/LessonAuxiliaryMaterialDetailPage';

const LessonReaderPage = React.lazy(() =>
  import('../LessonDetailPage/LessonReaderPage').then((m) => ({
    default: m.LessonReaderPage
  }))
);

const AuxiliaryMaterialReaderPage = React.lazy(() =>
  import('../LessonDetailPage/AuxiliaryMaterialReaderPage').then((m) => ({
    default: m.AuxiliaryMaterialReaderPage
  }))
);

export const SupplementLessonPage: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SupplementHeader />}>
        <Route path="lessons" element={<SupplementLessonListPage />}>
          <Route index element={<RandomQuotePlaceholder />} />
          <Route path=":lessonId" element={<LessonDetailPage />}>
            <Route index element={<LessonTOCPage />} />
            <Route path="materials" element={<LessonAuxiliaryMaterialPage />}>
              <Route path=":materialId" element={<LessonAuxiliaryMaterialDetailPage />} />
            </Route>
          </Route>
        </Route>

        <Route path="modules" element={<SupplementModulesPage />} />
        <Route path="modules/:moduleId/lessons" element={<SupplementModuleDetailPage />}>
          <Route index element={<RandomQuotePlaceholder />} />
          <Route path=":lessonId" element={<LessonDetailPage />}>
            <Route index element={<LessonTOCPage />} />
            <Route path="materials" element={<LessonAuxiliaryMaterialPage />}>
              <Route path=":materialId" element={<LessonAuxiliaryMaterialDetailPage />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route
        path="/lessons/:lessonId/reader"
        element={
          <LoadComponent>
            <LessonReaderPage />
          </LoadComponent>
        }
      />
      <Route
        path="/lessons/:lessonId/materials/:materialId/reader"
        element={
          <LoadComponent>
            <AuxiliaryMaterialReaderPage />
          </LoadComponent>
        }
      />
      <Route
        path="/modules/:moduleId/lessons/:lessonId/reader"
        element={
          <LoadComponent>
            <LessonReaderPage />
          </LoadComponent>
        }
      />
      <Route
        path="/modules/:moduleId/lessons/:lessonId/materials/:materialId/reader"
        element={
          <LoadComponent>
            <AuxiliaryMaterialReaderPage />
          </LoadComponent>
        }
      />
    </Routes>
  );
};
