import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Combobox } from '@headlessui/react';

import { translations } from '@/locales';
import { HighlightResult, SearchType } from '@/domains';

import { BoldedHighlight } from '@/components/BoldedHighlight';

import { SectionTitle } from './SectionTitle';
import { orderByLessonDisplayNumber } from '@/services/lesson';

interface Props {
  data: HighlightResult[];
  searchTerm: string;
  showSectionTitle: boolean;
}

export const HighlightResults: React.FC<Props> = ({ data, searchTerm, showSectionTitle }) => (
  <React.Fragment>
    {showSectionTitle && <SectionTitle type={SearchType.Highlight} />}

    {orderByLessonDisplayNumber(data, (item) => item).map((item, index) => (
      <Combobox.Option
        key={index}
        value={item}
        className={({ active }) =>
          classnames('py-1 px-6 text-gray-800 cursor-pointer hover:bg-gray-100 transition', { 'bg-gray-100': active })
        }
      >
        <BoldedHighlight text={item.content} term={searchTerm} />

        <span className="text-gray px-1">
          (<FormattedMessage id={translations.misc.lesson} values={{ number: item.displayedNumber }} />)
        </span>
      </Combobox.Option>
    ))}
  </React.Fragment>
);
