import {
  IEngineEventListener,
  IEngineEventTypeMap,
  IReaderView,
  IReaderViewAnnotationLayer
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

export interface IAnnotationLayer {
  subscribeToEvent<E extends keyof IEngineEventTypeMap>(
    type: E,
    callback: IEngineEventListener<IEngineEventTypeMap[E]>
  ): () => void;
  unloadAll(): void;
}

export class AnnotationLayer<T> implements IAnnotationLayer {
  protected readonly annotationLayer: IReaderViewAnnotationLayer<T>;

  constructor(name: string, protected readonly readerView: IReaderView) {
    this.annotationLayer = this.readerView.createAnnotationLayer(name);
  }

  public subscribeToEvent<T extends keyof IEngineEventTypeMap>(
    type: T,
    callback: IEngineEventListener<IEngineEventTypeMap[T]>
  ): () => void {
    this.annotationLayer.addEngineEventListener(type, callback);

    return () => this.annotationLayer.removeEngineEventListener(type, callback);
  }

  public unloadAll(): void {
    this.annotationLayer.destroyAllAnnotations();
  }
}
