import {
  Content,
  SupplementLessonModule,
  SupplementLessonModuleContentSummary,
  Tenant,
  SupplementLessonModuleLessons
} from '@/domains';
import { ContentLanguage } from '@/domains/content-language';
import { Header } from '@/services/network/http';
import { authorizedApi } from '@/services/network/authorized-api';

export interface GetSupplementLessonsModulesOptions {
  language: ContentLanguage;
  tenant: Tenant;
}

export interface GetModuleLessonsOptions {
  language: ContentLanguage;
  moduleId: string;
  tenant: Tenant;
}

export const getSupplementLessonsModules = ({ language, tenant }: GetSupplementLessonsModulesOptions) =>
  authorizedApi
    .get<Content<SupplementLessonModule>>(
      `/v1/supplement-lesson-modules`,
      { tenant },
      { headers: { [Header.AcceptLanguage]: language } }
    )
    .then(reduceOnContentSummary);

export const getModuleLessons = ({ language, moduleId, tenant }: GetModuleLessonsOptions) =>
  authorizedApi.get<SupplementLessonModuleLessons>(
    `/v1/supplement-lesson-modules/${moduleId}`,
    { tenant },
    { headers: { [Header.AcceptLanguage]: language } }
  );

const reduceOnContentSummary = ({ content }: Content<SupplementLessonModule>) => ({
  available: content.filter(
    (lessonsModule) => lessonsModule.contentSummary === SupplementLessonModuleContentSummary.HasAvailableLessons
  ),
  upcoming: content.filter(
    (lessonsModule) => lessonsModule.contentSummary === SupplementLessonModuleContentSummary.NoAvailableLessons
  )
});
