import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import { HttpError } from '@/services/network/http';
import { translations } from '@/locales';

interface Props {
  error: HttpError<any> | undefined | null;
  className?: string;
}

export const SubmitError: React.FC<Props> = ({ error, children, className }) => {
  const intl = useIntl();

  if (!error) return null;

  let validErrors: string[] = [];

  if (error.isAxiosError && error.response?.status === 403)
    validErrors = [intl.formatMessage({ id: translations.errors.api.forbidden })];
  else {
    const errors: string[] =
      error.isAxiosError && error.response?.data?.errors?.length
        ? error.response.data.errors.map(
            ({ code }: { code: 'forbidden' }) =>
              !!translations.errors.api[code] && intl.formatMessage({ id: translations.errors.api[code] })
          )
        : [error.message];

    validErrors = errors.filter((error) => !!error);
  }

  return (
    <React.Fragment>
      <div className={classnames('inline-block p-2 text-left rounded-2xl text-red', className)}>
        {validErrors.length > 0
          ? validErrors.map((error, index) => <div key={index}>{error}</div>)
          : intl.formatMessage({ id: translations.errors.custom.unknown })}
      </div>

      {children}
    </React.Fragment>
  );
};
