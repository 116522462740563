import { Language } from '@/constants';
import { Tenant } from './tenant';

export enum SearchType {
  Glossary = 'glossary',
  Dictionary = 'dictionary',
  LessonContent = 'lesson_content',
  AuxiliaryMaterial = 'auxiliary_material',
  GuidedMeditation = 'guided_meditation',
  BlogPost = 'blog_post',
  Highlight = 'highlight'
}

export interface GlossaryResult {
  type: SearchType.Glossary;
  content: string;
  id: number;
  language: Language;
  phoneticSpelling?: string;
  definitions: string[];
}

export interface DictionaryResult {
  type: SearchType.Dictionary;
  content: string;
  language: Language;
  phoneticSpelling?: string;
  definitions: string[];
}

export interface HighlightResult {
  type: SearchType.Highlight;
  content: string;
  lessonId: string;
  displayedNumber: string;
  userId: string;
  locator: string;
  tenant: Tenant;
  language: Language;
}

export interface LessonResult {
  type: SearchType.LessonContent;
  content: string;
  lessonId: string;
  displayedNumber: string;
  locator?: string;
  tenant: Tenant;
  language: Language;
}

export interface AuxiliaryMaterialResult {
  type: SearchType.AuxiliaryMaterial;
  content: string;
  lessonId: string;
  displayedNumber: string;
  materialId: string;
  tenant: Tenant;
  language: Language;
}

export interface GuidedMeditationResult {
  type: SearchType.GuidedMeditation;
  content: string;
  meditationId: string;
  tenant: Tenant;
  language: Language;
}

export interface NewsResult {
  type: SearchType.BlogPost;
  content: string;
  url: string;
  language: Language;
}

export interface SearchResult {
  glossary?: GlossaryResult[];
  dictionary?: DictionaryResult[];
  highlights?: HighlightResult[];
  lessons?: LessonResult[];
  auxiliaryMaterials?: AuxiliaryMaterialResult[];
  guidedMeditations?: GuidedMeditationResult[];
  news?: NewsResult[];
}
