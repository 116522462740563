import React from 'react';
import { useLocation } from 'react-router';

export const ScrollToTop: React.FC = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <React.Fragment>{children}</React.Fragment>;
};
