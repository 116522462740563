import useSWR from 'swr';

import { getLessons } from '@/services/api';
import { LessonType, MaterialType } from '@/domains';

import { TenantContainer } from '@/containers/TenantContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { SessionContainer } from '@/containers/SessionContainer';

export const useLoadLessons = (o: { type?: LessonType; materialType?: MaterialType }) => {
  const { tenant } = TenantContainer.useContainer();
  const { language } = LanguageContainer.useContainer();
  const { preferences } = SessionContainer.useContainer();

  return useSWR(
    { key: `getLessons`, ...o, language, tenant, contentLanguage: preferences.contentLanguage },
    ({ key, contentLanguage, ...o }) => getLessons(o).then(({ content }) => content),
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  );
};
