import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { groupByLesson } from '@/services';
import { deleteBookmark, getBookmarks, getLessonById } from '@/services/api';

import { PageTitle } from '@/components/PageTitle';
import { Loading, LoadingOverlay, LoadingCenter, LoadingIndicator } from '@/components/Loading';
import { LessonGroup } from '@/components/LessonGroup';
import { EmptyPlaceholder } from '@/components/EmptyPlaceholder';
import { BookmarkListItem } from '@/components/BookmarkListItem';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { LessonFilterSimpleSelect } from '@/components/Select/LessonFilterSelect';
import { Modal, useModal } from '@/components/Modal';
import { ConfirmationModal } from '@/components/Modals/ConfirmationModal';

const text = translations.pages.forMe.annotations.bookmarks;

export const BookmarksPage: React.FC = () => {
  const navigate = useNavigate();
  const { language } = LanguageContainer.useContainer();
  const [selectedLessonId, setSelectedLessonId] = React.useState<string | undefined>();

  const {
    data: items = [],
    isValidating: loading,
    mutate: reload
  } = useSWR({ key: `getBookmarks` }, () => getBookmarks({ order: 'ascending-by-location' }), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const lessonSelectItems = groupByLesson(items).map(({ lesson: { id, title, displayedNumber } }) => ({
    id,
    title,
    displayedNumber
  }));

  const bookmarks = React.useMemo(() => {
    const filteredByColor = items?.filter((item) => (!selectedLessonId ? true : item.lesson.id === selectedLessonId));

    const groupedByLesson = groupByLesson(filteredByColor ?? []);

    return groupedByLesson;
  }, [items, selectedLessonId]);

  const modal = useModal(ConfirmationModal);

  return (
    <React.Fragment>
      <PageTitle id={text.helmet} />

      {!!lessonSelectItems.length && (
        <LessonFilterSimpleSelect
          value={selectedLessonId}
          items={lessonSelectItems}
          onChange={setSelectedLessonId}
          className="w-1/4"
        />
      )}

      <ul className="relative min-h-[10rem]">
        <Loading visible={!bookmarks.length && loading}>
          <LoadingOverlay className="z-30" />

          <LoadingCenter className="z-30">
            <LoadingIndicator />
          </LoadingCenter>
        </Loading>

        {!loading && !bookmarks.length && (
          <EmptyPlaceholder className="mt-8">
            <FormattedMessage id={text.empty} />
          </EmptyPlaceholder>
        )}

        <Modal {...modal.props} />

        {bookmarks.map((group) => (
          <LessonGroup key={group.lesson.id} className="mt-8" {...{ group }}>
            {({ items }) => (
              <React.Fragment>
                {items.map((item) => (
                  <BookmarkListItem
                    key={item.id}
                    bookmark={item}
                    className="py-2.5"
                    onClick={() =>
                      getLessonById({ lessonId: item.lesson.id, language }).then((lesson) => {
                        return navigate(
                          urls.getLessonUrl({ lessonType: lesson.type, lessonId: lesson.id }) + '/reader',
                          urls.getReaderParams({ selector: item.location })
                        );
                      })
                    }
                    onDelete={() => modal.open(() => deleteBookmark(item.id).then(() => reload()))}
                  />
                ))}
              </React.Fragment>
            )}
          </LessonGroup>
        ))}
      </ul>
    </React.Fragment>
  );
};
