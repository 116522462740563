import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { DictionaryResult } from '@/domains';

interface Props {
  data: DictionaryResult[];
}

export const DictionaryResults: React.FC<Props> = ({ data }) => (
  <div>
    <div className="text-title mb-2 text-scarlet">
      <FormattedMessage id={translations.modals.learnMoreModal.fromDictionary} />
    </div>

    <div className="space-y-3">
      {data.map((item, index) => (
        <div key={index}>
          <span className="font-semibold">{item.content}</span>

          <span className="text-gray-600">&nbsp;{item.phoneticSpelling}</span>

          <div className="space-y-1">
            {item.definitions.map((definition, index) => (
              <div key={index}> {definition}</div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
);
