import React from 'react';

import { Icon } from '@/components/Icon';
import { Reader } from '@/components/Reader/containers/ReaderContainer';

import { ReaderHeaderButton } from '../ReaderHeaderButton';

export const AudioButton: React.FC = () => {
  const { audiobook, loading } = Reader.useContainer();

  return (
    <ReaderHeaderButton
      is="button"
      active={audiobook.on}
      disabled={audiobook.disabled || loading}
      onClick={audiobook.toggle}
    >
      <Icon type="headphones" />
    </ReaderHeaderButton>
  );
};
