import React from 'react';

import { MediaType, IRandomAccessDataSource } from '@colibrio/colibrio-reader-framework/colibrio-core-io-base';
import {
  ISyncMediaTtsTimelineBuilderOptions,
  ReadingSystemEngine
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-engine';
import { EpubOcfResourceProvider } from '@colibrio/colibrio-reader-framework/colibrio-core-publication-epub';
import { IPublication } from '@colibrio/colibrio-reader-framework/colibrio-core-publication-base';
import {
  IEngineEventListener,
  IEngineEventTypeMap,
  IPageProgressionTimelineEngineEvent,
  IReaderPublication,
  IReaderView,
  IReaderViewAnnotationLayer,
  IReadingSessionOptions,
  NavigationCollectionType,
  SyncMediaFormat
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import { EpubFormatAdapter } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub';
import { FlipBookRenderer } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-renderer';

import { TableOfContentsEntry } from '@/domains';
import { COLIBRIO_LICENSE_API_KEY } from '@/config';

import { toTableOfContentsEntry } from '@/components/Reader/converter';

import { ReaderProgress } from './reader-progress';
import { IMediaService, MediaService } from './media-service';
import { EncryptedHttpDataSource } from './encrypted-http-data-source';
import { getMediaTypeFromPublication, isEpubPublication, isPdfPublication } from './utils';
import { HttpDataSource } from './http-data-source';

export interface LoadOptions {
  url: string;
  audioEnabled: boolean;
  selector?: string;
  encryption?: { key: string; iv: string };
  session: IReadingSessionOptions;
}

export interface IReaderService {
  view: IReaderView;
  mediaService?: IMediaService;
  getTableOfContents: () => Promise<TableOfContentsEntry[]>;
  load(options: LoadOptions): Promise<void>;
  goToPreviousPage(): Promise<void>;
  goToNextPage(): Promise<void>;
  goToPage(page: number): Promise<void>;
  goToSelector(selector: string): Promise<void>;
  updateFontScale(scale: number): void;
  subscribeToProgressChange(callback: (progress: ReaderProgress) => void): () => void;
  subscribeToEvent<T extends keyof IEngineEventTypeMap>(
    type: T,
    callback: IEngineEventListener<IEngineEventTypeMap[T]>
  ): () => void;
  destroy(): void;
}

export class ReaderService implements IReaderService {
  public mediaService?: IMediaService;

  public readonly view: IReaderView;
  private readonly engine: ReadingSystemEngine;

  private readonly cache = new Map<string, IReaderPublication>();

  constructor(private ref: React.RefObject<HTMLDivElement>) {
    this.engine = new ReadingSystemEngine({ licenseApiKey: COLIBRIO_LICENSE_API_KEY });

    this.engine.addFormatAdapter(new EpubFormatAdapter());

    this.view = this.engine.createReaderView();

    // hides/disables the loading indicator
    this.view.setContentOnLoading('');

    this.view.addRenderer(
      new FlipBookRenderer({
        ignoreAspectRatio: true,
        showRendererBackgroundShadow: false,
        showPageTurnShadow: false,
        showPageFoldShadow: false
      })
    );
  }

  public async getTableOfContents(): Promise<TableOfContentsEntry[]> {
    const readerPublication = this.view.getReaderPublications()[0];

    if (!readerPublication) return [];

    const navigation = await readerPublication.fetchPublicationNavigation();

    const collection = navigation
      .getNavigationCollections()
      .find((collection) => collection.getType() === NavigationCollectionType.TOC);

    if (!collection) return [];

    return collection
      .getChildren()
      .map(toTableOfContentsEntry)
      .filter((item): item is TableOfContentsEntry => !!item);
  }

  public subscribeToEvent<T extends keyof IEngineEventTypeMap>(
    type: T,
    callback: IEngineEventListener<IEngineEventTypeMap[T]>
  ): () => void {
    this.engine.addEngineEventListener(type, callback);

    return () => this.engine.removeEngineEventListener(type, callback);
  }

  private focus = async () => {
    await this.view
      .focusOnReadingPosition({
        focusOnPageContainer: true,
        focusOnPageBodyElement: false,
        focusNearContentLocation: false
      })
      .catch(() => null);
  };

  public async goToPreviousPage() {
    if (!this.view.canPerformPrevious()) return;

    await this.view.previous();
    await this.focus();
  }

  public async goToNextPage() {
    if (!this.view.canPerformNext()) return;

    await this.view.next();
    await this.focus();
  }

  public async goToPage(page: number) {
    const timeline = this.view.getPageProgressionTimeline();

    if (!timeline) return;

    const locator = await timeline.fetchLocatorFromPageIndex(page);

    await this.view.goTo(locator);
    await this.focus();
  }

  public async goToSelector(selector: string) {
    const publication = this.view.getReaderPublications()[0];

    if (!publication) return;

    await this.view.goTo({ sourceUrl: publication.getDefaultLocatorUrl(), selectors: [selector] });
    await this.focus();
  }

  protected async createDataSource(options: LoadOptions) {
    const o = await HttpDataSource.getOptionsFromUrl(options.url);

    if (!options.encryption) return new HttpDataSource(o);

    return new EncryptedHttpDataSource({ ...o, ...options.encryption });
  }

  public async load(options: LoadOptions) {
    const selector = this.view.getReadingPosition()?.getLocator()?.getSelectors()[0]?.toString() ?? options.selector;

    const dataSource = await this.createDataSource(options);

    const readerPublication = await this.getPublicationByUrl(options.url, dataSource, options.session);

    this.view.setReaderDocuments(readerPublication.getSpine());

    this.mediaService?.destroy();
    this.mediaService = options.audioEnabled ? await this.createMediaPlayer(readerPublication) : undefined;

    this.renderToViewport();

    if (!selector) return this.view.goToStart().then(() => this.focus());

    await this.goToSelector(selector);
  }

  public subscribeToProgressChange(callback: (progress: ReaderProgress) => void): () => void {
    const handler = ({ pageProgressionTimeline }: IPageProgressionTimelineEngineEvent) => {
      const visibleRange = pageProgressionTimeline.getVisibleTimelineRange();

      callback({ current: visibleRange?.end.pageIndex ?? 0, total: pageProgressionTimeline.getTotalNumberOfPages() });
    };

    this.engine.addEngineEventListener('pageProgressionTimelineVisibleRangeChanged', handler);

    return () => this.engine.removeEngineEventListener('pageProgressionTimelineVisibleRangeChanged', handler);
  }

  public updateFontScale(scale: number) {
    const { publicationStyleOptions } = this.view.getOptions() ?? {};

    // The scale factor can move between 0.5 - 3
    const boundedValue = Math.min(Math.max(scale, 0.5), 3);

    // Make sure the value is a finite number (e.g. not 0.9644444444444444) because it crashes the SDK
    const finiteValue = +boundedValue.toFixed(2);

    this.view.setOptions({
      publicationStyleOptions: {
        ...publicationStyleOptions,
        fontSizeScaleFactor: finiteValue
      }
    });
  }

  public destroy() {
    this.engine.destroy();
  }

  private async getPublicationByUrl(url: string, dataSource: HttpDataSource<any>, session: IReadingSessionOptions) {
    const cached = this.cache.get(url);

    if (cached) return cached;

    const publication = await this.createPublicationFromDataSource(dataSource, dataSource.getType());

    const readerPublication = await this.engine.loadPublication(
      publication,
      {
        clipboardOptions: { allowCopy: false },
        // @ts-ignore
        customPublicationCss: {
          injectionPointEnd: [`aside { display: none; }`]
        }
      },
      session
    );

    this.cache.set(url, readerPublication);

    return readerPublication;
  }

  private createPublicationFromDataSource = (
    dataSource: IRandomAccessDataSource,
    mediaType: MediaType
  ): Promise<IPublication> => {
    switch (mediaType) {
      case MediaType.APPLICATION_EPUB_ZIP:
        return EpubOcfResourceProvider.createFromRandomAccessDataSource(dataSource).then((provider) => {
          const publication = provider.getDefaultPublication();

          if (publication) return publication;

          throw new Error(`Couldn't retrieve default publication.`);
        });
      case MediaType.APPLICATION_PDF:
        return Promise.all([
          import('@colibrio/colibrio-reader-framework/colibrio-core-publication-pdf').then(({ PdfPublication }) =>
            PdfPublication.createFromRandomAccessDataSource(dataSource)
          ),
          import('@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-pdf').then(
            ({ PdfFormatAdapter }) => this.engine.addFormatAdapter(new PdfFormatAdapter())
          )
        ]).then(([pdfPublication]) => pdfPublication);
    }

    throw new Error(`Unsupported media file type: ${mediaType}`);
  };

  private async createMediaPlayer(publication: IReaderPublication) {
    const timeline = await this.createMediaTimeline(publication, this.createMediaAnnotationLayer());

    const syncMediaPlayer = this.engine.createSyncMediaPlayer(timeline);
    this.view.setSyncMediaPlayer(syncMediaPlayer);

    return new MediaService(syncMediaPlayer, () => {
      this.engine.destroySyncMediaPlayer(syncMediaPlayer);
    });
  }

  private async createMediaTimeline(
    publication: IReaderPublication,
    defaultTtsHighlightLayer?: IReaderViewAnnotationLayer
  ) {
    const options: ISyncMediaTtsTimelineBuilderOptions = { defaultTtsHighlightLayer };

    if (isPdfPublication(publication)) {
      return publication.createTtsSyncMediaTimeline(publication.getSpine(), options);
    }

    if (!isEpubPublication(publication)) {
      throw new Error(`Unknown media type ${getMediaTypeFromPublication(publication)} while creating media timeline.`);
    }

    const hasSyncMedia = publication
      .getAvailableSyncMediaFormats()
      .some((format) => format === SyncMediaFormat.EPUB_MEDIA_OVERLAY);

    if (!hasSyncMedia) return publication.createTtsSyncMediaTimeline(publication.getSpine(), options);

    const timeline = await publication.createMediaOverlaySyncMediaTimeline(this.view.getReaderDocuments(), options);

    return timeline ?? publication.createTtsSyncMediaTimeline(publication.getSpine(), options);
  }

  private createMediaAnnotationLayer() {
    const layer = this.view.createAnnotationLayer('syncmedia-tts-highlights');

    layer.setLayerOptions({
      layerStyle: {
        'mix-blend-mode': 'multiply',
        opacity: '0.3'
      }
    });

    layer.setDefaultAnnotationOptions({
      rangeStyle: {
        'background-color': '#DE6A10'
      }
    });

    return layer;
  }

  private renderToViewport() {
    if (!this.ref.current) return;

    this.view.renderTo(this.ref.current);
  }
}
