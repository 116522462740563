import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import classnames from 'classnames';

import { urls } from '@/constants';
import { LessonType } from '@/domains';
import { analytics } from '@/services/analytics';

import { Rule, usePermissions } from '@/hooks/usePermissions';

import { CookieBar } from '@/components/CookieBar';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { LoadComponent } from '@/components/LoadComponent';
import { LanguageContainer } from '@/containers/LanguageContainer';
import { ConfidentialityNotice } from '@/components/ConfidentialityNotice';

import { ContentPage } from '@/pages/ContentPage';
import { AnnotationPage } from '@/pages/ForMePage/AnnotationPage';
import { BookmarksPage, FlashcardsPage, HighlightsPage } from '@/pages/ForMePage';
import { VLDHandbookLoader, VLDHandbookPage } from '@/pages/OtherContentPage';
import { LoadingPage } from '@/pages/LoadingPage';

import { Header } from './Header';

const VLDHandbookReaderPage = React.lazy(() =>
  import('@/pages/OtherContentPage/VLDHandbookReaderPage').then((m) => ({
    default: m.VLDHandbookReaderPage
  }))
);

const readerUrls = [
  urls.getLessonUrl({ lessonType: ':lessonType' as any, lessonId: ':lessonId' }) + '/reader',
  urls.getLessonUrl({ lessonType: ':lessonType' as any, lessonId: ':lessonId' }) + '/materials/:materialId/reader',
  urls.getLessonUrl({ lessonType: LessonType.Supplement, moduleId: ':moduleId', lessonId: ':lessonId' }) + '/reader',
  urls.getLessonUrl({ lessonType: LessonType.Supplement, moduleId: ':moduleId', lessonId: ':lessonId' }) +
    '/materials/:materialId/reader',
  [urls.otherContent.root, urls.otherContent.vld.root, urls.otherContent.vld.reader].join('')
];

interface Props {
  className?: string;
}

export const AppContent: React.FC<Props> = ({ className }) => {
  const { permissions } = usePermissions();

  const { language } = LanguageContainer.useContainer();

  React.useEffect(() => {
    analytics.trackUser((user) => {
      return user.set('activated app language', language);
    });
  }, [language]);

  return (
    <div className={classnames('h-full text-gray-800', className)}>
      <Routes>
        {readerUrls.map((path) => (
          <Route key={path} {...{ path }} element={null} />
        ))}
        <Route
          path="*"
          element={
            <div className="h-16 flex-shrink-0 w-full z-header">
              <Header />
            </div>
          }
        />
      </Routes>

      <ErrorBoundary>
        <div className="relative flex-grow overflow-y-auto">
          <Routes>
            <Route path="/auth0/callback" element={<LoadingPage />} />
            <Route path="/content/*" element={<ContentPage />} />

            {permissions[Rule.ForMeView] && (
              <React.Fragment>
                <Route path={urls.catchAll(urls.annotations.root)} element={<AnnotationPage />}>
                  <Route path={urls.relative(urls.annotations.bookmarks)} element={<BookmarksPage />} />
                  <Route path={urls.relative(urls.annotations.highlights)} element={<HighlightsPage />} />
                  <Route path="*" element={<Navigate to={urls.relative(urls.annotations.bookmarks)} />} />
                </Route>
                <Route path={urls.relative(urls.flashcards)} element={<FlashcardsPage />} />
              </React.Fragment>
            )}

            {permissions[Rule.VldHandbookView] && (
              <Route path={urls.otherContent.root}>
                <Route path={urls.relative(urls.otherContent.vld.root)} element={<VLDHandbookLoader />}>
                  <Route index element={<VLDHandbookPage />} />
                  <Route
                    path={urls.relative(urls.otherContent.vld.reader)}
                    element={
                      <LoadComponent>
                        <VLDHandbookReaderPage />
                      </LoadComponent>
                    }
                  />
                </Route>
              </Route>
            )}

            <Route path="*" element={<Navigate to="/content/basic/lessons" />} />
          </Routes>

          <ConfidentialityNotice />

          <CookieBar />
        </div>
      </ErrorBoundary>
    </div>
  );
};
