import React from 'react';
import { Transition } from '@headlessui/react';
import useSWR from 'swr';

import { getRandomItemFromList } from '@/utils';
import { backupQuotes } from '@/constants';
import { getTodaysInspiration } from '@/services/api/todays-inspiration';

import { TenantContainer } from '@/containers/TenantContainer';
import { LanguageContainer } from '@/containers/LanguageContainer';

import blueWideOpen1 from '@/assets/blue-wide-open-1.svg';

export const RandomQuotePlaceholder: React.FC = () => {
  const { tenant } = TenantContainer.useContainer();

  const { language } = LanguageContainer.useContainer();

  const backupQuote = React.useMemo(() => getRandomItemFromList(backupQuotes), []);

  const { data: value = backupQuote, isValidating: loading } = useSWR(
    { key: `getTodaysInspiration`, language, tenant },
    getTodaysInspiration,
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  if (loading) return null;

  return (
    <div className="relative h-full w-full overflow-hidden">
      <Transition
        as="img"
        show={true}
        src={blueWideOpen1}
        enter="transition-all duration-1000 ease-out"
        enterFrom="opacity-0 translate-y-10"
        enterTo="opacity-1 translate-y-0"
        className="w-[36rem] h-[21.5rem] absolute -bottom-4 -right-28"
        appear
      />

      <Transition
        as="div"
        show={true}
        enter="transition-all duration-1000 ease-out"
        enterFrom="opacity-0 translate-y-16"
        enterTo="opacity-1 translate-y-0"
        className="font-semibold max-w-xl flex flex-col items-center absolute m-auto inset-x-0 top-1/2"
        appear
      >
        <blockquote className="text-headline-1 text-center text-gray-800 italic mb-5">{`“${value.quote}”`}</blockquote>

        <p className="text-gray-600 text-title">{`- ${value.quoteAuthor}`}</p>
      </Transition>
    </div>
  );
};
