import { ReadLessonSummary } from './read-lesson-summary';

export enum FlashcardColor {
  Red = 'RED',
  Blue = 'BLUE',
  Orange = 'ORANGE',
  Yellow = 'YELLOW'
}

export const FLASHCARD_COLORS = Object.values(FlashcardColor);

export interface Flashcard {
  id: string;
  content: string;
  color: FlashcardColor;
  title: string;
  createdAt: string;
  lesson: ReadLessonSummary;
}

export type FlashcardCreate = Pick<Flashcard, 'title' | 'content' | 'color'>;
