import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { LessonType } from '@/domains';

import { Icon } from '@/components/Icon';
import { PageTitle } from '@/components/PageTitle';
import { InfoMessage } from '@/components/InfoMessage';

import { useLoadLessons } from '../hooks/useLoadLessons';
import { LessonAside, LessonAsideSection } from '../components/LessonAside';
import { LessonList } from '../components/LessonList';
import { LessonListItemLink, LessonListItemProgress } from '../components/LessonListItemLink';

const text = translations.pages.lessons.kriya;

export const KriyaLessonListPage: React.FC = () => {
  const { data: lessons = [], isValidating: loading } = useLoadLessons({ type: LessonType.Kriya });

  return (
    <div className="flex h-full w-full">
      <PageTitle id={text.title} />

      <LessonAside>
        <LessonAsideSection>
          <div className="py-6 pr-[1.875rem] pl-[5.25rem]">
            <p className="text-headline-1 font-semibold mt-5 mb-1">
              <FormattedMessage id={text.title} />
            </p>
          </div>

          <LessonList loading={lessons.length === 0 && loading}>
            {lessons.map((lesson) => (
              <LessonListItemLink
                key={lesson.id}
                {...{ lesson }}
                href={urls.getLessonUrl({ lessonType: lesson.type, lessonId: lesson.id })}
              >
                <LessonListItemProgress {...{ lesson }} />
              </LessonListItemLink>
            ))}
          </LessonList>

          {!!lessons.length && (
            <div className="absolute bottom-0 right-0 left-0 p-9 z-10 bg-gradient-to-t from-white">
              <InfoMessage className="flex items-center">
                <Icon type="info" className="text-[24px] mr-3 text-scarlet" />
                <FormattedMessage id={text.info} />
              </InfoMessage>
            </div>
          )}
        </LessonAsideSection>
      </LessonAside>

      <main className="w-2/3 overflow-y-auto">
        <Outlet />
      </main>
    </div>
  );
};
