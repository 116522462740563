import { IReaderPublicationNavigationItem } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

import { TableOfContentsEntry } from '@/domains';

import { getEpubCfiFromLocator } from '@/components/Reader/services/reader-service/utils';

export const toTableOfContentsEntry = (item: IReaderPublicationNavigationItem): TableOfContentsEntry | undefined => {
  const locator = item.getLocator();
  const children = item.getChildren();

  if (!locator) return;

  const epubLocation = getEpubCfiFromLocator(locator);

  if (!epubLocation) return;

  return {
    name: item.getTextContent(),
    epubLocation,
    ...(children && {
      subEntries: children.map(toTableOfContentsEntry).filter((item): item is TableOfContentsEntry => !!item)
    })
  };
};
