import React from 'react';

import { useTrackPageTime } from '@/hooks/useTrackPageTime';

import { InstanceProps } from '@/components/Modal';
import { SessionContainer } from '@/containers/SessionContainer';

export interface Props extends InstanceProps<[]> {
  playerUrl: string;
  onTrackDuration?(duration: number): void;
}

export const VideoPlayerModal: React.FC<Props> = ({ playerUrl, onTrackDuration }) => {
  const { preferences } = SessionContainer.useContainer();

  useTrackPageTime(
    (duration) => {
      if (!playerUrl) return;

      onTrackDuration?.(duration);
    },
    [playerUrl]
  );

  return (
    <div className="w-[64rem] -mx-10 -my-8 aspect-video rounded overflow-hidden">
      {!!playerUrl && (
        <iframe
          src={`${playerUrl}&autoplay=1&quality=${preferences.videoQuality || 'auto'}`}
          className="w-full h-full"
          allowFullScreen
          allow="autoplay; fullscreen;"
        />
      )}
    </div>
  );
};
