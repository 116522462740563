import React from 'react';
import { IntlProvider } from 'react-intl';
import { useLocalStorage } from 'react-use';
import { createContainer } from 'unstated-next';

import { Language } from '@/constants';
import { getTranslationMessages } from '@/locales';
import { Tenant } from '@/domains';
import { RemoteConfigKey } from '@/services/remote-config';

import { TenantContainer } from './TenantContainer';

export const defaultLanguages: Record<Tenant, Language> = {
  [Tenant.SRF]: Language.EnglishUS,
  [Tenant.YSS]: Language.EnglishIndian
};

const useLanguage = () => {
  const { tenant, config } = TenantContainer.useContainer();

  const defaultLanguage = defaultLanguages[tenant];

  const languages = React.useMemo(
    () => [
      defaultLanguage,
      ...config[RemoteConfigKey.AppLanguages].filter((language) => language !== defaultLanguage).sort()
    ],
    [config, defaultLanguage, tenant]
  );

  const fallback = React.useCallback(
    (language: Language) => (Object.values(Language).includes(language) ? language : defaultLanguage),
    [defaultLanguage]
  );

  const [language = defaultLanguage, update] = useLocalStorage('locale', defaultLanguage, {
    raw: false,
    serializer: fallback,
    deserializer: (value) => fallback(value as Language)
  });

  return React.useMemo(() => ({ language, languages, update }), [language, languages]);
};

const container = createContainer(useLanguage);

const IntlProviderWrapper: React.FC = (props) => {
  const { language } = container.useContainer();

  return <IntlProvider {...props} locale={language} messages={getTranslationMessages(language)} />;
};

const Provider: React.FC = (props) => (
  <container.Provider>
    <IntlProviderWrapper {...props} />
  </container.Provider>
);

export const LanguageContainer = { useContainer: container.useContainer, Provider };
