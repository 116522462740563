import React from 'react';
import classnames from 'classnames';
import { Menu } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locales';

import { LessonType, SupplementLessonsView } from '@/domains';

import { Rule, usePermissions } from '@/hooks/usePermissions';
import { SessionContainer } from '@/containers/SessionContainer';

const text = translations.application.header;

export const LessonsDropdownMenu = () => {
  const { permissions } = usePermissions();

  const { preferences, authenticated } = SessionContainer.useContainer();

  const lessonDropdownItems = [
    {
      to: urls.getLessonListUrl({ lessonType: LessonType.Basic }),
      text: text.lessons.basic
    },
    ...(permissions[Rule.LessonsKriyaView]
      ? [
          {
            to: urls.getLessonListUrl({ lessonType: LessonType.Kriya }),
            text: text.lessons.kriya
          }
        ]
      : []),
    ...(permissions[Rule.LessonsSupplementView]
      ? [
          {
            to: authenticated
              ? preferences.supplementLessonsView === SupplementLessonsView.All
                ? urls.getLessonListUrl({ lessonType: LessonType.Supplement })
                : '/content/supplement/modules'
              : '/content/supplement/modules',
            text: text.lessons.supplement
          }
        ]
      : [])
  ];

  return (
    <React.Fragment>
      {lessonDropdownItems.map((item) => (
        <li key={item.text}>
          <Menu.Item
            as={NavLink}
            to={item.to}
            // @ts-ignore
            className={({ isActive }) =>
              classnames('inline-block px-4 py-3 w-full hover:bg-gray-100', { 'bg-gray-100': isActive })
            }
          >
            <FormattedMessage id={item.text} />
          </Menu.Item>
        </li>
      ))}
    </React.Fragment>
  );
};
