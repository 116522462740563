import { Content, Bookmark, AnnotationOrder } from '@/domains';

import { orderAnnotations } from '@/services/annotation';
import { authorizedApi } from '@/services/network/authorized-api';

export interface GetBookmarksOptions {
  order?: AnnotationOrder;
  lessonId?: string;
}

export interface CreateBookmarksOptions extends Omit<Bookmark, 'createdAt' | 'sortingLocation'> {}

export const getBookmarks = async ({ lessonId, order }: GetBookmarksOptions = {}) => {
  if (!lessonId) return authorizedApi.get<Content<Bookmark>>(`/v1/bookmarks`).then((r) => r.content);

  return await authorizedApi
    .get<Content<Bookmark>>(`/v1/bookmarks`, { lessonId })
    .then((r) => orderAnnotations(r.content, order));
};

export const createBookmark = async ({ id, ...options }: CreateBookmarksOptions) =>
  authorizedApi.put<Bookmark>(`/v1/bookmarks/${id}`, { ...options, createdAt: new Date() });

export const deleteBookmark = (id: string) => authorizedApi.delete(`/v1/bookmarks/${id}`).then(() => ({ id }));
