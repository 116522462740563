import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useParams, Outlet } from 'react-router';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { LessonType, MaterialType } from '@/domains';

import { Icon } from '@/components/Icon';
import { PageTitle } from '@/components/PageTitle';

import { useLoadLessons } from '../hooks/useLoadLessons';
import { LessonAside, LessonAsideSection } from '../components/LessonAside';
import { LessonList } from '../components/LessonList';
import { LessonListItemLink, LessonListItemProgress } from '../components/LessonListItemLink';

const text = translations.pages.lessons.basic;

export const BasicLessonListPage: React.FC = () => {
  const { lessonId } = useParams<{ lessonId?: string }>();
  const [type, setType] = React.useState<LessonType.Basic | LessonType.PubliclyAvailable>(LessonType.Basic);

  const { data: lessons = [], isValidating: areLessonsLoading } = useLoadLessons({
    type: LessonType.Basic,
    materialType: MaterialType.Default
  });

  const { data: introductoryLessons = [], isValidating: areIntroductoryLessonsLoading } = useLoadLessons({
    materialType: MaterialType.Welcome
  });

  React.useEffect(() => {
    if (!lessonId) return;

    const type = introductoryLessons.some(({ id }) => id === lessonId)
      ? LessonType.PubliclyAvailable
      : LessonType.Basic;

    setType(type);
  }, [introductoryLessons]);

  return (
    <div className="flex h-full w-full">
      <PageTitle id={text.title} />

      <LessonAside className={classnames({ '-translate-x-full': type === LessonType.PubliclyAvailable })}>
        <LessonAsideSection>
          {introductoryLessons.length > 0 && (
            <div
              className="py-6 pr-[1.875rem] pl-[5.25rem] border-b border-solid border-gray-200 hover:bg-gray-100 hover:cursor-pointer"
              onClick={() => setType(LessonType.PubliclyAvailable)}
            >
              <p className="text-title font-semibold mb-1">
                <FormattedMessage id={text.welcome} />
              </p>

              <p className="text-gray-600">
                <FormattedMessage id={text.overview} />
              </p>
            </div>
          )}

          <LessonList loading={lessons.length === 0 && areLessonsLoading}>
            {lessons.map((lesson) => (
              <LessonListItemLink
                key={lesson.id}
                {...{ lesson }}
                href={urls.getLessonUrl({ lessonType: lesson.type, lessonId: lesson.id })}
              >
                <LessonListItemProgress {...{ lesson }} />
              </LessonListItemLink>
            ))}
          </LessonList>
        </LessonAsideSection>

        <LessonAsideSection>
          <div className="pr-[1.875rem] pl-[5.25rem]">
            <button
              onClick={() => setType(LessonType.Basic)}
              className="flex items-center px-3 py-2 -ml-3 my-4 font-semibold rounded text-scarlet hover:text-scarlet-700 hover:bg-scarlet-50"
            >
              <Icon type="chevron-left" className="text-[20px] mr-2" />

              <div className="-mb-[2px]">
                <FormattedMessage id={text.introductory.back} />
              </div>
            </button>

            <div className="text-question font-semibold my-4">
              <FormattedMessage id={text.overview} />
            </div>
          </div>

          <LessonList loading={introductoryLessons.length === 0 && areIntroductoryLessonsLoading}>
            {introductoryLessons.map((lesson) => (
              <LessonListItemLink
                key={lesson.id}
                {...{ lesson }}
                href={urls.getLessonUrl({ lessonType: lesson.type, lessonId: lesson.id })}
                className="transition"
              />
            ))}
          </LessonList>
        </LessonAsideSection>
      </LessonAside>

      <main className="w-2/3 overflow-y-auto">
        <Outlet />
      </main>
    </div>
  );
};
