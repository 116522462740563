import { getDeviceId, identify, init, setUserId, track, Types } from '@amplitude/analytics-browser';

import { RELEASE } from '@/config';

import { EventProperties, EventType } from '../event-properties';
import { UserProperties } from '../user-properties';
import { Analytics } from '../types';
import { UserTrackerCallback } from '../user-tracker';

import { AmplitudeUserTracker } from './amplitude-user-tracker';

interface AmplitudeAnalyticsOptions {
  apiKey: string;
}

export class AmplitudeAnalytics implements Analytics {
  constructor({ apiKey }: AmplitudeAnalyticsOptions) {
    init(apiKey, undefined, { appVersion: RELEASE, transport: Types.TransportType.SendBeacon });
  }

  getCrossDomainParameters(): Record<string, string | undefined> {
    return { amp_device_id: getDeviceId() };
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E], userProperties?: Partial<UserProperties>): void {
    track({ event_type: type, event_properties: event, user_properties: userProperties });
  }

  setCurrentUser(userId?: string): void {
    return setUserId(userId as string);
  }

  trackUser(callback: UserTrackerCallback) {
    const tracker = new AmplitudeUserTracker();
    callback(tracker);
    identify(tracker.toIdentify());
  }
}
