import React from 'react';

import { Reader } from '@/components/Reader/containers/ReaderContainer';

import { useOpen } from '@/hooks/useOpen';

export const useFootnotesContainer = () => {
  const footnoteModal = useOpen();
  const { readerService } = Reader.useContainer();
  const [footnoteId, setFootnoteId] = React.useState('');

  React.useEffect(() => {
    return readerService.subscribeToEvent('navigationIntent', (event) => {
      const attributes = event.relatedEvent?.target?.nodeData?.attributes;

      if (!attributes) return;

      const isNoteref = attributes.some((item) => item.prefix === 'epub' && item.value === 'noteref');

      if (!isNoteref) return;

      const id = attributes.find((item) => item.localName === 'href')?.value?.split('#')[1];

      if (!id) return;

      event.stopPropagation();

      setFootnoteId(id);
      footnoteModal.open();
    });
  });

  return { footnoteId, footnoteModal };
};
