import { Language } from '@/constants';
import { Tenant } from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';
import { Header } from '@/services/network/http';

export const getTodaysInspiration = ({ tenant, language }: { tenant: Tenant; language: Language }) =>
  authorizedApi
    .get<{ quote: string; quoteAuthor: string }>(
      `/v1/todays-inspiration`,
      { tenant: tenant },
      { headers: { [Header.AcceptLanguage]: language } }
    )
    .then((value) => value || undefined);
