import React from 'react';
import classnames from 'classnames';

import icon from './style.css';
import style from './style.css';

export type Type = keyof typeof icon;

export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  type: Type;
}

export const Icon: React.FC<Props> = ({ className, type, ...rest }) => (
  <i className={classnames(style.icon, 'align-middle leading-none', icon[type], className)} {...rest} />
);
