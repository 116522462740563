import React from 'react';
import { useNavigate } from 'react-router';

import { TenantContainer } from '@/containers/TenantContainer';
import { Logo } from '@/components/Logo';

export const ReaderHeader: React.FC = ({ children }) => {
  const { tenant } = TenantContainer.useContainer();
  const navigate = useNavigate();

  return (
    <header className="flex items-center justify-between min-h-[5.5rem] px-[5.25rem] py-6 border-b border-solid border-gray-200">
      <div className="flex items-center space-x-4 z-10 w-full">
        <button onClick={() => navigate(-1)}>
          <Logo tenant={tenant} type="blue" />
        </button>

        {children}
      </div>
    </header>
  );
};
