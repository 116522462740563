import { Language } from './language';

export const languageToEnrollmentUrlMap: Record<Language, { basicLesson: string; supplementLesson: string }> = {
  [Language.EnglishIndian]: {
    basicLesson: 'https://yssofindia.org/yss-lessons?app=1',
    supplementLesson: 'https://yssofindia.org/supplement-lessons?app=1'
  },
  [Language.EnglishUS]: {
    basicLesson: 'https://yogananda.org/lessons',
    supplementLesson: 'https://yogananda.org/supplement-lessons'
  },
  [Language.Italian]: {
    basicLesson: 'https://yogananda.org/it/lezioni-per-lo-studio-a-casa',
    supplementLesson: 'https://yogananda.org/it/lezioni-per-lo-studio-a-casa'
  },
  [Language.Tamil]: {
    basicLesson: 'https://yssofindia.org/ta/yss-lessons',
    supplementLesson: 'https://yssofindia.org/ta/yss-lessons'
  },
  [Language.Portuguese]: {
    basicLesson: 'https://yogananda.org/lessons',
    supplementLesson: 'https://yogananda.org/supplement-lessons'
  },
  [Language.Telugu]: {
    basicLesson: 'https://yssofindia.org/te/yss-lessons',
    supplementLesson: ''
  },
  [Language.Hindi]: {
    basicLesson: 'https://yssofindia.org/hi/yss-lessons',
    supplementLesson: ''
  },
  // TODO: Different urls for es and bn?
  [Language.Spanish]: {
    basicLesson: 'https://yogananda.org/lessons',
    supplementLesson: 'https://yogananda.org/supplement-lessons'
  },
  [Language.Bengali]: {
    basicLesson: 'https://yogananda.org/lessons',
    supplementLesson: 'https://yogananda.org/supplement-lessons'
  }
};

export const languageToSettingsUrlMap: Record<
  Language,
  { privacyPolicy: string; faq: string; generalFaq: string; terms: string }
> = {
  [Language.EnglishIndian]: {
    privacyPolicy: 'https://yssofindia.org/privacy-policy?app=1',
    faq: 'https://yssofindia.org/app-faq?app=1',
    generalFaq: 'https://yssofindia.org/about/frequently-asked-questions',
    terms: 'https://yssofindia.org/terms-of-use?app=1'
  },
  [Language.EnglishUS]: {
    privacyPolicy: 'https://yogananda.org/privacy-policy',
    faq: 'https://yogananda.org/app-faq#App%20FAQs',
    generalFaq: 'https://yogananda.org/frequently-asked-questions',
    terms: 'https://yogananda.org/terms-of-service'
  },
  [Language.Italian]: {
    privacyPolicy: 'https://yogananda.org/it/privacy-policy',
    faq: 'https://yogananda.org/it/domande-frequenti#Domande%20frequenti',
    generalFaq: 'https://yogananda.org/it/risposte-alle-domande-pi%C3%B9-frequenti',
    terms: 'https://yogananda.org/it/terms-conditions'
  },
  [Language.Tamil]: {
    privacyPolicy: 'https://yssofindia.org/privacy-policy?app=1',
    faq: 'https://yssofindia.org/ta/app-faq',
    generalFaq: 'https://yssofindia.org/ta/about/frequently-asked-questions',
    terms: 'https://yssofindia.org/terms-of-use?app=1'
  },
  [Language.Portuguese]: {
    privacyPolicy: 'https://yogananda.org/privacy-policy',
    faq: 'https://yogananda.org/app-faq#App%20FAQs',
    generalFaq: 'https://yogananda.org/frequently-asked-questions',
    terms: 'https://yogananda.org/terms-of-service'
  },
  [Language.Telugu]: {
    privacyPolicy: 'https://yssofindia.org/privacy-policy?app=1',
    faq: 'https://yssofindia.org/te/app-faq',
    generalFaq: 'https://yssofindia.org/te/about/frequently-asked-questions',
    terms: 'https://yssofindia.org/terms-of-use?app=1'
  },
  [Language.Hindi]: {
    privacyPolicy: 'https://yssofindia.org/privacy-policy?app=1',
    faq: 'https://yssofindia.org/hi/app-faq',
    generalFaq: 'https://yssofindia.org/hi/about/frequently-asked-questions',
    terms: 'https://yssofindia.org/terms-of-use?app=1'
  },
  // TODO: Different urls for es and bn?
  [Language.Spanish]: {
    privacyPolicy: 'https://yogananda.org/privacy-policy',
    faq: 'https://yogananda.org/app-faq#App%20FAQs',
    generalFaq: 'https://yogananda.org/frequently-asked-questions',
    terms: 'https://yogananda.org/terms-of-service'
  },
  [Language.Bengali]: {
    privacyPolicy: 'https://yogananda.org/privacy-policy',
    faq: 'https://yogananda.org/app-faq#App%20FAQs',
    generalFaq: 'https://yogananda.org/frequently-asked-questions',
    terms: 'https://yogananda.org/terms-of-service'
  }
};

export const storeLinks = {
  appStore: 'https://apps.apple.com/us/app/srf-yss-lessons/id1380804297',
  playStore: 'https://play.google.com/store/apps/details?id=com.yoganandasrf.lessonssrf&hl=en&gl=US'
};

export const backupQuotes: { quote: string; quoteAuthor: string }[] = [
  {
    quote: 'Thy Self-realization shall blossom forth from thy soulful study.',
    quoteAuthor: 'Paramahansa Yogananda'
  },
  {
    quote: 'Read a little. Meditate more. Think of God all the time.',
    quoteAuthor: 'Paramahansa Yogananda'
  },
  {
    quote: 'You have come to earth to entertain and to be entertained.',
    quoteAuthor: 'Paramahansa Yogananda'
  }
];
