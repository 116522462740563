import React from 'react';
import classnames from 'classnames';

import { Icon } from '@/components/Icon';

export interface Props extends React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  selected: boolean;
}

export const LanguageListItem: React.FC<Props> = ({ selected, className, children, ...rest }) => (
  <li
    className={classnames(
      'flex items-center justify-between py-2 px-4 hover:cursor-pointer hover:bg-gray-100',
      className
    )}
    {...rest}
  >
    {children}

    <Icon type="check" className={classnames('text-[20px] text-scarlet', { invisible: !selected })} />
  </li>
);
