import { v4 as uuidv4 } from 'uuid';

import { Content, Flashcard, FlashcardColor, ReadLessonSummary } from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';
import { descendingByCreatedAt } from '../annotation';

export const getFlashcards = () =>
  authorizedApi.get<Content<Flashcard>>(`/v1/flashcards`).then((r) => r.content.sort(descendingByCreatedAt));

export interface CreateFlashcardOptions {
  title: string;
  content: string;
  color: FlashcardColor;
  lesson: ReadLessonSummary;
}

export const createFlashcard = (options: CreateFlashcardOptions) => {
  const id = uuidv4();

  return authorizedApi.put(`/v1/flashcards/${id}`, { ...options, createdAt: new Date() });
};

export const deleteFlashcard = (id: string) => authorizedApi.delete(`/v1/flashcards/${id}`);
