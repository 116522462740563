import React from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, useLocation } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { translations } from '@/locales';

import { HeaderMenuButtonWithArrow, HeaderMenuDropdown } from '../HeaderMenu';
import { LessonsDropdownMenu } from './LessonsDropdownMenu';

const text = translations.application.header;

export const LessonsMenu = () => {
  const location = useLocation();

  const active = !!matchPath({ path: '/content', end: false }, location.pathname);

  return (
    <Menu as="div" className="relative font-semibold">
      <HeaderMenuButtonWithArrow {...{ active }}>
        <FormattedMessage id={text.lessons.title} />
      </HeaderMenuButtonWithArrow>

      <HeaderMenuDropdown className="left-0 top-1 w-60">
        <LessonsDropdownMenu />
      </HeaderMenuDropdown>
    </Menu>
  );
};
