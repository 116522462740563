import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import noResults from '@/assets/no-results.svg';

export const NoResults = () => (
  <div className="h-[200px] pt-3 px-6 relative">
    <div className="font-semibold">
      <FormattedMessage id={translations.application.search.noResults.title} />
    </div>
    <div className="text-note text-gray pt-3">
      <FormattedMessage id={translations.application.search.noResults.subtitle} />
    </div>

    <img className="w-4/5 absolute right-6 bottom-0" src={noResults} />
  </div>
);
