import React from 'react';
import classnames from 'classnames';

import { Highlight } from '@/domains';

import { Icon } from '@/components/Icon';

export interface Props {
  highlight: Highlight;
  className?: string;
  onClick(): void;
  onDelete(): void;
}

export const HighlightListItem: React.FC<Props> = ({ highlight, className, onClick, onDelete }) => (
  <li className={classnames('text-gray-600 text-title flex', { 'hover:cursor-pointer': !!onClick }, className)}>
    <div className="flex flex-1 space-x-4" {...{ onClick }}>
      <div style={{ backgroundColor: highlight.colorCode }} className="w-1 flex-shrink-0" />
      <div>{highlight.content}</div>
    </div>

    <div className="w-1/12 flex items-center justify-end">
      <button
        onClick={onDelete}
        className="flex items-center text-red text-[1.5rem] transition hover:cursor-pointer hover:text-red-600"
      >
        <Icon type="bin" />
      </button>
    </div>
  </li>
);
