import React from 'react';
import { useLocation, useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tab } from '@headlessui/react';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { Lesson, SearchType } from '@/domains';
import {
  createBookmark,
  createFlashcard,
  createProgress,
  deleteBookmark,
  deleteHighlight,
  getBookmarks,
  getHighlights,
  upsertHighlight
} from '@/services/api';
import { analytics, convertLessonType, convertSearchType } from '@/services/analytics';

import { useTrackPageTime } from '@/hooks/useTrackPageTime';
import { useScreenName } from '@/hooks/useScreenName';
import { useReaderSession } from '@/components/Reader/hooks/useReaderSession';
import { useConsumptionDepth } from '@/components/Reader/hooks/useConsumptionDepth';
import { useHasTOC } from '@/components/Reader/hooks/useHasTOC';

import { Sidebar } from '@/components/Reader/Sidebar';
import { ReaderHeader } from '@/components/Reader/ReaderHeader';
import { ReaderView } from '@/components/Reader/ReaderView';
import { SidebarTab } from '@/components/Reader/Sidebar/SidebarTab';
import { SidebarTabList } from '@/components/Reader/Sidebar/SidebarTabList';
import { TableOfContentsTab } from '@/components/Reader/Sidebar/TableOfContentsTab';
import { ReaderForwardIframeEvents } from '@/components/Reader/ReaderForwardIframeEvents';
import { Reader as ReaderContainer } from '@/components/Reader/containers/ReaderContainer';
import { SidebarButton } from '@/components/Reader/ReaderHeader/ReaderHeaderButton/SidebarButton';
import { FontSizeButton } from '@/components/Reader/ReaderHeader/ReaderHeaderButton/FontSizeButton';
import { Loading, LoadingCenter, LoadingIndicator } from '@/components/Loading';
import { SessionContainer } from '@/containers/SessionContainer';
import { SearchBar } from '@/components/SearchBar';
import { ReaderLessonBreadcrumb } from '@/components/Reader/ReaderHeader/ReaderLessonBreadcrumb';
import { BookmarkAnnotations } from '@/components/Reader/BookmarkAnnotations';
import { HighlightAnnotations } from '@/components/Reader/HighlightAnnotations';
import { toCreateBookmark } from '@/components/Reader/converter/bookmark-converter';
import { toCreateFlashcard, toUpsertHighlight } from '@/components/Reader/converter';
import { AudioButton } from '@/components/Reader/ReaderHeader/ReaderHeaderButton/AudioButton';
import { BookmarkButton } from '@/components/Reader/ReaderHeader/ReaderHeaderButton/BookmarkButton';
import { ReaderHighlightsDialog } from '@/components/Reader/ReaderHighlightsDialog';
import { ReaderFooter } from '@/components/Reader/ReaderFooter';
import { useFootnotesContainer } from '@/components/Reader/Footnotes';
import { Modal } from '@/components/Modal';
import { FootnoteModal } from '@/components/FootnoteModal';
import { BookmarksTab } from '@/components/Reader/Sidebar/BookmarksTab';
import { HighlightsTab } from '@/components/Reader/Sidebar/HighlightsTab';

import { useLessonDisabled } from '../hooks/useLessonDisabled';
import { useLoadLesson } from '../hooks/useLoadLesson';

const text = translations.pages.reader;

const getEncryptionOptionsFromLesson = (lesson: Lesson) => {
  const keyPart = `lesson:${lesson.id}`;
  const versionPart = `version:${lesson.version}`;

  return { key: `${keyPart}:${versionPart}`, iv: `${versionPart}:${keyPart}` };
};

export interface LessonReaderWrapperProps {
  lesson: Lesson;
  listen?: boolean;
  selector?: string;
}

const LessonReaderWrapper: React.FC<LessonReaderWrapperProps> = ({ lesson, listen, selector, children }) => {
  const session = useReaderSession(lesson.id);

  return (
    <ReaderContainer.Provider
      initialState={{
        file: {
          url: lesson.epubUrl,
          audio: lesson.audioBook?.url
        },
        audiobookMode: !!listen,
        selector,
        encryption: getEncryptionOptionsFromLesson(lesson),
        session
      }}
    >
      {children}
    </ReaderContainer.Provider>
  );
};

const InteractiveLessonReaderWrapper: React.FC<LessonReaderWrapperProps> = ({ lesson, children, ...props }) => (
  <LessonReaderWrapper {...props} {...{ lesson }}>
    <BookmarkAnnotations.Provider
      initialState={{
        load: () => getBookmarks({ lessonId: lesson.id, order: 'ascending-by-location' }),
        onBookmarkCreate: (data) => createBookmark(toCreateBookmark(data, lesson)),
        onBookmarkDelete: (id) => deleteBookmark(id)
      }}
    >
      <HighlightAnnotations.Provider
        initialState={{
          load: () => getHighlights({ lessonId: lesson.id, order: 'ascending-by-location' }),
          onHighlightUpsert: (data) => upsertHighlight(toUpsertHighlight(data, lesson)),
          onHighlightDelete: (id) => deleteHighlight(id)
        }}
      >
        {children}
      </HighlightAnnotations.Provider>
    </BookmarkAnnotations.Provider>
  </LessonReaderWrapper>
);

const LessonSearchBar: React.FC<{ lesson: Lesson }> = ({ lesson }) => {
  const { readerService } = ReaderContainer.useContainer();
  const screenName = useScreenName();

  return (
    <div className="flex-1">
      <SearchBar
        {...{ lesson }}
        className="w-96 border-none bg-scarlet-50 text-gray-900 ml-auto"
        onLessonSearch={(selector) => readerService.goToSelector(selector)}
        searchCategories={[SearchType.LessonContent]}
        onSearchSuccess={({ query }) =>
          analytics.trackEvent('search lesson', { title: lesson.title, search_query: query })
        }
        onResultClick={({ query, item, type }) =>
          analytics.trackEvent('click search result', {
            screen_name: screenName,
            search_query: query,
            search_click_text: item,
            search_type: 'lesson',
            app_search_category: convertSearchType(type),
            title: lesson.title,
            lesson_number: lesson.displayedNumber
          })
        }
      />
    </div>
  );
};

interface LessonReaderProps {
  lesson: Lesson;
  readonly: boolean;
  hasBookmarks?: boolean;
  hasHighlights?: boolean;
}

const LessonReader: React.FC<LessonReaderProps> = ({ lesson, readonly, hasBookmarks, hasHighlights }) => {
  const { audiobook, readerService } = ReaderContainer.useContainer();

  const { footnoteId, footnoteModal } = useFootnotesContainer();

  const footnote = React.useMemo(() => lesson.footnotes.find((item) => item.id === footnoteId), [lesson, footnoteId]);

  const getConsumptionDepth = useConsumptionDepth(readerService);

  const hasTOC = useHasTOC(readonly ? 3 : 1);
  const hasAny = hasTOC || hasBookmarks || hasHighlights;

  useTrackPageTime(
    (duration) => {
      if (!lesson?.id) return;

      analytics.trackEvent('view lesson', {
        method: audiobook.on ? 'listen' : 'read',
        lesson_title: lesson.title,
        lesson_number: lesson.displayedNumber,
        lesson_type: convertLessonType(lesson.type),
        lesson_language: lesson.language,
        modules: lesson.supplementModules.map(({ title }) => title),
        consumption_duration: Math.round(duration / 1000),
        consumption_depth: getConsumptionDepth()
      });
    },
    [lesson?.id, getConsumptionDepth]
  );

  return (
    <div className="flex flex-col relative h-full">
      <ReaderHeader>
        <div className="w-full">
          <ReaderLessonBreadcrumb {...{ lesson }} />
        </div>

        {!readonly && <LessonSearchBar {...{ lesson }} />}

        {!readonly && !!lesson.audioBook?.url && <AudioButton />}

        <FontSizeButton />

        {!readonly && <BookmarkButton />}

        {hasAny && <SidebarButton />}
      </ReaderHeader>

      {hasAny && (
        <Sidebar>
          <Tab.Group>
            <SidebarTabList>
              {hasTOC && (
                <SidebarTab>
                  <FormattedMessage id={text.sidebar.toc} />
                </SidebarTab>
              )}

              {hasBookmarks && (
                <SidebarTab>
                  <FormattedMessage id={text.sidebar.bookmarks} />
                </SidebarTab>
              )}

              {hasHighlights && (
                <SidebarTab>
                  <FormattedMessage id={text.sidebar.highlights} />
                </SidebarTab>
              )}
            </SidebarTabList>

            <Tab.Panels>
              {hasTOC && (
                <Tab.Panel>
                  <TableOfContentsTab />
                </Tab.Panel>
              )}

              {hasBookmarks && (
                <Tab.Panel>
                  <BookmarksTab />
                </Tab.Panel>
              )}

              {hasHighlights && (
                <Tab.Panel>
                  <HighlightsTab />
                </Tab.Panel>
              )}
            </Tab.Panels>
          </Tab.Group>
        </Sidebar>
      )}

      <ReaderForwardIframeEvents />

      <ReaderView />

      {footnote && (
        <Modal
          isOpen={footnoteModal.isOpen}
          modal={FootnoteModal}
          {...{ footnote }}
          onAction={() => null}
          onClose={footnoteModal.close}
        />
      )}

      {!readonly && (
        <ReaderHighlightsDialog
          onFlashcardCreate={(values) => createFlashcard(toCreateFlashcard({ values, lesson }))}
        />
      )}

      <ReaderFooter
        onProgressChange={async ({ epubCfi, percent }) => {
          if (readonly) return;

          await createProgress({
            lessonId: lesson.id,
            readingLocationEpubCfi: epubCfi,
            completionPercentage: percent,
            language: lesson.language
          });
        }}
      />
    </div>
  );
};

const InteractiveLessonReader: React.FC<Omit<LessonReaderProps, 'hasBookmarks' | 'hasHighlights'>> = (props) => {
  const highlights = HighlightAnnotations.useContainer();
  const bookmarks = BookmarkAnnotations.useContainer();

  const hasBookmarks = !!bookmarks.list?.length;
  const hasHighlights = !!highlights.list?.length;

  return <LessonReader {...props} {...{ hasBookmarks, hasHighlights }} />;
};

export const LessonReaderPage: React.FC = () => {
  const { moduleId, lessonId } = useParams<{ moduleId: string; lessonId: string }>();
  const location = useLocation();

  const { authenticated } = SessionContainer.useContainer();
  const { data: lesson, isValidating: loading } = useLoadLesson(lessonId!, { revalidate: false });

  const { listen, selector } = (location.state ?? {}) as { listen?: boolean; selector?: string };

  const disabled = useLessonDisabled(lesson);
  const readonly = !authenticated;

  const ReaderWrapper = readonly ? LessonReaderWrapper : InteractiveLessonReaderWrapper;
  const Reader = readonly ? LessonReader : InteractiveLessonReader;

  if (!lesson && loading) {
    return (
      <Loading visible>
        <LoadingCenter>
          <LoadingIndicator />
        </LoadingCenter>
      </Loading>
    );
  }

  if (!lesson) return null;

  if (disabled) return <Navigate to={urls.getLessonUrl({ lessonType: lesson.type, moduleId, lessonId: lessonId! })} />;

  return (
    <ReaderWrapper {...{ lesson, listen, selector }}>
      <Reader {...{ lesson, readonly }} />
    </ReaderWrapper>
  );
};
