import React from 'react';
import { Outlet } from 'react-router';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { LessonType } from '@/domains';

import { PageTitle } from '@/components/PageTitle';

import { useLoadLessons } from '../hooks/useLoadLessons';
import { LessonAside, LessonAsideSection } from '../components/LessonAside';
import { LessonList } from '../components/LessonList';
import { LessonListItemLink, LessonListItemProgress } from '../components/LessonListItemLink';

const text = translations.pages.lessons.supplement;

export const SupplementLessonListPage: React.FC = () => {
  const { data: lessons = [], isValidating: loading } = useLoadLessons({ type: LessonType.Supplement });

  return (
    <div className="flex h-full w-full">
      <PageTitle id={text.title} />

      <LessonAside>
        <LessonAsideSection>
          <LessonList loading={lessons.length === 0 && loading}>
            {lessons.map((lesson) => (
              <LessonListItemLink
                key={lesson.id}
                {...{ lesson }}
                href={urls.getLessonUrl({ lessonType: lesson.type, lessonId: lesson.id })}
              >
                <LessonListItemProgress {...{ lesson }} />
              </LessonListItemLink>
            ))}
          </LessonList>
        </LessonAsideSection>
      </LessonAside>

      <main className="w-2/3 overflow-y-auto">
        <Outlet />
      </main>
    </div>
  );
};
