import React from 'react';
import classnames from 'classnames';

import { Icon } from '@/components/Icon';

export interface Props
  extends Pick<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'className' | 'onClick'> {
  color?: string;
  gradient?: string;
  className?: string;
  selected?: boolean;
}

export const ColoredDot: React.FC<Props> = ({ color, gradient, className, selected, onClick }) => {
  return (
    <div className={classnames('relative group', className)} {...{ onClick }}>
      <div
        className={classnames('absolute inset-0 opacity-30 rounded-full cursor-pointer group-hover:visible', gradient, {
          invisible: !selected
        })}
        {...(color && { style: { backgroundColor: color } })}
      />

      <div
        className={classnames(
          'absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex justify-center items-center h-2/3 w-2/3 min-w-[1.5rem] rounded-full hover:cursor-pointer',
          gradient
        )}
        {...(color && { style: { backgroundColor: color } })}
      >
        {color && <Icon type="close" className={classnames('font-bold transition', { hidden: !selected })} />}
      </div>
    </div>
  );
};
