import React from 'react';
import { Tab } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';
import { useOutletContext } from 'react-router';

import { VLDHandbook } from '@/domains';
import { translations } from '@/locales';

import { useReaderSession } from '@/components/Reader/hooks/useReaderSession';
import { useHasTOC } from '@/components/Reader/hooks/useHasTOC';

import { Sidebar } from '@/components/Reader/Sidebar';
import { ReaderView } from '@/components/Reader/ReaderView';
import { ReaderFooter } from '@/components/Reader/ReaderFooter';
import { ReaderHeader } from '@/components/Reader/ReaderHeader';
import { SidebarTab } from '@/components/Reader/Sidebar/SidebarTab';
import { SidebarTabList } from '@/components/Reader/Sidebar/SidebarTabList';
import { TableOfContentsTab } from '@/components/Reader/Sidebar/TableOfContentsTab';
import { ReaderForwardIframeEvents } from '@/components/Reader/ReaderForwardIframeEvents';
import { Reader as ReaderContainer } from '@/components/Reader/containers/ReaderContainer';
import { SidebarButton } from '@/components/Reader/ReaderHeader/ReaderHeaderButton/SidebarButton';
import { FontSizeButton } from '@/components/Reader/ReaderHeader/ReaderHeaderButton/FontSizeButton';

const text = translations.pages.reader;

const VLDHandbookReader: React.FC = () => {
  const hasTOC = useHasTOC(3);

  return (
    <div className="flex flex-col relative h-full">
      <ReaderHeader>
        <div className="w-full" />

        <FontSizeButton />

        {!!hasTOC && <SidebarButton />}
      </ReaderHeader>

      {!!hasTOC && (
        <Sidebar>
          <Tab.Group>
            <SidebarTabList>
              <SidebarTab>
                <FormattedMessage id={text.sidebar.toc} />
              </SidebarTab>
            </SidebarTabList>

            <Tab.Panels>
              <Tab.Panel>
                <TableOfContentsTab />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Sidebar>
      )}

      <ReaderForwardIframeEvents />

      <ReaderView />

      <ReaderFooter />
    </div>
  );
};

export const VLDHandbookReaderPage: React.FC = () => {
  const value = useOutletContext<VLDHandbook>();
  const session = useReaderSession(value.id);

  return (
    <ReaderContainer.Provider initialState={{ file: { url: value.epubUrl }, session }}>
      <VLDHandbookReader />
    </ReaderContainer.Provider>
  );
};
