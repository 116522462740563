import React from 'react';

import type { IReaderService } from '../services';

export const useConsumptionDepth = (reader: IReaderService) => {
  const progress = React.useRef({ current: 0, total: 1 });

  React.useEffect(
    () =>
      reader.subscribeToProgressChange((value) => {
        progress.current = value;
      }),
    [reader]
  );

  return React.useCallback(() => {
    const percent = Math.min(Math.max((progress.current.current / progress.current.total) * 100, 0), 100);
    return Math.round(percent);
  }, [progress]);
};
