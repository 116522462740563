import { stringify } from 'query-string';

import { API } from '@/config';

import { HttpRequestConfig, HttpError, HttpResponse, Header } from './http';
import { ApiClient } from './api';

type PromiseCallback = () => Promise<any>;

interface AuthorizedHttpRequestConfig extends HttpRequestConfig {
  refreshRetries?: number;
}

interface AuthorizedApiCallbackOptions {
  onUnauthorized: PromiseCallback;
  onAccessTokenExpired: PromiseCallback;
}

export class AuthorizedApiClient<
  C extends AuthorizedHttpRequestConfig = AuthorizedHttpRequestConfig
> extends ApiClient<C> {
  private promise: Promise<any> | null = null;
  private options: AuthorizedApiCallbackOptions | null = null;
  public token: string | null = null;

  constructor(options: C) {
    super(options);

    this.client.interceptors.request.use(this.attachToken);
    this.client.interceptors.response.use(undefined, this.retryAfterRefresh);
  }

  configure(options: AuthorizedApiCallbackOptions) {
    this.options = options;
  }

  private attachToken = (config: AuthorizedHttpRequestConfig): AuthorizedHttpRequestConfig => {
    if (!this.token) return config;

    return { ...config, headers: { ...config.headers, [Header.Authorization]: `Bearer ${this.token}` } };
  };

  private doesRequireRefresh = ({ config, status, data }: HttpResponse<any, AuthorizedHttpRequestConfig>) => {
    if (!config.refreshRetries || config.refreshRetries < 2) return false;

    return status === 401 || (status === 403 && data.message === 'Forbidden' && !data.errorMessage);
  };

  private retryAfterRefresh = (error: HttpError<any, C>) => {
    if (error.isAxiosError && error.response && this.doesRequireRefresh(error.response)) {
      return this.refresh()
        .catch((error) => {
          if (this.options) this.options.onUnauthorized();

          throw error;
        })
        .then(() => this.client({ ...error.config, refreshRetries: (error.config.refreshRetries ?? 0) - 1 }));
    }

    throw error;
  };

  private refresh(): Promise<any> {
    if (this.promise == null) {
      if (!this.options) throw new Error(`Token expiration strategy is not set.`);

      this.promise = this.options
        .onAccessTokenExpired()
        .then((data) => {
          this.promise = null;
          return data;
        })
        .catch((error) => {
          this.promise = null;
          throw error;
        });
    }

    return this.promise;
  }
}

export const authorizedApi = new AuthorizedApiClient<AuthorizedHttpRequestConfig>({
  baseURL: API,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: (params) => stringify(params, { skipNull: true }),
  sentry: { ignoreStatus: [401] },
  refreshRetries: 3
});
