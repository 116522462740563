import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { LanguageContainer } from '@/containers/LanguageContainer';

import { LanguageListItem } from './LanguageListItem';
import { BackToViewButton } from './BackToViewButton';

export interface Props {
  onBack: () => void;
}

export const ApplicationLanguageSelectionView: React.FC<Props> = ({ onBack }) => {
  const { language: currentLanguage, languages, update } = LanguageContainer.useContainer();

  return (
    <div>
      <BackToViewButton onClick={onBack}>
        <FormattedMessage id={translations.misc.mainMenu} />
      </BackToViewButton>

      <ul>
        {languages.map((language) => (
          <LanguageListItem key={language} selected={currentLanguage === language} onClick={() => update(language)}>
            <FormattedMessage id={translations.i18n.languages[language]} />
          </LanguageListItem>
        ))}
      </ul>
    </div>
  );
};
