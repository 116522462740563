import React from 'react';

import { Icon } from '@/components/Icon';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const OpenViewButton: React.FC<Props> = ({ children, ...rest }) => (
  <button
    className="inline-flex font-semibold w-full items-center justify-between px-4 py-3 hover:bg-gray-100 text-left"
    type="button"
    {...rest}
  >
    {children}

    <Icon type="chevron-right" className="text-[20px]" />
  </button>
);
