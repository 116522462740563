import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locales';

import { Select, Props as SimpleSelectProps } from './Select';

interface BaseLesson {
  id: string;
  title: string;
  displayedNumber: string;
}

export interface Props<T> extends Omit<SimpleSelectProps, 'items'> {
  items?: T[];
}

export const LessonFilterSimpleSelect = <T extends BaseLesson>({
  value,
  items: lessons,
  onChange,
  ...rest
}: Props<T>) => {
  const intl = useIntl();

  const items = !lessons?.length
    ? [{ name: intl.formatMessage({ id: translations.misc.allLessons }) }]
    : [
        { name: intl.formatMessage({ id: translations.misc.allLessons }) },
        ...lessons.map(({ id: value, title, displayedNumber }) => ({
          value,
          name: intl.formatMessage({ id: translations.misc.lessonNumberAndTitle }, { number: displayedNumber, title })
        }))
      ];

  return <Select {...{ value, items, onChange, ...rest }} />;
};
