import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { Auth0Provider, Auth0ProviderOptions, useAuth0, User } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

import { urls } from '@/constants';
import { analytics } from '@/services/analytics';
import { LessonType, Me, Tenant, UserPreferences } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';
import { getMe, updateUserPreferences } from '@/services/api/user';

import { useLogout } from '@/hooks/useLogout';

import { TenantContainer } from './TenantContainer';
import { LanguageContainer } from './LanguageContainer';

interface State {
  loading: boolean;
  preferences: UserPreferences & { loading: boolean };
  me?: Me;
}

const useAuth0Redirect = (fallbackUrl: string) => {
  const { handleRedirectCallback } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    handleRedirectCallback()
      .then((state) => state?.appState as string | undefined)
      .then((redirectUrl) => {
        const url = redirectUrl ?? fallbackUrl;
        return navigate(url);
      })
      .catch(() => null);
  }, []);
};

const use3rdPartyUserUpdater = (user?: Me) => {
  React.useEffect(() => {
    analytics.setCurrentUser(user?.id ?? undefined);
    Sentry.setUser(user ? { id: `${user.id}`, email: user.emailAddress } : null);
  }, [user]);
};

const useApiAccessTokenRefresher = () => {
  const { getAccessTokenSilently } = useAuth0();
  const onUnauthorized = useLogout('forced');

  React.useEffect(() => {
    authorizedApi.configure({
      onAccessTokenExpired: () =>
        getAccessTokenSilently().then((accessToken) => {
          authorizedApi.token = accessToken;
          return accessToken;
        }),
      onUnauthorized
    });
  }, [getAccessTokenSilently, onUnauthorized]);
};

interface Auth0User extends User {
  'https://accounts.yogananda.org/user_type': 'srf' | 'yss';
}

const getUserTenant = (user: Auth0User) => {
  return user['https://accounts.yogananda.org/user_type'] === 'yss' ? Tenant.YSS : Tenant.SRF;
};

const useRedirectBasedOnTenant = () => {
  const { tenant, redirectByTenant } = TenantContainer.useContainer();
  const { user } = useAuth0<Auth0User>();

  React.useEffect(() => {
    if (!user) return;

    const userTenant = getUserTenant(user);

    if (userTenant != tenant) redirectByTenant(userTenant);
  }, [user, tenant]);
};

const useSession = () => {
  const { loading: tenantLoading } = TenantContainer.useContainer();
  const { language } = LanguageContainer.useContainer();
  const { isLoading: auth0Loading, isAuthenticated: authenticated, getAccessTokenSilently } = useAuth0<Auth0User>();

  const [session, setSession] = React.useState<State>({
    loading: true,
    preferences: { contentLanguage: language, loading: false }
  });

  const loadMe = React.useCallback(() => {
    return getMe(language)
      .then(({ preferences, ...me }) => {
        setSession({
          loading: false,
          me,
          preferences: { ...preferences, contentLanguage: preferences.contentLanguage ?? language, loading: false }
        });
      })
      .catch(() => setSession((previous) => ({ ...previous, loading: false })));
  }, [language, setSession]);

  const updatePreferences = React.useCallback(
    async (preferences: UserPreferences) => {
      setSession((previous) => ({ ...previous, preferences: { ...previous.preferences, loading: true } }));

      if (!authenticated) {
        setSession((session) => ({ ...session, preferences: { ...session.preferences, ...preferences } }));
        return;
      }

      await updateUserPreferences(preferences)
        .then((preferences) =>
          setSession((session) => ({
            ...session,
            preferences: { ...session.preferences, ...preferences, loading: false }
          }))
        )
        .then(() => {
          if (!preferences.contentLanguage) return;
          return loadMe();
        })
        .catch(() =>
          setSession((session) => ({
            ...session,
            preferences: { ...session.preferences, loading: false }
          }))
        );
    },
    [authenticated, loadMe]
  );

  useAuth0Redirect(urls.getLessonListUrl({ lessonType: LessonType.Basic }));
  use3rdPartyUserUpdater(session.me);
  useApiAccessTokenRefresher();
  useRedirectBasedOnTenant();

  React.useEffect(() => {
    getAccessTokenSilently()
      .then((accessToken) => {
        authorizedApi.token = accessToken;
      })
      .then(loadMe)
      .catch(() => setSession((previous) => ({ ...previous, loading: false })));
  }, [getAccessTokenSilently, loadMe, setSession]);

  return React.useMemo(
    () => ({
      loading: auth0Loading || session.loading || tenantLoading,
      authenticated,
      preferences: session.preferences,
      me: session.me,
      updatePreferences
    }),
    [auth0Loading, authenticated, session.loading, session.preferences, session.me, tenantLoading, updatePreferences]
  );
};

const container = createContainer(useSession);

const Provider = ({ children, ...props }: React.PropsWithChildren<Auth0ProviderOptions>) => {
  const { language } = LanguageContainer.useContainer();

  const dynamicProps = {
    ...analytics.getCrossDomainParameters(),
    ui_locales: language,
    language,
    redirectUri: `${window.origin}/auth0/callback`
  };

  return (
    <Auth0Provider {...dynamicProps} {...props}>
      <container.Provider>{children}</container.Provider>
    </Auth0Provider>
  );
};

export const SessionContainer = { Provider, useContainer: container.useContainer };
