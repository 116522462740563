import { Tenant, VLDHandbook, ContentLanguage } from '@/domains';
import { Header } from '@/services/network/http';
import { authorizedApi } from '@/services/network/authorized-api';

export interface GetVLDHandbookOptions {
  tenant: Tenant;
  language: ContentLanguage;
}

export const getVLDHandbook = ({ tenant, language }: GetVLDHandbookOptions) =>
  authorizedApi.get<VLDHandbook>(
    `/v1/misc-content-items/vld-handbook`,
    { tenant },
    { headers: { [Header.AcceptLanguage]: language } }
  );
