import { LessonType } from '@/domains';

const order = [
  LessonType.PubliclyAvailable,
  LessonType.Basic,
  LessonType.Kriya,
  LessonType.Supplement,
  LessonType.Advanced
];

interface WithLesson<I, L extends { displayedNumber: string }> {
  item: I;
  lesson: L;
}

const sortByLessonDisplayNumber = <T, L extends { displayedNumber: string }>(
  one: WithLesson<T, L>,
  other: WithLesson<T, L>
) => {
  const oneNumber = parseInt(one.lesson.displayedNumber, 10);
  const otherNumber = parseInt(other.lesson.displayedNumber, 10);

  if (oneNumber && otherNumber) return oneNumber - otherNumber;

  if (oneNumber) return 1;
  if (otherNumber) return -1;

  return one.lesson.displayedNumber.localeCompare(other.lesson.displayedNumber);
};

export const orderByLesson = <T>(
  items: T[],
  getLessonAttributes: (item: T) => { type: LessonType; displayedNumber: string }
) => {
  const itemsWithLesson = items.map((item) => ({ item, lesson: getLessonAttributes(item) }));

  return order.flatMap((type) =>
    itemsWithLesson
      .filter(({ lesson }) => lesson.type === type)
      .sort(sortByLessonDisplayNumber)
      .map(({ item }) => item)
  );
};

export const orderByLessonDisplayNumber = <T>(
  items: T[],
  getLessonAttributes: (item: T) => { displayedNumber: string }
) => {
  const itemsWithLesson = items.map((item) => ({ item, lesson: getLessonAttributes(item) }));

  return itemsWithLesson.sort(sortByLessonDisplayNumber).map(({ item }) => item);
};
