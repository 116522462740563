import React from 'react';
import { createContainer } from 'unstated-next';
import { IContentLocation } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

import { Highlight, HighlightColor } from '@/domains';

import {
  getContentLocation,
  getEpubCfiFromLocator,
  getLocatorFromEpubCfi,
  getPublicationUrl
} from '@/components/Reader/services/reader-service/utils';

import { useTextSelection } from '@/components/Reader/hooks/useTextSelection';
import { Reader } from '@/components/Reader/containers/ReaderContainer';
import { isHighlight } from '@/components/Reader/converter';

import {
  HighlightAnnotationData,
  HighlightAnnotationLayer,
  IHighlightAnnotationLayer
} from './highlight-annotation-layer';
import { reducer } from './reducer';

export interface UpsertHighlightOptions {
  contentLocation: IContentLocation;
  colorCode: HighlightColor;
  content: string;
}

export interface RemoveHighlightOptions {
  location: string | IContentLocation;
}

export type OnHighlightUpsertOptions = HighlightAnnotationData;

export interface Options {
  load(): Promise<Highlight[]>;
  onHighlightUpsert(options: OnHighlightUpsertOptions): Promise<Highlight>;
  onHighlightDelete(id: string): Promise<{ id: string }>;
}
const isContentLocation = (location: string | IContentLocation): location is IContentLocation =>
  typeof location === 'object';

const normalizeLocation = (location: string | IContentLocation) => {
  if (!isContentLocation(location)) return location;

  const locator = location.getLocator();
  if (!locator) return;

  const epubCfi = getEpubCfiFromLocator(locator);
  if (!epubCfi) return;

  return epubCfi;
};

const useHighlightAnnotations = ({ load, onHighlightUpsert, onHighlightDelete }: Options = {} as Options) => {
  const { readerService } = Reader.useContainer();

  const { selection, setSelection } = useTextSelection(readerService);

  const [state, dispatch] = React.useReducer(reducer, { loading: true });
  const highlightsAnnotationLayer = React.useMemo<IHighlightAnnotationLayer<HighlightAnnotationData>>(
    () => new HighlightAnnotationLayer<HighlightAnnotationData>('highlights-annotation-layer', readerService.view),
    [readerService.view]
  );

  React.useEffect(() => {
    load()
      .then((value) => {
        value.forEach((highlight) => {
          highlightsAnnotationLayer.addHighlight(highlight);
        });
        dispatch({ type: 'success', value });
      })
      .catch((error) => {
        dispatch({ type: 'error', error });
      });

    return () => highlightsAnnotationLayer.unloadAll();
  }, []);

  React.useEffect(
    () =>
      highlightsAnnotationLayer.subscribeToEvent('annotationClick', async (event) => {
        const highlight = event.annotation.getCustomData() as Highlight;
        const locator = getLocatorFromEpubCfi(getPublicationUrl(readerService.view), highlight.location);

        if (!highlight || !locator) return;

        const contentLocation = await getContentLocation(readerService.view, locator);

        setSelection({
          text: highlight.content,
          contentLocation,
          position: { x: event.clientX, y: event.clientY },
          color: highlight.colorCode as HighlightColor
        });
      }),
    [highlightsAnnotationLayer, setSelection]
  );

  const upsert = React.useCallback(
    async ({ contentLocation, colorCode, content }: UpsertHighlightOptions) => {
      if (!contentLocation || !selection) return;

      const epubCfi = getEpubCfiFromLocator(contentLocation.getLocator());
      if (!epubCfi) return;

      const upserted = highlightsAnnotationLayer.upsertHighlight({
        location: epubCfi,
        colorCode,
        content
      });

      const data = upserted?.getCustomData();
      if (!data) return;

      await onHighlightUpsert(data).then((highlight) => {
        highlightsAnnotationLayer.upsertHighlight(highlight);

        dispatch({ type: 'upsert-ordered-by-location', highlight });
      });
    },
    [selection]
  );

  const remove = React.useCallback(async ({ location }: RemoveHighlightOptions) => {
    const normalizedLocation = normalizeLocation(location);

    if (!normalizedLocation) return;

    const highlight = highlightsAnnotationLayer.deleteHighlight({ location: normalizedLocation })?.getCustomData();

    if (!highlight || !isHighlight(highlight)) return;

    await onHighlightDelete(highlight.id).then(() => {
      dispatch({ type: 'delete-by-ids', ids: [highlight.id] });
    });
  }, []);

  return React.useMemo(
    () => ({
      list: state.value,
      loading: state.loading,
      upsert,
      remove,
      selection,
      setSelection
    }),
    [state.value, state.loading, upsert, remove, selection, setSelection]
  );
};

export const HighlightAnnotations = createContainer(useHighlightAnnotations);
