import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { SearchType } from '@/domains';

interface Props {
  type: SearchType;
}

export const SectionTitle: React.FC<Props> = ({ type }) => (
  <div className="text-title text-scarlet-700 font-semibold px-6 mt-3">
    <FormattedMessage id={translations.application.search.categories[type]} />
  </div>
);
