import { ReadLessonSummary } from '@/domains';

import { OnBookmarkCreate } from '@/components/Reader/BookmarkAnnotations';

export const toCreateBookmark = (data: OnBookmarkCreate, lesson: ReadLessonSummary) => {
  return {
    ...data,
    lesson: {
      id: lesson.id,
      title: lesson.title,
      displayedNumber: lesson.displayedNumber,
      lessonClaimNumber: lesson.lessonClaimNumber,
      type: lesson.type,
      language: lesson.language,
      tenant: lesson.tenant
    }
  };
};
