import { AuxiliaryMaterialType, LessonType, SearchType } from '@/domains';

export const convertMaterialType = (type: AuxiliaryMaterialType): string => {
  if (type === AuxiliaryMaterialType.Weblink) return 'web';
  return type.toLowerCase();
};

export const convertLessonType = (type: LessonType): string => {
  return type
    .toLowerCase()
    .replace(/[^a-zA-Z]/g, ' ')
    .replace(/\s+/g, ' ');
};

const normalizeLessonType = (type: LessonType) => type.toLowerCase();

export const convertLessonTypeToEnrollment = (type: LessonType): string => {
  switch (type) {
    case LessonType.Basic:
    case LessonType.Advanced:
    case LessonType.PubliclyAvailable:
      return normalizeLessonType(LessonType.Basic);
  }

  return normalizeLessonType(type);
};

export const convertSearchType = (type: SearchType): string => {
  switch (type) {
    case SearchType.Glossary:
      return 'glossary';
    case SearchType.Dictionary:
      return 'dictionary';
    case SearchType.LessonContent:
      return 'lessons';
    case SearchType.Highlight:
      return 'highlights';
    case SearchType.AuxiliaryMaterial:
      return 'auxiliary material';
    case SearchType.GuidedMeditation:
      return 'guided meditations';
    case SearchType.BlogPost:
      return 'blog entry';
  }
};
