import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { LessonListItem } from '@/domains';

import { FormattedLessonPages } from '@/components/FormattedLessonPages';

interface LessonListItemLinkProps {
  lesson: LessonListItem;
  href: string;
  className?: string;
  onClick?(): void;
}

export const LessonListItemLink: React.FC<LessonListItemLinkProps> = ({
  lesson,
  className,
  href,
  onClick,
  children
}) => (
  <li>
    <NavLink
      {...{ onClick }}
      className={({ isActive }) =>
        classnames('relative block py-6 pr-[1.875rem] pl-[5.25rem]', className, {
          'bg-gray-100': !lesson.empty && isActive,
          'cursor-pointer hover:bg-gray-100': !lesson.empty,
          'text-gray cursor-default pointer-events-none': lesson.empty
        })
      }
      to={href}
    >
      <i className="absolute text-lesson-item-number -left-1 top-1/2 transform -translate-y-1/2 text-gray-200 font-archer font-bold italic">
        {lesson.displayedNumber}
      </i>

      <h6 className={classnames('font-semibold mb-1', children ? 'text-title ' : 'text-body')}>{lesson.title}</h6>

      {children}
    </NavLink>
  </li>
);

interface LessonListItemProgressProps {
  lesson: LessonListItem;
}

export const LessonListItemProgress: React.FC<LessonListItemProgressProps> = ({ lesson }) => (
  <p className={classnames({ 'text-gray-600': !lesson.empty, 'text-gray-200': lesson.empty })}>
    <FormattedLessonPages {...{ lesson }} />
  </p>
);
