import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { analytics } from '@/services/analytics';

export const useLogout = (type: 'forced' | 'regular') => {
  const { logout } = useAuth0();

  return React.useCallback(async () => {
    logout({ returnTo: window.origin });

    switch (type) {
      case 'forced':
        analytics.trackEvent('session expired', {});
        break;
      case 'regular':
        analytics.trackEvent('logout success', {});
        break;
    }
  }, [logout]);
};
