import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { urls } from '@/constants';

export const useScreenName = () => {
  const location = useLocation();

  const isActive = React.useCallback(
    (pattern: { path: string; end: boolean }) => matchPath(pattern, location.pathname),
    [location]
  );

  return React.useMemo(() => {
    if (isActive({ path: '/content', end: false })) return 'lessons';

    if (isActive({ path: urls.flashcards, end: false })) return 'flashcards';

    if (isActive({ path: `${urls.annotations.root}${urls.annotations.highlights}`, end: false })) return 'highlights';

    if (isActive({ path: `${urls.annotations.root}${urls.annotations.bookmarks}`, end: false })) return 'bookmarks';

    if (isActive({ path: `${urls.otherContent.root}${urls.otherContent.vld.root}`, end: false })) return 'vld';

    return 'unknown';
  }, [isActive]);
};
