import { API, RELEASE } from '@/config';

import { Header, HttpClient, HttpError, HttpRequestConfig } from './http';

interface ApiCallbackOptions {
  onUnsupportedVersionError?: () => void;
}

export class ApiClient<C extends HttpRequestConfig = HttpRequestConfig> extends HttpClient<C> {
  protected baseOptions?: ApiCallbackOptions;

  constructor(options: C) {
    super(options);

    this.client.interceptors.request.use(this.setHeaders);
    this.client.interceptors.response.use(undefined, this.enforceForceUpdate);
  }

  configureBaseOptions(options: ApiCallbackOptions) {
    this.baseOptions = options;
  }

  private enforceForceUpdate = (error: HttpError<any>) => {
    if (
      error.isAxiosError &&
      error.response?.status === 403 &&
      error.response?.data.code == 'client_version_not_supported'
    ) {
      this.baseOptions?.onUnsupportedVersionError?.();
    }
  };

  private setHeaders = (config: HttpRequestConfig): HttpRequestConfig => {
    const headers = config.headers || {};

    return {
      ...config,
      headers: {
        ...headers,
        [Header.Platform]: 'web',
        [Header.Type]: 'study',
        [Header.Version]: RELEASE
      }
    };
  };
}

export const api = new ApiClient<HttpRequestConfig>({
  baseURL: API,
  headers: { 'Content-Type': 'application/json' }
});
