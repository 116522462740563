import React from 'react';

import { Loading, LoadingOverlay, LoadingCenter, LoadingIndicator } from '@/components/Loading';
import { Reader as ReaderContainer } from '@/components/Reader/containers/ReaderContainer';
import { PageControlButton } from '@/components/Reader/PageControlButton';
import { useHighlightSearchResult } from '@/components/Reader/hooks/useHighlightSearchResult';

export const ReaderView: React.FC = () => {
  const { loading, ref, readerService } = ReaderContainer.useContainer();

  useHighlightSearchResult();

  return (
    <div id="content" className="flex h-full">
      <div className="flex justify-center">
        <PageControlButton icon="chevron-left" onClick={readerService.goToPreviousPage.bind(readerService)} />
      </div>

      <div className="relative h-full w-full">
        <Loading visible={loading}>
          <LoadingOverlay className="z-30 bg-white" />

          <LoadingCenter className="z-30">
            <LoadingIndicator />
          </LoadingCenter>
        </Loading>

        <div {...{ ref }} className="relative h-full w-full overflow-hidden" />
      </div>

      <div className="flex justify-center">
        <PageControlButton icon="chevron-right" onClick={readerService.goToNextPage.bind(readerService)} />
      </div>
    </div>
  );
};
