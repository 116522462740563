import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classnames from 'classnames';

import { Icon } from '@/components/Icon';

interface SelectItem {
  value?: string;
  name: string;
}

export interface Props {
  value?: string;
  items?: SelectItem[];
  onChange: (value?: string) => void;
  className?: string;
}

export const Select: React.FC<Props> = ({ value, items, onChange, className }) => {
  const options = items ?? [];

  const selected = options.find((item) => item.value === value);

  return (
    <Listbox as="div" {...{ value, onChange }} className={classnames('relative text-gray-800 z-[1]', className)}>
      <Listbox.Button className="flex items-center justify-between w-full py-2 px-3 rounded border border-gray-200">
        <div className="-mb-[2px]">{selected?.name}</div>

        <Icon type="chevron-down" className="text-[20px]" />
      </Listbox.Button>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Listbox.Options className="absolute top-1 left-0 w-full bg-white rounded overflow-hidden shadow text-gray-900 z-30">
          {options.map((option, index) => (
            <Listbox.Option key={option.value ?? index} value={option.value} as={React.Fragment}>
              {({ selected }) => (
                <li className="relative flex items-center justify-between py-2 pl-3 pr-10 transition hover:cursor-pointer hover:bg-gray-100">
                  {option.name}

                  {selected && (
                    <Icon type="check" className="absolute top-1/2 right-3 text-[20px] -translate-y-1/2 text-scarlet" />
                  )}
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
};
