import React from 'react';
import { Routes, Route } from 'react-router';

import { RandomQuotePlaceholder } from '@/components/RandomQuotePlaceholder';
import { LoadComponent } from '@/components/LoadComponent';

import { BasicLessonListPage } from './BasicLessonListPage';
import { LessonDetailPage } from '../LessonDetailPage';
import { LessonTOCPage } from '../LessonDetailPage/LessonTOCPage';
import { LessonAuxiliaryMaterialPage } from '../LessonDetailPage/LessonAuxiliaryMaterialPage';
import { LessonAuxiliaryMaterialDetailPage } from '../LessonDetailPage/LessonAuxiliaryMaterialDetailPage';

const LessonReaderPage = React.lazy(() =>
  import('../LessonDetailPage/LessonReaderPage').then((m) => ({
    default: m.LessonReaderPage
  }))
);

const AuxiliaryMaterialReaderPage = React.lazy(() =>
  import('../LessonDetailPage/AuxiliaryMaterialReaderPage').then((m) => ({
    default: m.AuxiliaryMaterialReaderPage
  }))
);

export const BasicLessonPage: React.FC = () => {
  return (
    <Routes>
      <Route path="lessons" element={<BasicLessonListPage />}>
        <Route index element={<RandomQuotePlaceholder />} />
        <Route path=":lessonId" element={<LessonDetailPage />}>
          <Route index element={<LessonTOCPage />} />
          <Route path="materials" element={<LessonAuxiliaryMaterialPage />}>
            <Route path=":materialId" element={<LessonAuxiliaryMaterialDetailPage />} />
          </Route>
        </Route>
      </Route>

      <Route
        path="lessons/:lessonId/reader"
        element={
          <LoadComponent>
            <LessonReaderPage />
          </LoadComponent>
        }
      />

      <Route
        path="lessons/:lessonId/materials/:materialId/reader"
        element={
          <LoadComponent>
            <AuxiliaryMaterialReaderPage />
          </LoadComponent>
        }
      />
    </Routes>
  );
};
