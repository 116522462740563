import React from 'react';

import { Icon } from '@/components/Icon';

import { HeaderMenuButton } from './HeaderMenuButton';

export const HeaderMenuButtonWithArrow: React.FC<React.ComponentProps<typeof HeaderMenuButton>> = ({
  children,
  ...props
}) => (
  <HeaderMenuButton {...props}>
    <p className="-mb-[2px] mr-2">{children}</p>

    <Icon type="triangle-down" className="text-[10px]" />
  </HeaderMenuButton>
);
