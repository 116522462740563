import React from 'react';

import { Icon } from '@/components/Icon';
import { Reader } from '@/components/Reader/containers/ReaderContainer';

import { ReaderHeaderButton } from './ReaderHeaderButton';

export interface Props {}

export const SidebarButton: React.FC<Props> = () => {
  const { sidebar } = Reader.useContainer();

  return (
    <ReaderHeaderButton is="button" active={sidebar.isOpen} onClick={sidebar.toggle}>
      <Icon type="contents-menu" />
    </ReaderHeaderButton>
  );
};
