import React from 'react';
import classnames from 'classnames';

export interface Props {
  className?: string;
}

export const FlashcardFace = ({ className, children }: React.PropsWithChildren<Props>) => (
  <div
    className={classnames(
      'flex justify-center absolute inset-0 backface-hidden text-center overflow-y-auto',
      className
    )}
  >
    {children}
  </div>
);
