export enum LanguageFamily {
  English = 'en',
  Italian = 'it',
  Tamil = 'ta',
  Portuguese = 'pt',
  Telugu = 'te',
  Hindi = 'hi',
  Spanish = 'es',
  Bengali = 'bn'
}

export enum Language {
  EnglishUS = 'en-US',
  EnglishIndian = 'en-IN',
  Italian = 'it',
  Tamil = 'ta',
  Portuguese = 'pt',
  Telugu = 'te',
  Hindi = 'hi',
  Spanish = 'es',
  Bengali = 'bn'
}

export const getLanguageFamily = (code: Language): LanguageFamily => code.split('-')[0] as LanguageFamily;
