import React from 'react';
import classnames from 'classnames';

import lotu7lightBlue from '@/assets/lotus-7-light-blue.svg';

export interface Props {
  className?: string;
}

export const EmptyPlaceholder = ({ className, children }: React.PropsWithChildren<Props>) => (
  <div className={classnames('relative', className)}>
    <img src={lotu7lightBlue} className="absolute w-2/3 top-5 right-0" />

    <span className="text-headline-2 font-semibold text-gray-800">{children}</span>
  </div>
);
