import React from 'react';
import { Transition } from '@headlessui/react';
import classnames from 'classnames';

import { Loading, LoadingCenter, LoadingIndicator } from '@/components/Loading';

export type Appearance = 'none' | 'scarlet';

const styles: Record<Appearance, { root?: string; loading?: string }> = {
  none: {},
  scarlet: {
    root: 'bg-scarlet text-white font-semibold hover:bg-scarlet-700',
    loading: 'w-6 h-6 text-scarlet-50'
  }
};

export interface Props extends Omit<React.ButtonHTMLAttributes<any>, 'is'> {
  is?: React.ElementType;
  appearance?: Appearance;
  size?: string;
  loading?: boolean;
}

export const Button: React.FC<Props> = ({
  is: Component = 'button',
  appearance = 'none',
  size = 'px-8 h-12',
  loading = false,
  className,
  children,
  ...rest
}) => {
  const content =
    loading == undefined ? (
      children
    ) : (
      <React.Fragment>
        <Loading visible={loading}>
          <LoadingCenter>
            <LoadingIndicator className={styles[appearance].loading} />
          </LoadingCenter>
        </Loading>

        <Transition
          as="span"
          appear
          show={!loading}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {children}
        </Transition>
      </React.Fragment>
    );

  return (
    <Component
      className={classnames(
        'disabled:cursor-default disabled:bg-gray-200 disabled:text-gray-50',
        styles[appearance]?.root,
        {
          'relative inline-flex items-center justify-center rounded focus:outline-none transition group': !!appearance
        },
        size,
        className
      )}
      {...rest}
    >
      {content}
    </Component>
  );
};
