import React from 'react';
import classnames from 'classnames';
import { Combobox } from '@headlessui/react';

import { AuxiliaryMaterialResult, SearchType } from '@/domains';
import { orderByLessonDisplayNumber } from '@/services/lesson';

import { SectionTitle } from './SectionTitle';

interface Props {
  data: AuxiliaryMaterialResult[];
  showSectionTitle: boolean;
}

export const AuxiliaryMaterialResults: React.FC<Props> = ({ data, showSectionTitle }) => (
  <React.Fragment>
    {showSectionTitle && <SectionTitle type={SearchType.AuxiliaryMaterial} />}

    {orderByLessonDisplayNumber(data, (item) => item).map((item, index) => (
      <Combobox.Option
        key={index}
        value={item}
        className={({ active }) =>
          classnames('py-1 px-6 text-gray-800 cursor-pointer hover:bg-gray-100 transition', { 'bg-gray-100': active })
        }
      >
        <span>{`${item.content}`}</span>
      </Combobox.Option>
    ))}
  </React.Fragment>
);
