import React from 'react';

import { Icon, Type as IconType } from '@/components/Icon';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon: IconType;
}

export const ActionButton = ({ icon, children, className, ...rest }: React.PropsWithChildren<Props>) => (
  <button
    className="font-semibold w-full py-4 border-t border-solid border-gray-200 text-left text-gray-800 hover:text-black"
    {...rest}
  >
    <Icon type={icon} className="text-[1.5rem] mr-4" />

    {children}
  </button>
);
